<template>
    <div class="position-dict-view p-3">
        <PositionDictSearchCriteria v-model="searchCriteria" @update:modelValue="search" @unselect="unselect" />
        <PositionDictTable ref="positionDictTable" :searchCriteria="searchCriteria" />
    </div>
</template>

<script>
    import PositionDictSearchCriteria from "./components/PositionDictSearchCriteria";
    import PositionDictTable from "./components/PositionDictTable";
    import {FiltersUtils} from "@/utils/FiltersUtils";

    export default {

        name: "PositionDictView",

        components: {
            PositionDictSearchCriteria,
            PositionDictTable,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            if (FiltersUtils.loadFromSessionStorage(this.$route.path)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(this.$route.path);
            }
        },

        methods: {
            getClearSearchCriteria() {
                return {
                    programs: [],
                    fieldNames: [],
                    statuses: [],
                    name: "",
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                    matchMode: "POSITION",
                };
            },

            search() {
                this.$refs.positionDictTable.search();
            },

            unselect() {
                this.$refs.positionDictTable.unselect();
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    FiltersUtils.saveToSessionStorage(this.$route.path, value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
