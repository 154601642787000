<template>
    <div class="field-dict-view p-3">
        <FieldDictSearchCriteria v-model="searchCriteria" @update:modelValue="search" />
        <FieldDictTable ref="fieldDictTable" :searchCriteria="searchCriteria" />
    </div>
</template>

<script>
    import FieldDictSearchCriteria from "./components/FieldDictSearchCriteria";
    import FieldDictTable from "./components/FieldDictTable";
    import {FiltersUtils} from "@/utils/FiltersUtils";

    export default {

        name: "PositionDictView",

        components: {
            FieldDictSearchCriteria,
            FieldDictTable,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            if (FiltersUtils.loadFromSessionStorage(this.$route.path)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(this.$route.path);
            }
        },

        methods: {
            getClearSearchCriteria() {
                return {
                    name: "",
                    programs: [],
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                    matchMode: "FIELD",
                };
            },

            search() {
                this.$refs.fieldDictTable.search();
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    FiltersUtils.saveToSessionStorage(this.$route.path, value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
