<template>
    <div class="monitoring-program-semiraw-table">
        <DataTable :lazy="true" :value="semiRawData" v-model:editingRows="editingRows" editMode="row"
                   dataKey="id" @row-edit-save="onRowEditSave" :paginator="!useGivenData"
                   v-model:rows="searchCriteria.page.limit" v-model:first="searchCriteria.page.offset"
                   :totalRecords="useGivenData ? semiRawData.length : totalRecords" :search-criteria="searchCriteria"
                   @page="onPage" @sort="onPage" removableSort scrollable size="small"
                   :rows="10" :rowsPerPageOptions="[5,10,20,50]" :loading="loading">
            <Column header="Program" :sortable="sortable" field="program"/>
            <Column header="Powierzchnia" :sortable="sortable" field="field">
                <template #body="slotProps">
                    {{ slotProps.data.field.name }}
                </template>
            </Column>
            <Column header="Stanowisko" :sortable="sortable" field="position">
                <template #body="slotProps">
                    {{ slotProps.data.position?.name }}
                </template>
            </Column>
            <Column header="Transekt" :sortable="sortable" field="transect">
                <template #body="slotProps">
                    {{ slotProps.data.transect?.name }}
                </template>
            </Column>
            <Column header="Punkt" :sortable="sortable" field="point">
                <template #body="slotProps">
                    {{ slotProps.data.point?.name }}
                </template>
            </Column>
            <Column header="Rok" :sortable="sortable" field="year"/>
            <Column header="Kontrola" :sortable="sortable" field="control">
                <template #body="slotProps">
                    {{ slotProps.data.control?.label }}
                </template>
            </Column>
            <Column header="Data kontroli" :sortable="sortable" field="controlDate" style="width: 120px;" >
                <template #body="slotProps">
                    {{ dateFormat(slotProps.data.controlDate) }}
                </template>
                <template #editor="{data,field}">
                    <CustomCalendar v-model="data[field]" name="controlDate" class="w-9rem" />
                </template>
            </Column>
            <Column header="Obserwator" :sortable="sortable" field="observer">
                <template #body="slotProps">
                    {{ slotProps.data.observer.name }}
                </template>
                <template #editor="{data,field}">
                    <CustomSelectOne :items="observers" itemLabel="name"
                                     v-model="data[field]" name="observer" />
                </template>
            </Column>
            <Column header="Kod obrączkarski" :sortable="sortable" field="species">
                <template #body="slotProps">
                    {{ slotProps.data.species?.ident1 }}
                </template>
            </Column>
            <Column header="Nazwa polska" :sortable="sortable" field="species">
                <template #body="slotProps">
                    {{ slotProps.data.species?.namePl }}
                </template>
            </Column>
            <Column header="Nazwa łacińska" :sortable="sortable" field="species">
                <template #body="slotProps">
                    {{ slotProps.data.species?.nameLat }}
                </template>
            </Column>
            <Column v-if="dicts?.controlType !== undefined && dicts.controlType.length > 0" header="Kontrola" :sortable="sortable" field="controlType" style="width: 200px;">
                <template #body="slotProps">
                    {{ slotProps.data.controlType ? slotProps.data.controlType.label : slotProps.data.control.label}}
                </template>
                <template #editor="{data,field}">
                    <CustomSelectOne :items="dicts.controlType"
                                     v-model="data[field]" name="observer" />
                </template>
            </Column>
            <Column header="Liczebność" :sortable="sortable" field="quantity">
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="quantity" class="w-3rem"/>
                </template>
            </Column>
            <Column header="Jednostka liczebności" :sortable="sortable" field="quantityUnit"/>
            <Column header="Kryterium lęgowości" :sortable="sortable" field="nestingCriteria">
                <template #body="slotProps">
                    <div v-if="slotProps.data.nestingCriteria !== null && showMoreText[slotProps.index]"
                         @click="showMore(slotProps.index)" class="nesting-criteria-cell"
                         :class="slotProps.data.nestingCriteria.length > 60 ? 'cursor-pointer' : ''"
                         @mousemove="tooltipPosition($event,slotProps.index,slotProps.data.nestingCriteria.length)">
                        {{ slotProps.data.nestingCriteria === null ? "" : slotProps.data.nestingCriteria }}
                        <span v-if="slotProps.data.nestingCriteria.length > 60"
                              :id="`nesting-criteria-tooltip-${slotProps.index}`" class="hide-tooltip">
                            Ukryj tekst
                        </span>
                    </div>
                    <div v-else-if="slotProps.data.nestingCriteria !== null" @click="showMore(slotProps.index)"
                         class="nesting-criteria-cell"
                         :class="slotProps.data.nestingCriteria.length > 60 ? 'cursor-pointer' : ''"
                         @mousemove="tooltipPosition($event,slotProps.index,slotProps.data.nestingCriteria.length)">
                        {{ slotProps.data.nestingCriteria === null
                            ? ""
                            : textTruncate(slotProps.data.nestingCriteria) }}
                        <span v-if="slotProps.data.nestingCriteria.length > 60"
                              :id="`nesting-criteria-tooltip-${slotProps.index}`" class="show-tooltip">
                            Pokaż tekst
                        </span>
                    </div>
                </template>
                <template v-if="dicts?.nestingCriteria !== undefined && dicts.nestingCriteria.length > 0" #editor="{data,field}">
                    <CustomSelectOne itemLabel="code" :items="dicts.nestingCriteria"
                                     v-model="data[field]" name="nestingCriteria" tooltip style="width: 200px;"/>
                </template>
            </Column>
            <Column header="Kategoria lęgowości" :sortable="sortable" field="nestingCategory" />
            <Column header="Liczba piskląt" :sortable="sortable" field="hatchlingsNumber">
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="hatchlingsNumber" class="w-3rem"/>
                </template>
            </Column>
            <Column header="Wynik lęgu" :sortable="sortable" field="breedingResult" />
            <Column header="Siedlisko1" :sortable="sortable" field="habitat1" style="width: 130px;" />
            <Column header="Siedlisko2" :sortable="sortable" field="habitat2"/>
            <Column header="Dodatkowi obserwatorzy" :sortable="sortable" field="additionalObservers">
                <template #body="slotProps">
                    {{ slotProps.data.additionalObservers }}
                </template>
            </Column>
            <Column header="Data dodania/modyfikacji" :sortable="sortable" field="date" style="width: 120px;" >
                <template #body="slotProps">
                    {{ dateFormat(slotProps.data.date) }}
                </template>
            </Column>
            <Column v-if="editable" :rowEditor="true" style="width: 120px; text-align: center;" />
        </DataTable>
    </div>
</template>

<script>
    import _ from "lodash";
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchSemiRawDataCount,
        searchSemiRawData,
        getAllObserversAsCoordinator,
        updateSemiRawData,
    } from "@/swagger/vue-api-client";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import {DateUtils} from "@/utils/DateUtils";

    export default {
        name: "SemiRawDataTable",

        components: {
            CustomInputNumber,
            CustomCalendar,
            CustomSelectOne,
            DataTable,
            Column,
        },

        props: {
            modelValue: null,
            sortable: {
                type: Boolean,
                default: true,
            },
            editable: {
                type: Boolean,
                default: false,
            },
            data: {
                type: Array,
                default: () => [],
            },
            useGivenData: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["dicts"],

        data() {
            return {
                semiRawData: [],
                editingRows: ref([]),
                totalRecords: 0,
                loading: false,
                observers: [],
                showMoreText: [],
            };
        },

        emits: ["update:modelValue"],

        beforeMount() {
            getAllObserversAsCoordinator()
                .then((response) => {
                    this.observers = response.data;
                });
        },

        mounted() {
            if (this.useGivenData) {
                this.semiRawData = this.data;
            } else {
                this.search();
            }
        },

        methods: {
            search() {
                if (!this.useGivenData) {
                    this.loading = true;
                    const parameters = {programEditionId: this.$route.params.id, body: this.searchCriteria};
                    searchSemiRawData(parameters)
                        .then((response) => {
                            this.semiRawData = response.data;
                            return searchSemiRawDataCount(parameters);
                        })
                        .then((response) => {
                            this.totalRecords = response.data;
                            this.loading = false;
                        });
                }
            },

            onPage(event) {
                this.searchCriteria.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
                // not need to run "search" because searchCriteria watch handler will do it
            },

            onRowEditSave(event) {
                const {newData, index} = event;
                this.semiRawData[index] = newData;

                const request = {...this.semiRawData[index]};
                request.fieldId = request.field?.id;
                delete request.field;
                request.positionId = request.position?.id;
                delete request.position;
                request.controlKey = request.controlType?.key;
                delete request.controlType;
                request.observerId = request.observer?.id;
                delete request.observer;
                request.speciesId = request.species?.id;
                delete request.species;
                request.nestingCriteria = request.nestingCriteria?.key;

                updateSemiRawData({
                    id: request.id,
                    body: request,
                });
            },

            dateFormat(date) {
                if (date == null) return "";
                return DateUtils.displayDate(date);
            },

            textTruncate(text) {
                if (text.length > 60) {
                    return _.truncate(text, {
                        length: 60,
                        separator: " ",
                    });
                }
                return text;
            },
            showMore(index) {
                if (this.showMoreText[index]) {
                    this.showMoreText[index] = false;
                } else {
                    this.showMoreText[index] = true;
                }
            },
            tooltipPosition(e, i, length) {
                if (length > 60) {
                    const x = e.clientX;
                    const y = e.clientY;
                    document.getElementById(`nesting-criteria-tooltip-${i}`).style.left = x - 5 + "px";
                    document.getElementById(`nesting-criteria-tooltip-${i}`).style.top = y - 15 + "px";
                }
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    this.search();
                    this.$emit("update:modelValue", value);
                },
                deep: true,
            },
        },

        computed: {
            searchCriteria: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },
    };
</script>

<style lang="scss" scoped>
    .nesting-criteria-cell {
        position: relative;
        .show-tooltip, .hide-tooltip {
            display: none;
            position: fixed;
            width: max-content;
            padding: 2px 4px;
            border: 1px solid black;
            background-color: var(--surface-a);
            border-radius: 4px;
        }
        &:hover {
            .show-tooltip, .hide-tooltip {
                display: block;
            }
        }
    }
</style>
