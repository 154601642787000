<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="position" :geobjects="positions"
                              :controls="position.controls" @deleteControl="deleteControl"
                              :canDeleteControl="position.controls.length > 1"
                              additionalInformationInMain v-model:additionalInformation="internalForm">
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition :field="field" v-model="position" :positions="positions" v-model:step="internalStep"/>
        </template>
        <template v-slot:controlData>
            <div class="flex gap-3">
                <CustomSelectOne label="Gatunek" name="species" v-model="position.species" class="w-4"
                                 :emptyValue="false" itemLabel="name" required :items="dicts.species"
                                 :disabled="!editing" />
            </div>
        </template>
        <template v-slot:controlMainData="slotProps">
            <div class="flex gap-3">
                <CustomCalendar label="Data liczenia" v-model="slotProps.control.controlDate" name="controlDate" class="w-4" required />
                <CustomSelectOne label="Metoda kontroli" name="controlMethod" v-model="slotProps.control.controlMethod"
                                 class="w-4" required
                                 :items="dicts.controlMethod" />
            </div>
            <CustomCheckbox label="Brak ptaków" name="noBirds" v-model="slotProps.control.noBirds"
                            class="w-4 mt-3" />
        </template>
        <template v-slot:controlBirdsData="slotProps">
            <FormBirdsMCY v-if="!slotProps.control.noBirds" v-model="slotProps.control" class="mt-4" :index="slotProps.index" />
        </template>
        <template v-slot:controlsSummary>
            <CustomButton v-if="editing && position.controls.length < 2" class="add-control-button align-self-center mb-4"
                          label="Dodaj drugą kontrolę" icon="pi pi-plus" @click="addControl" />
        </template>
        <template v-slot:controlHabitatData>
            <HabitatDataMCY :habitatData="position.habitatData" v-model:habitatDataToChange="position.habitatDataToChange" />
        </template>
    </FormObservationsTemplate>
    <Dialog class="delete-control-dialog" v-model:visible="confirmDeleteDialog" header="Czy na pewno chcesz usunąć kontrolę?"
            :closable="false" :draggable="false" modal>
        <div class="delete-control-dialog-container">
            <div class="delete-control-dialog-buttons">
                <CustomButton label="Usuń" color="#EA3030" bgColor="transparent" @click="confirmDeleteControl" :disabled="!editing" />
                <CustomButton label="Anuluj" @click="confirmDeleteDialog = false" bgColor="transparent" :disabled="!editing" />
            </div>
        </div>
    </Dialog>
</template>

<script>
    import _ from "lodash";
    import Dialog from "primevue/dialog";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import FormBirdsMCY from "./FormBirdsMCY.vue";
    import HabitatDataMCY from "./HabitatDataMCY.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "FormMCYObservations",

        components: {
            FormObservationsTemplate,
            ObservationsSelectPosition,
            FormBirdsMCY,
            HabitatDataMCY,
            CustomCalendar,
            CustomSelectOne,
            CustomCheckbox,
            CustomButton,
            Dialog,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
            allPositions: {
                type: Array,
                default: () => [],
            },
            field: {
                type: Array,
                default: () => [],
            },
            step: {
                type: Number,
                required: true,
            },
        },

        inject: ["editing", "showErrorMessages", "dicts"],

        data() {
            return {
                positions: [],
                position: _.cloneDeep(EmptyStructures.getEmptyMCYPosition()),
                activeIndex: 0,
                confirmDeleteDialog: false,
                deletingControlIndex: null,
            };
        },

        emits: ["update:step", "update:form"],

        beforeMount() {
            this.positions = this.allPositions.filter((p) => (p.notAudited === null || !p.notAudited));
            for (let i = 0; i < this.positions.length; i += 1) {
                if (this.positions[i].controls == null) {
                    this.positions[i].controls = [];
                }
                if (this.positions[i].controls.length === 0) {
                    this.positions[i].controls.push(_.cloneDeep(EmptyStructures.getEmptyMCYControl()));
                    this.activeIndex = this.positions[i].controls.length - 1;
                }
            }
        },

        methods: {
            addControl() {
                if (this.position.controls.length > 1) return;
                if (this.position.controls == null) {
                    this.position.controls = [];
                }
                this.position.controls.push(_.cloneDeep(EmptyStructures.getEmptyMCYControl()));
                this.activeIndex = this.position.controls.length - 1;
            },
            confirmDeleteControl() {
                if (this.position.controls.length > 1) {
                    this.position.controls.splice(this.deletingControlIndex, 1);
                    this.activeIndex = this.position.controls.length - 1;
                    this.confirmDeleteDialog = false;
                }
            },
            deleteControl(index) {
                if (this.position.controls.length > 1) {
                    this.deletingControlIndex = index;
                    if (_.isEqual(this.position.controls[index], EmptyStructures.getEmptyMCYControl())) {
                        this.confirmDeleteControl();
                    } else {
                        this.confirmDeleteDialog = true;
                    }
                }
            },
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
