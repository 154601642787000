<template>
    <div>
        <div class="flex gap-3 flex-wrap" v-if="loadPrograms && loadUsers">
            <CustomMultiSelect name="obserwatorzy" label="Do obserwatorów z programu" :items="programs"
                               itemValue="value" v-model="internalValue.observersFromProgram"
                               class="w-3 mb-0" @update:modelValue="update" />
            <CustomMultiSelect name="koordynatorzy" label="Do koordynatorów z programu" :items="programs"
                               itemValue="value" v-model="internalValue.coordinatorsFromProgram"
                               class="w-3 mb-0" @update:modelValue="update" />
            <CustomMultiSelect name="obserwatorzy-niezatwierdzonym-formularze"
                               label="Do obserwatorów z niezatwierdzonym formularze w programie"
                               :items="programs" itemValue="value" v-model="internalValue.notAcceptedFormsFromProgram"
                               class="w-5 mb-0" @update:modelValue="update" />
            <CustomMultiSelect name="obserwatorzy-niezatwierdzonym-formularze" label="Do innych użytkowników"
                               :items="users" itemLabel="name" itemValue="id" v-model="internalValue.otherReceivers"
                               class="w-3 mb-0" virtual-scrolling @update:modelValue="update" />
            <CustomCalendar label="Data od do" class="w-2" name="controlDate" selectionMode="range"
                            v-model="internalValue.date" />
        </div>
        <CustomButton label="Wyczyść" @click="clear"/>
    </div>
</template>

<script>
    import CustomButton from "../../../components/form/CustomButton";
    import CustomMultiSelect from "../../../components/form/inner/CustomMultiSelect.vue";
    import { SearchCriteria } from "../../../utils/SearchCriteria";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import {
        getListOfPrograms,
        listUsers,
    } from "@/swagger/vue-api-client";

    export default {
        name: "EmailHistorySearchCrtiera",

        components: {
            CustomButton,
            CustomMultiSelect,
            CustomCalendar,
        },

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue", "unselect"],

        data() {
            return {
                searchCriteria: SearchCriteria,
                loadPrograms: false,
                loadUsers: false,
            };
        },

        beforeMount() {
            this.getPrograms();
            this.getUsersList();
        },

        methods: {
            getPrograms() {
                getListOfPrograms()
                    .then((response) => {
                        this.programs = response.data;

                        // In order to use search, is necessary to change data structur
                        for (let i = 0; i < this.programs.length; i += 1) {
                            this.programs[i] = { label: this.programs[i], value: this.programs[i] };
                        }

                        this.loadPrograms = true;
                    });
            },
            getUsersList() {
                listUsers()
                    .then((response) => {
                        this.users = response.data;
                        this.loadUsers = true;
                    });
            },

            update() {
                this.$emit("update:modelValue", this.internalValue);
            },

            clear() {
                this.internalValue.programs = [];
                this.internalValue.fieldNames = [];
                this.internalValue.statuses = [];
                this.internalValue.name = "";
                this.$emit("unselect");
                this.update();
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        watch: {
            "internalValue.programs"(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.getFields();
                }
            },
        },
    };
</script>

<style lang="scss">
</style>
