<template>
    <div class="p-3">
        <BirdsDictSearchCriteria />
        <BirdsDictTable />
    </div>
</template>

<script>
    import BirdsDictTable from "./BirdsDictTable.vue";
    import BirdsDictSearchCriteria from "./BirdsDictSearchCriteria.vue";

    export default {
        name: "BirdsDictView",

        components: {
            BirdsDictTable,
            BirdsDictSearchCriteria,
        },
    };
</script>

<style lang="scss">
</style>
