<template>
    <Dialog class="edit-positions-dialog" :header="(create ? 'Utwórz nową' : 'Edytuj') + ' historię punktu'"
            :draggable="false" v-model:visible="internalVisible">
        <div class="edit-positions-dialog-container flex flex-column">
            <div class="edit-positions-dialog-content flex justify-content-between mb-5 gap-8">
                <div class="flex flex-column justify-content-between">
                    <div>
                        <CustomInputNumber label="Koordynaty N" v-model="internalItem.coordinates.latitude"
                                           :maxDigits="6" @update:modelValue="updateMap" name="latitude"
                                           required :showErrors="showErrors"/>
                        <CustomInputNumber label="Koordynaty E" v-model="internalItem.coordinates.longitude"
                                           :maxDigits="6" @update:modelValue="updateMap" name="longitude"
                                           required :showErrors="showErrors"/>
                        <CustomCalendar label="Rok" name="year" required dateFormat="yy" v-model="internalItem.year"
                                        view="year"/>
                        <CustomSelectOne v-if="create" id="controlVersion" v-model="internalItem.controlVersion"
                                         :items="controlVersions" label="Wersja kontrolna" name="controlVersion"
                                         required :showErrors="showErrors" itemLabel=""
                                         :filter="false" />
                    </div>
                    <CustomButton label="Zatwierdź" class="justify-content-center"
                                  bgColor="var(--secondary-color)" color="var(--surface-a)" @click="save" />
                </div>
                <div class="edit-positions-dialog-content__left">
                    <div class="edit-positions-map"
                         style="height: 400px; width: 600px;">
                        <MapSingleMarker v-model="mapCoordinates" mapId="editPointMap" height="400px"
                                         @emitCoor="onMapCoordinatesChange" :zoomControls="false" :field="field" />
                    </div>
                </div>
            </div>
            <div class="flex justify-content-end">

            </div>
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import {MapMixin} from "@/mixins/MapMixin";
    import MapSingleMarker from "@/components/map/MapSingleMarker.vue";
    import {
        createPointHistory,
        getAvailablePointControlVersions,
        updatePointHistory,
    } from "@/swagger/vue-api-client";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import {DateUtils} from "@/utils/DateUtils";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";

    export default {
        name: "EditPointDialog",

        mixins: [MapMixin],

        components: {
            CustomSelectOne,
            CustomCalendar,
            Dialog,
            CustomButton,
            CustomInputNumber,
            MapSingleMarker,
        },

        emits: ["update:visible", "update:item", "reloadHistory"],

        props: {
            item: {
                type: Object,
                required: true,
            },
            visible: {
                type: Boolean,
                default: false,
            },
            create: {
                type: Boolean,
                default: false,
            },
            field: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                mapCoordinates: [],
                showErrors: false,
                controlVersions: [],
            };
        },

        beforeMount() {
            this.loadControlVersions();
            this.updateMap();
        },

        methods: {
            loadControlVersions() {
                getAvailablePointControlVersions({
                    year: this.year ? this.year : new Date().getFullYear(),
                    id: this.$route.params.id,
                })
                    .then((response) => { this.controlVersions = response.data; });
            },
            onMapCoordinatesChange(coordinates) {
                this.internalItem.coordinates.latitude = coordinates.lat;
                this.internalItem.coordinates.longitude = coordinates.lng;
            },
            updateMap() {
                if (this.internalItem.coordinates.latitude == null
                    || this.internalItem.coordinates.longitude == null) return;
                this.mapCoordinates = [
                    this.internalItem.coordinates.latitude,
                    this.internalItem.coordinates.longitude,
                ];
            },
            validate() {
                return !(this.internalItem.coordinates.longitude === ""
                    || this.internalItem.coordinates.longitude == null
                    || this.internalItem.coordinates.latitude === ""
                    || this.internalItem.coordinates.latitude == null);
            },
            save() {
                if (this.validate()) {
                    this.showErrors = false;
                    const historyId = this.internalItem.id;
                    const requestBody = {
                        pointId: this.$route.params.id,
                        coordinates: this.internalItem.coordinates,
                        year: DateUtils.getYearOnly(this.internalItem.year),
                        controlVersion: this.internalItem.controlVersion,
                        accepted: this.internalItem.accepted,
                    };
                    if (this.create) {
                        createPointHistory({body: requestBody})
                            .then(() => {
                                this.$emit("reloadHistory");
                                this.$emit("update:visible", false);
                            })
                            .catch((error) => { console.log(error); });
                    } else {
                        updatePointHistory({id: historyId, body: requestBody})
                            .then(() => {
                                this.$emit("reloadHistory");
                                this.$emit("update:visible", false);
                            })
                            .catch((error) => { console.log(error); });
                    }
                } else {
                    this.showErrors = true;
                }
            },
        },

        computed: {
            internalVisible: {
                get() { return this.visible; },
                set(value) { this.$emit("update:visible", value); },
            },
            internalItem: {
                get() { return this.item; },
                set(value) { this.$emit("update:item", value); },
            },
        },

        watch: {
            internalItem: {
                handler(value) { this.$emit("update:item", value); },
                deep: true,
            },
            "internalItem.year"() {
                this.loadControlVersions();
            },
        },
    };
</script>

<style lang="scss">
@import "../../../assets/theme/mytheme/variables";
@import "../../../views/assets/scss/dialogs";
</style>
