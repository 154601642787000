<template>
    <div class="p-3">
        <HabitatDataTable :columns="columns" :programCode="programCode"/>
    </div>
</template>

<script>
    import HabitatDataTable from "./components/HabitatDataTable.vue";

    export default {
        name: "HabitatDataView",

        components: {
            HabitatDataTable,
        },

        data() {
            return {
                columns: [],
                programCode: this.$route.params.programCode,
                multiSelectProps: {
                    filterType: "multiSelect",
                    filterFilter: true,
                    filterItemLabel: "name",
                    filterItemValue: "id",
                    filterClass: "flex-1 w-0 mt-3",
                    filterEmptyValue: false,
                },
                field: null,
                position: null,
                transect: null,
                point: null,
                accepted: null,
                date: null,
                hab1Lev1: null,
                hab1Lev2: null,
                hab1Lev3_1: null,
                hab1Lev3_2: null,
                hab1Lev3_3: null,
                hab2Lev1: null,
                hab2Lev2: null,
                hab2Lev3_1: null,
                hab2Lev3_2: null,
                hab2Lev3_3: null,
                utilization: null,
                differentUtilization: null,
                when: null,
                rut: null,
                clumps: null,
                plantHeight: null,
                treeBushesArea: null,
                treeType: null,
                bushHeight: null,
                cane: null,
                water: null,
                nestPlace: null,
                plantLitter: null,
                habitatType: null,
                habitatType2: null,
                habitatTypeList: null,
                thermalModernization: null,
                swiftBoxes: null,
                habitatNotes: null,
                dominantVegetation: null,
                spatial: null,
                succession: null,
                averageHeightPlant: null,
                averageHeightClump: null,
                location: null,
                landform: null,
                direction: null,
                waterInSoil: null,
                grassCovered: null,
                perennialsCovered: null,
                shrubsCovered: null,
                rockSurface: null,
                treeNumber: null,
                waterPresence: null,
                crops: null,
                buildingDistance: null,
                waterDistance: null,
                slopeHeight: null,
                otherHabitatType: null,
                otherColonyLocation: null,
                treeArea: null,
                bushesArea: null,
            };
        },

        beforeMount() {
            /* eslint-disable */
            this.assignColumnProps();
            if (this.programCode === "MPPL") {
                this.columns = [this.field, this.transect, this.date, this.accepted, this.hab1Lev1,
                    this.hab1Lev2, this.hab1Lev3_1, this.hab1Lev3_2, this.hab1Lev3_3,
                    this.hab2Lev1, this.hab2Lev2, this.hab2Lev3_1, this.hab2Lev3_2, this.hab2Lev3_3];
            } else if (this.programCode === "MPM") {
                this.columns = [this.field, this.transect, this.date, this.accepted, this.hab1Lev1,
                    this.hab1Lev2, this.hab1Lev3_1, this.hab1Lev3_2, this.hab1Lev3_3,
                    this.hab2Lev1, this.hab2Lev2, this.hab2Lev3_1, this.hab2Lev3_2, this.hab2Lev3_3];
            } else if (this.programCode === "MWOT") {
                this.columns = [this.field, this.transect, this.date, this.accepted, this.utilization,
                    this.differentUtilization, this.when, this.rut, this.clumps, this.plantHeight,
                    this.treeBushesArea, this.treeType, this.bushHeight, this.cane, this.water,
                    this.nestPlace, this.plantLitter];
            } else if (this.programCode === "MPPM") {
                this.columns = [this.field, this.point, this.date, this.accepted,
                    this.habitatType, this.habitatType2, this.thermalModernization, this.swiftBoxes];
            } else if (this.programCode === "MNZ") {
                this.columns = [this.field, this.position, this.date, this.accepted,
                    this.habitatType, this.habitatNotes, this.dominantVegetation,];
            } else if (this.programCode === "MDU") {
                this.columns = [this.field, this.position, this.date, this.accepted,
                    this.habitatType, this.utilization, this.water, this.spatial, this.succession,
                    this.averageHeightPlant, this.averageHeightClump];
            } else if (this.programCode === "MPG") {
                this.columns = [this.field, this.point, this.date, this.accepted,
                    this.location, this.landform, this.direction, this.waterInSoil, this.plantHeightNumber, this.grassCovered,
                    this.perennialsCovered, this.shrubsCovered, this.rockSurface, this.treeNumber, this.waterPresence];
            } else if (this.programCode === "MZO") {
                this.columns = [this.field, this.position, this.date, this.accepted, this.habitatType, this.crops, this.buildingDistance, this.waterDistance, this.slopeHeight];
            } else if (this.programCode === "MSL") {
                this.columns = [this.field, this.position, this.date, this.accepted, this.habitatTypeList, this.habitatNotes];
            } else if (this.programCode === "MMC") {
                this.columns = [this.field, this.position, this.date, this.accepted, this.habitatType, this.otherHabitatType, this.location, this.otherColonyLocation, this.plantHeight, this.treeArea, this.bushesArea];
            } else {
                this.columns = [];
            }
            /* eslint-enable */
        },

        methods: {
            /* eslint-disable */
            assignColumnProps() {
                this.field = {header: "Powierzchnia", field: "field.name", filterField: "field",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: true,
                    linkTo: (data) => this.getFieldLink(data), bodyContent: (data) => data.field?.name,
                    filterItemsKey: "fields", ...this.multiSelectProps, filterOtherAttrs: {}, filterType: "dictionary"};
                this.position = {header: "Stanowisko", field: "position.name", filterField: "position",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: true,
                    linkTo: (data) => this.getPositionLink(data), bodyContent: (data) => data.position?.name,
                    filterItemsKey: "positions", ...this.multiSelectProps, filterOtherAttrs: {}, filterType: "dictionary"};
                this.transect = {header: "Transekt", field: "transect.name", filterField: "transect",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: true,
                    linkTo: (data) => this.getTransectLink(data), bodyContent: (data) => data.transect.name,
                    filterItemsKey: "transects", ...this.multiSelectProps, filterOtherAttrs: {}, filterType: "dictionary", filterItemLabel: "label", itemValue: "label",};
                this.point = {header: "Punkt", field: "point.name", filterField: "point",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: true,
                    linkTo: (data) => this.getPointLink(data), bodyContent: (data) => data.point.name,
                    filterItemsKey: "points", ...this.multiSelectProps, filterOtherAttrs: {}, filterType: "dictionary", filterItemLabel: "label", itemValue: "label",};
                this.date = {header: "Data kontroli", field: "controlDate", filterField: "controlDate",
                    showFilterMatchModes: false, filterMenuClass: "w-27.5rem", sortable: true, bodyLink: false,
                    bodyClass: () => "nowrap", bodyTooltip: false,
                    bodyContent: (data) => data.controlDate,
                    filterSelectionMode: "range", filterOtherAttrs: {}, filterType: "date-range"};
                this.accepted = this.getBooleanColumn("Zaakceptowano", "accepted");
                this.hab1Lev1 = this.getTextColumn("S1P1", "hab1Lev1");
                this.hab1Lev2 = this.getTextColumn("S1P2", "hab1Lev2");
                this.hab1Lev3_1 = this.getTextColumn("S1P3_1", "hab1Lev3_1");
                this.hab1Lev3_2 = this.getTextColumn("S1P3_2", "hab1Lev3_2");
                this.hab1Lev3_3 = this.getTextColumn("S1P3_3", "hab1Lev3_3");
                this.hab2Lev1 = this.getTextColumn("S2P1", "hab2Lev1");
                this.hab2Lev2 = this.getTextColumn("S2P2", "hab2Lev2");
                this.hab2Lev3_1 = this.getTextColumn("S2P3_1", "hab2Lev3_1");
                this.hab2Lev3_2 = this.getTextColumn("S2P3_2", "hab2Lev3_2");
                this.hab2Lev3_3 = this.getTextColumn("S2P3_3", "hab2Lev3_3");
                this.utilization = this.getTooltipColumn("Użytkowanie", "utilization");
                this.differentUtilization = this.getTextColumn("Użytkowanie inne","differentUtilization");
                this.when = this.getTooltipColumn("Kiedy","when", "whenEnum");
                this.rut = this.getTooltipColumn("Koleiny","rut");
                this.clumps = this.getTooltipColumn("Kępy","clumps");
                this.plantHeight = this.getTooltipColumn("Wysokość roślinności","plantHeight");
                this.plantHeightNumber = this.getNumberColumn("Wysokość roślinności","plantHeight");
                this.treeBushesArea = this.getTooltipColumn("Drzewa i krzewy","treeBushesArea");
                this.treeType = this.getTooltipColumn("Gatunek","treeType");
                this.bushHeight = this.getNumberColumn("Wysokość roślinności","bushHeight");
                this.cane = this.getTooltipColumn("Trzcina","cane");
                this.water = this.getTooltipColumn("Woda","water");
                this.nestPlace = this.getTooltipColumn("Ściółka","plantLitter");
                this.plantLitter = this.getTooltipColumn("Miejsce na gniazdo","nestPlace");
                this.habitatType = this.getTooltipColumn("Siedlisko", "habitatType");
                this.habitatType2 = this.getTooltipColumn("Siedlisko 2", "habitatType2", "habitatType");
                this.habitatTypeList = {header: "Siedlisko", field: "habitatType", filterField: "habitatType",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyContent: (data) => data.habitatType, filterItemsKey: "habitatType", ...this.multiSelectProps,
                    filterOtherAttrs: {}, filterType: "dictionary", isList: true};
                this.thermalModernization = this.getTooltipColumn("Termomodernizacja", "thermalModernization");
                this.swiftBoxes = this.getBooleanColumn("Budki dla jerzyków", "swiftBoxes");
                this.habitatNotes = this.getTextColumn("Uwagi", "habitatNotes");
                this.dominantVegetation = {header: "Jednostka roślinności", field: "dominantVegetation", filterField: "dominantVegetation",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: true,
                    bodyContent: (data) => data.dominantVegetation, filterItemsKey: "dominantVegetation", ...this.multiSelectProps,
                    filterOtherAttrs: {}, filterType: "dictionary"};
                this.spatial = this.getTooltipColumn("Uwarunkowanie przestrzenne", "spatial");
                this.succession = this.getTooltipColumn("Sukcesja", "succession");
                this.averageHeightPlant = this.getNumberColumn("Średnia wysokość roślinności","averageHeightPlant");
                this.averageHeightClump = this.getNumberColumn("Średnia wysokość kęp","averageHeightClump");
                this.location = this.getTooltipColumn("Położenie", "location");
                this.landform = this.getTooltipColumn("Rzeźba terenu", "landform");
                this.direction = this.getTooltipColumn("Ekspozycja stoku", "direction");
                this.waterInSoil = this.getTooltipColumn("Wilgnotność gruntu", "water");
                this.grassCovered = this.getNumberColumn("% pokrycia roślinnością trawiastą","grassCovered");
                this.perennialsCovered = this.getNumberColumn("% pokrycia bylinami i krzewinkami","perennialsCovered");
                this.shrubsCovered = this.getNumberColumn("% pokrycia krzewami (w tym kosodrzewiną)","shrubsCovered");
                this.rockSurface = this.getNumberColumn("% powierzchni skalnej","rockSurface");
                this.treeNumber = this.getNumberColumn("Liczba drzew","treeNumber");
                this.waterPresence = this.getBooleanColumn("Obecność potoków/ zbiorników wodnych", "waterPresence");
                this.crops = {header: "Uprawy w promieniu 500m", field: "crops", filterField: "crops",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyContent: (data) => data.crops, filterItemsKey: "crops", ...this.multiSelectProps,
                    filterOtherAttrs: {}, filterType: "dictionary", isList: true};
                this.buildingDistance = this.getNumberColumn("Przybliżona odległość do najbliższych zabudowań (m)","buildingDistance");
                this.waterDistance = this.getNumberColumn("Przybliżona odległość do najbliższej doliny rzecznej lub zbiornika wodnego (m)","waterDistance");
                this.slopeHeight = this.getNumberColumn("Szacunkowa wysokość skarpy (m)","slopeHeight");
                this.otherHabitatType = this.getTextColumn("Inny typ zbiornika", "otherHabitatType");
                this.otherColonyLocation = this.getTextColumn("Inne usytuowanie kolonii", "otherColonyLocation");
                this.treeArea = this.getTooltipColumn("Roślinność w kolonii - krzewy","treeArea");
                this.bushesArea = this.getTooltipColumn("Roślinność w kolonii - drzewa","bushesArea");
            },
            getTimeColumn(header, field) {
                return {header: header, field: field, filterField: null,
                    showFilterMatchModes: false, filterMenuClass: "", sortable: true, bodyLink: false,
                    bodyClass: () => "", bodyTooltip: false, bodyContent: (data) => data[field]?.slice(0,5),
                    filterOtherAttrs: {}}
            },
            getBooleanColumn(header, field) {
                return {header: header, field: field, filterField: field, showFilterMatchModes: false,
                    filterMenuClass: "w-16rem", sortable: false, bodyLink: false, bodyClass: (data) => data[field] ? "true-style" : "false-style",
                    bodyTooltip: false, bodyContent: (data) => this.computedBoolean(data[field]),
                    filterType: "selectOne", filterItems: [{label: 'Tak', id: true}, {label: 'Nie', id: false}],
                    filterItemLabel: "label", filterItemValue: "id", filterClass: "flex-1 w-0 mt-3",
                    filterOtherAttrs: {}, filterEmptyValue: false};
            },
            getTextColumn(header, field) {
                return {header: header, field: field, filterField: null, showFilterMatchModes: false,
                    filterMenuClass: "", sortable: false, bodyLink: false,
                    bodyClass: () => "", bodyTooltip: false, bodyContent: (data) => data[field], filterOtherAttrs: {}, filterType: "inputText"};
            },
            getNumberColumn(header, field) {
                return {header: header, field: field, filterField: field, showFilterMatchModes: false,
                    filterMenuClass: "w-16rem", sortable: true, bodyLink: false,
                    bodyClass: () => "", bodyTooltip: false, bodyContent: (data) => data[field],
                    filterType: "range", filterOtherAttrs: {}, range: {min: 0, max: 999}};
            },
            getTooltipColumn(header, field, filterItemsKey = field) {
                return {header: header, field: `${field}.label`, filterField: field, showFilterMatchModes: false,
                    filterMenuClass: "w-16rem", sortable: true, bodyLink: false,
                    bodyClass: () => "", bodyTooltip: true, bodyTooltipContent: (data) => data[field]?.label,
                    bodyContent: (data) => data[field]?.code,
                    filterItemsKey: filterItemsKey, ...this.multiSelectProps, filterItemLabel: "label",
                    filterItemValue: "key", filterOtherAttrs: {}, filterType: "dictionary"};
            },
            /* eslint-enable */

            getFieldLink(rowData) {
                return `/field/${rowData.field?.id}`;
            },
            getPositionLink(rowData) {
                return `/position/${rowData.position?.id}`;
            },
            getTransectLink(rowData) {
                return `/transect/${rowData.transect?.id}`;
            },
            getPointLink(rowData) {
                return `/transect/${rowData.point?.id}`;
            },
            computedBoolean(value) {
                if (value) return "Tak";
                return "Nie";
            },
        },

        watch: {
            "$route.params.programCode"(value) {
                this.programCode = value;
            },
        },
    };
</script>

<style lang="scss">
</style>
