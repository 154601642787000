<template>
    <div class="custom-multi-select field flex flex-column relative">
        <span class="p-float-label">
            <MultiSelect :id="name" :options="internalItems" :optionLabel="itemLabel" :optionValue="itemValue"
                         v-model="internalValue" :filter="filter" @hide="$emit('hide')"
                         @before-show="moveOnTop" :disabled="disabled" :dataKey="dataKey"
                         :virtual-scroller-options="scroll" :class="computedClasses"
                         :optionDisabled="itemDisabled" :autoFilterFocus="filter"
            />
            <CustomLabel v-if="label" :name="name" :label="label" :required="required" :disabled="disabled"/>
        </span>
        <CustomValidationMessage :errorMessage="errorMessage" />
        <CustomValidationMessage :errorMessage="customErrorMessage" />
        <CustomValidationMessage v-if="showRequiredError" :errorMessage="$t('message.validations.required')" />
    </div>
</template>

<script>
    import {useField} from "vee-validate";
    import MultiSelect from "primevue/multiselect";
    import CustomValidationMessage from "./CustomValidationMessage";
    import CustomLabel from "./CustomLabel";

    export default {
        name: "CustomMultiSelect",
        props: {
            name: {
                type: String,
            },
            showLabel: {
                type: Boolean,
                default: true,
            },
            label: {
                type: String,
            },
            itemLabel: {
                default: "label",
            },
            itemValue: {
                type: String,
                default: "",
            },
            itemDisabled: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: false,
            },
            items: {
                type: Array,
            },
            modelValue: null,
            filter: {
                type: Boolean,
                default: true,
            },
            customErrorMessage: {
                type: String,
                default: "",
            },
            showErrors: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            dataKey: {
                type: String,
                default: "",
            },
            preventSorting: {
                type: Boolean,
                default: false,
            },
            trash: null,
            virtualScrolling: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["update:modelValue", "hide"],

        data() {
            return {
                internalItems: [...this.items],
            };
        },

        mounted() {
            if (this.emptyValue) {
                this.internalItems = [...this.items];
            }
            // if (this.modelValue != null && this.modelValue !== "") {
            //     this.moveOnTop(this.modelValue);
            // }
        },

        watch: {
            items(newVal) {
                this.internalItems = [...newVal];
            },
        },

        methods: {
            moveOnTop() {
                const newVal = this.internalValue;
                if (this.preventSorting) return;
                let key;
                let secondKey;
                let arr = [];
                if (this.internalItems !== null && this.internalItems !== undefined && this.internalItems.length > 0) {
                    // eslint-disable-next-line
                    key = Object.keys(this.internalItems[0])[1];
                    // eslint-disable-next-line
                    secondKey = Object.keys(this.internalItems[0])[0];
                }
                if (newVal && (typeof newVal[0] === typeof this.internalItems[0]
                    && (typeof newVal[0] === "string" || typeof newVal[0] === "number"
                        || typeof newVal[0] === "boolean"))) {
                    let index;
                    for (let i = 0; i < newVal.length; i += 1) {
                        index = this.internalItems.indexOf(newVal[i]);
                        this.internalItems.splice(index, 1);
                    }
                    this.internalItems = [...newVal, ...this.internalItems];
                } else {
                    if (newVal && (typeof newVal[0] !== typeof this.internalItems[0])) {
                        for (let i = 0; i < newVal.length; i += 1) {
                            const index = this.internalItems.map((obj) => obj[secondKey]).indexOf(newVal[i]);
                            arr.push(this.internalItems[index]);
                        }
                    } else {
                        arr = [];
                        if (newVal) {
                            arr = newVal;
                        }
                    }
                    const newItems = arr.sort((a, b) => {
                        /* eslint-disable */
                        if (typeof a[key] === "boolean" || typeof b[key] === "boolean") return;

                        const first = a[key].toLowerCase();
                        const second = b[key].toLowerCase();
                        if (first < second) return -1;
                        if (first > second) return 1;
                        return 0;
                        /* eslint-enable */
                    });
                    let index;
                    for (let i = 0; i < newItems.length; i += 1) {
                        index = this.internalItems.map((obj) => obj[secondKey]).indexOf(newItems[i][secondKey]);
                        this.internalItems.splice(index, 1);
                    }
                    this.internalItems.sort((a, b) => {
                        /* eslint-disable */
                        if (typeof a[key] === "boolean" || typeof b[key] === "boolean") {
                            return (a[key] === b[key]) ? 0 : a[key] ? 1 : -1;
                        };

                        const first = a[key].toLowerCase();
                        const second = b[key].toLowerCase();

                        if (first < second) return -1;
                        if (first > second) return 1;
                        return 0;
                        /* eslint-enable */
                    });
                    this.internalItems = [...newItems, ...this.internalItems];
                }
            },
        },

        computed: {
            internalValue: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            computedClasses() {
                return (this.customErrorMessage !== "" || this.showRequiredError) ? "p-invalid" : "";
            },
            scroll() {
                return this.virtualScrolling ? { itemSize: 36 } : undefined;
            },
            showRequiredError() {
                return this.showErrors && (this.internalValue == null || this.internalValue.length === 0);
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {MultiSelect, CustomValidationMessage, CustomLabel},
    };
</script>
<style lang="scss">
    .custom-multi-select {
        .p-multiselect-trigger {
            border-left: 1px solid #ced4da;
        }
        .p-multiselect {
            width: 100%;
            .p-multiselect-label {
                position: relative;
                top: 2px;
            }
        }
    }
</style>
