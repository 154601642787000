<template>
    <DataTable :lazy="true" :value="programObserver" v-model:editingRows="editingRows" editMode="row"
               dataKey="id" @row-edit-save="onRowEditSave" :paginator="true"
               v-model:rows="page.limit" v-model:first="page.offset" :totalRecords="totalRecords"
               @page="onPage" @sort="onPage" removableSort class="observer-table"
               :rows="10" :rowsPerPageOptions="[10,25,50,100,10000]" size="small" @row-edit-init="storeControls"
               @row-edit-cancel="removeControls" v-model:filters="searchCriteria"
               filter-display="menu" @update:filters="search">
        <template v-if="editCast" #header>
            <AddObserver @newObserver="addNewObserverToTable" :programObserver="programObserver"
                         :availableRegCoordinatorFields="availableRegCoordinatorFields"
                         :availableFieldSubfields="availableFieldSubfields" @getFields="getFields"/>
        </template>
        <Column field="observer.name" header="Imię i nazwisko" sortable :showFilterMatchModes="false"
                filter-menu-class="w-16rem" filterField="observerName">
            <template #filterclear="{ filterCallback }">
                <FilterClear :callback="filterCallback" />
            </template>
            <template #filterapply="{ filterCallback }">
                <FilterApply :callback="filterCallback" />
            </template>
            <template #body="slotProps">
                {{ slotProps.data.observer.name }}
            </template>
        </Column>
        <Column v-if="hasRegionalCoordinators" header="Koordynator regionalny" field="regionalCoordinator">
            <template #body="slotProps">
                {{ slotProps.data.regionalCoordinator?.name }}
            </template>
            <template #editor="{data,field}">
                <CustomSelectOne :items="regionalCoordinatorsItems" itemLabel="name" name="regionalCoordinators"
                                 v-model="data[field]" filter @change="clearRowFields(data)"/>
            </template>
        </Column>
        <Column field="fields" header="Powierzchnie"
                filterField="fieldName" :showFilterMatchModes="false" filter-menu-class="w-16rem">
            <template #filter="{filterModel}">
                <CustomSelectOne :label="hasSubfields ? 'Podpowierzchnia' : 'Powierzchnia'" :items="fields"
                                 itemLabel="name" itemValue="name" v-model="filterModel.value"
                                 class="flex-1 w-0" name="programObserverFieldName" />
            </template>
            <template #filterclear="{ filterCallback }">
                <FilterClear :callback="filterCallback" />
            </template>
            <template #filterapply="{ filterCallback }">
                <FilterApply :callback="filterCallback" />
            </template>
            <template #body="slotProps">
                <div>
                    {{ showMore
                        ? getFieldsString(slotProps.data.fields)
                        : textTruncate(getFieldsString(slotProps.data.fields)) }}
                </div>
                <span v-if="(getFieldsString(slotProps.data.fields)).length > 30" @click="showMore = !showMore"
                      class="cursor-pointer uppercase">
                    {{ showMore ? "Pokaż mniej" : "Pokaż więcej" }}
                </span>
            </template>
            <template #editor="{data,field,index}">
                <CustomMultiSelect :items="getFieldsForRow(data,index)" itemLabel="name" prevent-sorting
                                   name="fields" v-model="data[field]"
                                   :disabled="data.regionalCoordinator === null && hasRegionalCoordinators"/>
            </template>
        </Column>
        <Column v-if="hasSubfields" field="subfields" header="Podpowierzchnie (Powierzchnia)">
            <template #body="slotProps">
                <div>
                    {{ showMore
                        ? getFieldsString(slotProps.data.subfields)
                        : textTruncate(getFieldsString(slotProps.data.subfields)) }}
                </div>
                <span v-if="(getFieldsString(slotProps.data.subfields)).length > 30" @click="showMore = !showMore"
                      class="cursor-pointer uppercase">
                    {{ showMore ? "Pokaż mniej" : "Pokaż więcej" }}
                </span>
            </template>
            <template #editor="{data,field,index}">
                <CustomMultiSelect :items="getSubfieldsForRow(data,index)" :itemLabel="subfieldWithField"
                                   name="subfields" v-model="data[field]"
                                   :disabled="data.fields == null || data.fields.length === 0"/>
            </template>
        </Column>
        <Column v-if="programType === 'POSITION'" field="positions" header="Stanowiska">
            <template #body="slotProps">
                {{ join(slotProps.data.positions) }}
            </template>
            <template #editor="{data,field,index}">
                <CustomMultiSelect :items="getPositionsForRow(data,index)" itemLabel="name"
                                   name="positions" v-model="data[field]"/>
            </template>
        </Column>
        <Column v-if="!hasAllControls" field="controls" header="Kontrole">
            <template #body="slotProps">
                {{ joinControls(slotProps.data) }}
            </template>
            <template #editor="{data,field,index}">
                <div v-if="!hasSubfields">
                    <div v-for="f in data.fields" :key="'f_' + f.id">
                        {{ f.name }}:
                        <CustomMultiSelect :items="getControlsForRow(index,f)"
                                           name="controls" v-model="data[field][f.id]"/>
                    </div>
                </div>
                <div v-else>
                    <div v-for="f in data.subfields" :key="'f_' + f.id">
                        {{ f.name }}:
                        <CustomMultiSelect :items="getControlsForRow(index,f)"
                                           name="controls" v-model="data[field][f.id]"/>
                    </div>
                </div>
            </template>
        </Column>
        <Column v-if="editCast" :rowEditor="true" style="width: 120px; text-align: center;" />
        <Column v-if="editCast" style="width: 60px; text-align: center;">
            <template #body="slotProps">
                <CustomButton icon="pi pi-trash" borderColor="transparent" @click="deleteObserver(slotProps.data)" />
            </template>
        </Column>
    </DataTable>
</template>

<script>
/* eslint-disable */
    import _ from "lodash";
    import {computed, ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchProgramObserver,
        searchProgramObserverCount,
        editProgramObserversCast,
        deleteProgramObserverFromCast,
        getFreeFieldsForCoordinators, getFreeObservationsInProgramEdition,
    } from "@/swagger/vue-api-client";
    import CustomMultiSelect from "../../../../components/form/inner/CustomMultiSelect.vue";
    import CustomSelectOne from "../../../../components/form/inner/CustomSelectOne.vue";
    import CustomButton from "../../../../components/form/CustomButton.vue";
    import ListUtils from "@/utils/ListUtils";
    import AddObserver from "./AddObserver";
    import CustomInputText from "../../../../components/form/CustomInputText.vue";
    import FilterApply from "../../../../components/filters/FilterApply.vue";
    import FilterClear from "../../../../components/filters/FilterClear.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {FiltersUtils} from "@/utils/FiltersUtils";

    export default {
        name: "ObserverTable",

        components: {
            DataTable,
            Column,
            CustomMultiSelect,
            CustomSelectOne,
            CustomButton,
            AddObserver,
            CustomInputText,
            FilterApply,
            FilterClear,
        },

        props: {
            modelValue: null,
            editCast: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["hasRegionalCoordinators", "hasSubfields", "regionalCoordinators",
                 "programType", "hasAllControls", "fields"],

        data() {
            return {
                programObserver: [],
                editingRows: ref([]),
                totalRecords: 0,
                newObserver: null,
                availableRegCoordinatorFields: new Map(),
                freeFields: [],
                // fields: [],
                subfields: [],
                fieldSubfields: new Map(),
                availableFieldSubfields: new Map(),
                regionalCoordinatorsItems: [],
                fieldMap: new Map(),
                freeFieldsWithRegionalMap: {},
                freeSubfieldsOnFieldMap: {},
                freePositionsOnFieldMap: {},
                freeControlsOnFieldMap: {},
                programEditionId: this.$route.params.id,
                storedEditedControls: {},
                searchCriteria: SearchCriteria.getClearSearchCriteriaObserver(this.$route.params.id),
                page: SearchCriteria.getClearPage(),
                showMore: false,
            };
        },

        emits: ["update:modelValue", "reloadProgramState"],

        beforeMount() {
            if (FiltersUtils.loadFromSessionStorage(`${this.$route.path}[observers]`)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(`${this.$route.path}[observers]`);
            }
            this.search();
            this.regionalCoordinatorsItems = this.regionalCoordinators.map((rc) => rc.coordinator);
        },

        provide() {
            return {
                freeFieldsWithRegionalMap: computed(() => this.freeFieldsWithRegionalMap),
                freeSubfieldsOnFieldMap: computed(() => this.freeSubfieldsOnFieldMap),
                freeFields: computed(() => this.freeFields),
                programEditionId: this.programEditionId,
                freePositionsOnFieldMap: computed(() => this.freePositionsOnFieldMap),
                freeControlsOnFieldMap: computed(() => this.freeControlsOnFieldMap),
            };
        },

        beforeUpdate() {
            this.getFields();
        },

        methods: {
            getFields() {
                if (this.editCast) {
                    if (this.hasRegionalCoordinators) {
                        getFreeFieldsForCoordinators({programEditionId: this.programEditionId})
                            .then((response) => {
                                this.freeFieldsWithRegionalMap = response.data;
                            });
                    } else {
                        getFreeObservationsInProgramEdition({
                            programEditionId: this.programEditionId,
                            requestType: "fields",
                        }).then((response) => {
                            this.freeFields = response.data;
                        });
                    }
                    if (this.hasSubfields) {
                        getFreeObservationsInProgramEdition(
                            {
                                programEditionId: this.programEditionId,
                                requestType: "subfields",
                            })
                            .then((response) => {
                                this.freeSubfieldsOnFieldMap = response.data;
                            });
                    } if (this.programType === "POSITION") {
                        getFreeObservationsInProgramEdition({
                            programEditionId: this.programEditionId,
                            requestType: "positions",
                        }).then((response) => {
                            this.freePositionsOnFieldMap = response.data;
                        });
                    } if (!this.hasAllControls) {
                        getFreeObservationsInProgramEdition({
                            programEditionId: this.programEditionId,
                            requestType: "controls",
                        }).then((response) => {
                            this.freeControlsOnFieldMap = response.data;
                        });
                    }
                }
            },
            clearRowFields(data) {
                data.fields = [];
                data.subfields = [];
                data.positions = [];
                data.controls = {};
            },
            search() {
                const criteria = SearchCriteria.prepareCriteria(
                    this.searchCriteria,
                    this.page,
                    this.filterDescription,
                );
                const parameters = {id: this.$route.params.id, body: criteria};
                searchProgramObserver(parameters)
                    .then((response) => {
                        this.programObserver = response.data;
                        this.programObserver.forEach((po) => {
                            if (!this.hasAllControls) {
                                po.controls = {};
                                po.fields.forEach((field) => {
                                    po.controls[field.field.id] = field.controls;
                                });
                            }
                            if (this.hasSubfields) {
                                po.subfields = po.fields.map((f) => f.field);
                                const fields = {};
                                po.fields.forEach((f) => {
                                    fields[f.field?.superField?.id] = f.field?.superField?.name;
                                });
                                po.fields = Object.keys(fields).map((f) => ({id: parseInt(f), name: fields[f]}));
                                // po.subfields.forEach((s) => delete s.superField);
                            } else {
                                po.fields = po.fields.map((f) => f.field);
                            }
                        });
                        // this.updateAvailableFieldSubfields();
                        // this.updateAvailableFields();
                        return searchProgramObserverCount(parameters);
                    })
                    .then((response) => {
                        this.totalRecords = response.data;
                    });
            },
            onPage(event) {
                this.searchCriteria.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
                this.search();
            },
            onRowEditSave(event) {
                const {newData, index} = event;
                this.programObserver[index] = newData;

                const editObserver = {
                    userId: newData.observer.id,
                    positionIDs: this.programType === 'POSITION' ? newData.positions.map((pos) => pos.id) : null,
                    regionalCoordinatorId: newData.regionalCoordinator?.id ? newData.regionalCoordinator.id : null,
                };

                if (this.hasSubfields) {
                    editObserver.observations = newData.subfields.map((field) => ({fieldId: field.id}));
                } else {
                    editObserver.observations = newData.fields.map((field) => ({fieldId: field.id}));
                }
                if (!this.hasAllControls) {
                    editObserver.observations.forEach((observation) => {
                        observation.control = newData.controls[observation.fieldId].map((control) => control.key);
                    });
                }

                editProgramObserversCast({
                    programEditionId: this.$route.params.id,
                    body: {observerRequestList: [editObserver]},
                });
            },
            addNewObserverToTable(value) {
                this.newObserver = value;
                this.$emit("reloadProgramState");
            },
            deleteObserver(observer) {
                deleteProgramObserverFromCast({
                    programEditionId: this.$route.params.id,
                    observerId: observer.observer.id,
                    regionalId: this.hasRegionalCoordinators ? observer.regionalCoordinator?.id : null,
                });
                const index = this.programObserver.indexOf(observer);
                this.programObserver.splice(index, 1);
                this.$emit("reloadProgramState");
            },
            join(fields) {
                if (fields == null || fields.length === 0) return "";
                return ListUtils.join(fields, "name");
            },
            joinControls(data) {
                const { controls } = data;
                let fields;
                if (this.hasSubfields) {
                    fields = data.subfields;
                } else {
                    fields = data.fields;
                }
                if (controls == null || controls.length === 0) return "";
                const label = fields.map((field) => field.name + ": "
                    + ListUtils.join(controls[field.id], "label") + "\n");
                return ListUtils.join(label);
            },
            getFieldsString(fields) {
                return fields?.map((field) => {
                    if (field.superField) return field.name + ` (${field.superField?.name})`;
                    return field.name
                }).join(", ");
            },
            textTruncate(text) {
                if (text.length > 30) {
                    return _.truncate(text, {
                        length: 30,
                        separator: ",",
                    });
                }
                return text;
            },
            getFieldsForRow(data, index) {
                if (this.hasRegionalCoordinators) {
                    if (data.regionalCoordinator == null) return [];
                    const regCoordinator = data.regionalCoordinator.id;
                    if (regCoordinator === this.programObserver[index].regionalCoordinator.id) {
                        const set = new Set(this.programObserver[index].fields
                            .concat(this.freeFieldsWithRegionalMap[regCoordinator])
                            .map(JSON.stringify));
                        return [...set]
                            .map(JSON.parse)
                            .sort((a, b) => a.name.localeCompare(b.name));
                    }
                    return this.freeFieldsWithRegionalMap[regCoordinator];
                }
                return [...new Set((this.freeFields || []).concat(this.programObserver[index].fields)
                    .map(JSON.stringify))]
                    .map(JSON.parse).sort((a, b) => a.name.localeCompare(b.name));
            },
            getSubfieldsForRow(data, index) {
                if (data.fields == null) return [];
                const fields = data.fields.map((field) => field.id);
                const fieldsSet = new Set(fields);
                const oldSubfields = this.programObserver[index].subfields
                    .filter((subfield) => fieldsSet.has(subfield.superField.id))
                    .filter((subfield) =>
                        !((this.freeSubfieldsOnFieldMap[subfield.superField.id] || []).map((o) => o.id).includes(subfield.id)));
                return oldSubfields.concat(fields.reduce(
                    (acc, id) => acc.concat(this.freeSubfieldsOnFieldMap[id] || []), [],
                ));
            },
            getPositionsForRow(data, index) {
                let fields;
                if (this.hasSubfields) {
                    fields = data.subfields.map((field) => field.id);
                } else {
                    fields = data.fields.map((field) => field.id);
                }
                const fieldSet = new Set(fields);
                let oldPositions;
                if (this.hasAllControls) {
                    oldPositions = this.programObserver[index].positions
                        .filter((position) => fieldSet.has(position.fieldId));
                } else {
                    oldPositions = [];
                }
                return oldPositions.concat(fields.reduce(
                    (acc, id) => acc.concat(this.freePositionsOnFieldMap[id] || []), [],
                ));
            },
            getControlsForRow(index, field) {
                const oldControls = (this.storedEditedControls[index][field.id] || [])
                    .filter((control) => !(this.freeControlsOnFieldMap[field.id] || []).map((c) => c.key)
                        .includes(control.key));
                return oldControls.concat(this.freeControlsOnFieldMap[field.id] || []);
            },
            storeControls(event) {
                if (!this.hasAllControls) {
                    this.storedEditedControls[event.index] = JSON.parse(JSON.stringify(event.data.controls));
                }
            },
            subfieldWithField(item) {
                if (item) {
                    return item.name + " " + `(${item.superField.name})`;
                }
                return "";
            },
            removeControls(event) {
                if (!this.hasAllControls) {
                    this.programObserver[event.index].controls = this.storedEditedControls[event.index];
                    delete this.storedEditedControls[event.index];
                }
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    FiltersUtils.saveToSessionStorage(`${this.$route.path}[observers]`, value);
                },
                deep: true,
            },
            newObserver(value) {
                this.programObserver.push(value);
                this.totalRecords += 1;
            },
            editingRows(value) {
                value.forEach((row) => {
                    row.controls = _.cloneDeep(row.controls);
                });
            }
        },

        computed: {
            filterDescription() {
                return SearchCriteria.getSearchCriteriaObserverFilterDescription();
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../../assets/theme/mytheme/variables";
</style>
