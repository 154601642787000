<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing"
                      v-model:step="step" v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :someGeobjectWasAudited="someGeobjectWasAudited"
                      :validationCorrect="validationCorrect" @goTo2Step="validateFirstStep"
                      :noPosition="form.noPosition">
        <template v-slot:formMap>
            <div class="px-3">
                <CustomAlertBox class="flex justify-content-start w-full mb-3" :disabled="!editing"
                                text="Brak stanowisk" v-model="form.noPosition"/>
                <CustomCalendar v-if="form.noPosition" class="w-4" label="Data liczenia"
                                v-model="form.noPositionControlDate" name="controlDate" />
            </div>
            <FormPositions v-if="!form.noPosition" v-model="form.positions" :field="form.field" @reloadFormData="getFormData"
                           dynamicPositions />
        </template>
        <template v-slot:formObservations>
            <FormMFGPObservations :field="form.field.shape" :allPositions="form.positions"
                                  ref="observationsView" v-model:step="step" v-model:form="form" />
        </template>
    </FormViewTemplate>
</template>

<script>
    import {computed} from "vue";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import FormPositions from "../../components/formSharedComponents/FormPositions.vue";
    import FormMFGPObservations from "./components/FormMFGPObservations.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import CustomAlertBox from "@/components/form/CustomAlertBox.vue";
    import {
        getForm,
        getList as getDict,
    } from "@/swagger/vue-api-client";

    export default {
        name: "FormMFGPView",

        components: {
            FormViewTemplate,
            FormPositions,
            FormMFGPObservations,
            CustomCalendar,
            CustomAlertBox,
        },

        data() {
            return {
                programCode: "MFGP",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                step: 1,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                controlTimeFrame: computed(() => this.form.control),
                programEditionId: computed(() => this.form.programEditionId),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            this.getFormData();
        },

        methods: {
            getFormData() {
                getForm({program: this.programCode, formId: this.$route.params.id, controlCode: this.$route.query.controlCode})
                    .then((response) => {
                        this.form = response.data;
                        ValidateUtils.flattenData(this.form.positions, "position");
                        console.log(this.form);
                        this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                        return getDict({programCode: this.programCode, formId: this.$route.params.id});
                    })
                    .then((response) => {
                        this.dicts = response.data;
                        this.dictsFilterBySpecies();
                        console.log(this.dicts);
                        // przypisanie do danych przychodzących z bazy odpowiedniego info do obiektu aby wartość była zgodna ze słownikową i wyświetlała się w CustomSelectOne
                        this.form.positions.forEach((p) => {
                            this.dicts.habitatType.forEach((hT) => {
                                if (p.habitatType?.code === hT.code) {
                                    p.habitatType.info = hT.info;
                                }
                            });
                            this.dicts.controlMethod.forEach((cM) => {
                                if (this.$route.query.controlCode === "Bocian" && p.control1?.controlMethod?.code === cM.code) {
                                    p.control1.controlMethod.info = cM.info;
                                } else if (this.$route.query.controlCode === "Bocian" && p.control2?.controlMethod?.code === cM.code) {
                                    p.control2.controlMethod.info = cM.info;
                                } else if (p.controlMethod?.code === cM.code) {
                                    p.controlMethod.info = cM.info;
                                }
                            });
                            this.dicts.nestingCriteria.forEach((nC) => {
                                if (this.$route.query.controlCode === "Bocian" && p.control1?.nestingCriteria?.code === nC.code) {
                                    p.control1.nestingCriteria.info = nC.info;
                                } else if (this.$route.query.controlCode === "Bocian" && p.control2?.nestingCriteria?.code === nC.code) {
                                    p.control2.nestingCriteria.info = nC.info;
                                } else if (p.nestingCriteria?.code === nC.code) {
                                    p.nestingCriteria.info = nC.info;
                                }
                            });
                        });
                        this.loaded = true;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loaded = false;
                    });
            },
            validateControl(control, i) {
                let isCorrect = true;
                if (control.noControl !== true) {
                    isCorrect = ValidateUtils.validateNotEmpty(control, ["controlDate", "controlMethod", "nestingCriteria"]) && isCorrect;
                    isCorrect = ValidateUtils.validateIsDateInRange(control.controlDate, this.dicts.controlType[i].controlStart, this.dicts.controlType[i].controlEnd) && isCorrect;
                }
                if (control.noControl) {
                    isCorrect = ValidateUtils.validateNotEmpty(control, ["noControlReason"]) && isCorrect;
                }
                return isCorrect;
            },
            validateData() {
                const bird = this.$route.query.controlCode;
                const controlDateBirds = ["Zuraw", "Gawron", "Perkoz", "Blotniak", "Bak"];
                const nestingCriteriaBirds = ["Zuraw", "Blotniak", "Bak"];
                let isCorrect = ValidateUtils.validateNoFormControl(this.form);
                if (isCorrect && !this.form.noFormControl) {
                    isCorrect = this.validateFirstStep() && isCorrect;
                    for (let i = 0; i < this.form.positions.length; i += 1) {
                        let positionIsCorrect = true;
                        const position = this.form.positions[i];
                        if (position.notAudited === false
                            || position.notAudited === null
                            || position.notAudited === undefined) {
                            positionIsCorrect = ValidateUtils.validateNotEmpty(position, ["habitatType"]) && positionIsCorrect;
                            if (controlDateBirds.includes(bird)) {
                                positionIsCorrect = ValidateUtils.validateNotEmpty(position, ["controlDate"]) && positionIsCorrect;
                                positionIsCorrect = ValidateUtils.validateIsDateInRange(position.controlDate, this.form.control.controlStart, this.form.control.controlEnd) && positionIsCorrect;
                            }
                            if (nestingCriteriaBirds.includes(bird)) {
                                if (!position.noBirds) {
                                    positionIsCorrect = ValidateUtils.validateNotEmpty(position, ["nestingCriteria"]) && positionIsCorrect;
                                }
                            }
                            if (bird === "Gawron") {
                                positionIsCorrect = ValidateUtils.validateBirds(
                                    position.birds, position.noBirds, ["nests", "treeType"],
                                ) && positionIsCorrect;
                            }
                            if (bird === "Perkoz") {
                                positionIsCorrect = ValidateUtils.validateBirds(
                                    position.birds, position.noBirds, ["pairs", "nestingCriteria"],
                                ) && positionIsCorrect;
                            }
                            if (bird === "Zuraw") {
                                positionIsCorrect = ValidateUtils.validateNotEmpty(position, ["controlMethod"]) && positionIsCorrect;
                            }
                            if (bird === "Bocian") {
                                positionIsCorrect = this.validateControl(position.control1, 0) && positionIsCorrect;
                                positionIsCorrect = this.validateControl(position.control2, 1) && positionIsCorrect;
                            }
                        }
                        if (position.error) delete position.error; // remove previous checks
                        if (!positionIsCorrect) {
                            position.error = true;
                            isCorrect = false;
                        }
                    }
                }
                this.validationCorrect = isCorrect;
                this.request = this.getFormRequest();
                console.log(this.request);
            },
            getFormRequest() {
                const request = ValidateUtils.getFormRequest(this.form, this.programCode + `-${this.$route.query.controlCode.toLowerCase()}`, "positions");
                if (!this.form.noFormControl) {
                    for (let i = 0; i < this.form.positions.length; i += 1) {
                        ValidateUtils.formatControlDateStartTimeAndEndTime(this.form.positions[i].control1);
                        ValidateUtils.formatControlDateStartTimeAndEndTime(this.form.positions[i].control2);
                        request.positions.push(this.form.positions[i]);
                    }
                }
                return request;
            },
            validateFirstStep() {
                const errors = ValidateUtils.validateNotAudited(this.form.positions, "Stanowisko");
                this.addErrorToasts(errors);
                return errors.length === 0;
            },
            addErrorToasts(errors) {
                errors.forEach((message) => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: message,
                        life: 5000,
                    });
                });
            },
            dictsFilterBySpecies() {
                if (this.$route.query.controlCode) {
                    Object.keys(this.dicts).forEach((key) => {
                        const filteredDictsElements = [];
                        this.dicts[key].forEach((dict) => {
                            if (dict.info) {
                                dict.info.forEach((inf) => {
                                    if (inf.key.split("_")[0] === this.$route.query.controlCode.toUpperCase()) {
                                        filteredDictsElements.push(dict);
                                    }
                                });
                                this.dicts[key] = filteredDictsElements;
                            }
                        });
                    });
                }
            },
        },

        computed: {
            someGeobjectWasAudited() {
                return ValidateUtils.someGeobjectWasAudited(this.form.positions, this.form.noFormControl);
            },
        },
    };
</script>

<style lang="scss">
</style>
