<template>
    <div>
        <DataTable :value="fields" :totalRecords="totalRecords" :loading="loading" :search-criteria="searchCriteria"
                   @page="onPage" @sort="onPage" lazy
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink
                   LastPageLink RowsPerPageDropdown"
                   :currentPageReportTemplate="currentPageReportTemplate"
                   paginator :rows="10" :rowsPerPageOptions="[10,25,50,100]"
                   v-model:rows="searchParams.page.limit" v-model:first="searchParams.page.offset">
            <Column header="Nazwa" field="name"></Column>
            <Column header="Program" field="program"></Column>
            <Column header="Działania">
                <template #body="slotProps">
                    <div class="flex gap-3">
                        <CustomButton label="Wyświetl szczegóły" @click="navigateToField(slotProps.data.id)"/>
                        <CustomButton label="Edytuj" @click="navigateToEditField(slotProps.data.id)"/>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchFields,
        searchFieldsCount,
    } from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton";

    export default {
        name: "PositionDictTable",

        components: {
            CustomButton,
            DataTable,
            Column,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        data() {
            return {
                fields: null,
                editingRows: ref([]),
                loading: false,
                totalRecords: 0,
            };
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                searchFields({body: this.searchCriteria})
                    .then((response) => {
                        this.fields = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        if (err.response.status === 403) {
                            this.$router.push({name: "forbidden"});
                        }
                    });
            },

            updateTotalRecords() {
                searchFieldsCount({body: this.searchCriteria})
                    .then((response) => {
                        this.totalRecords = response.data;
                    });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },

            navigateToField(id) {
                this.$router.push(`/field/${id}`);
            },

            navigateToEditField(id) {
                this.$router.push(`/edit-field/${id}`);
            },

            onRowEditSave(event) {
                const {newData, index} = event;

                this.fields[index] = newData;
            },
        },

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },
            currentPageReportTemplate() {
                return "Rekordy od "
                    + "{first}"
                    + " do "
                    + "{last}"
                    + " z "
                    + "{totalRecords}"
                    + " (Strona {currentPage}"
                    + " z "
                    + "{totalPages})";
            },
        },
    };
</script>

<style lang="scss">
</style>
