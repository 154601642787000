<template>
    <div v-if="!isAdmin" class="flex flex-1 flex-wrap add-in-table-header flex-column gap-3" v-focustrap>
        <div class="flex flex-1 gap-2 flex-wrap align-items-center">
            <CustomButton icon="pi pi-copy" style="margin-bottom: 1rem"
                          title="Skopiuj ostatnio dodany gatunek"
                          :disabled="!editing" @click="copyLastSpecies"
                          v-on:keydown.enter="copyLastSpecies" />
            <div class="w-1">
                <CustomSelectOne label="Kod" name="species" v-model="newItem.species" required :showErrors="showErrors"
                                 :items="dicts.programBirds" :disabled="!editing"
                                 itemLabel="code"/>
            </div>
            <div class="w-1">
                <CustomSelectOne label="Gatunek" name="species" v-model="newItem.species" required
                                 :showErrors="showErrors" :disabled="!editing"
                                 :items="dicts.programBirds" itemLabel="name" />
            </div>
            <div class="w-1">
                <CustomInputNumber label="L. samców" name="males" v-model="newItem.males"
                                   required :showErrors="showErrors" :disabled="!editing" />
            </div>
            <div class="w-1">
                <CustomInputNumber label="L. samic" name="females" v-model="newItem.females"
                                   required :showErrors="showErrors" :disabled="!editing" />
            </div>
            <div class="w-1">
                <CustomInputNumber label="L. nieozn" name="otherAdults" v-model="newItem.otherAdults"
                                   required :showErrors="showErrors" :disabled="!editing" />
            </div>
            <div class="w-1">
                <CustomInputNumber label="L. młod" name="young" v-model="newItem.young"
                                   required :showErrors="showErrors" :disabled="!editing" />
            </div>
            <div class="w-1">
                <CustomInputNumber label="Suma l. os." name="count" v-model="newItem.count"
                                   required :showErrors="showErrors" disabled class="disabled-not-opacity" />
            </div>
            <div class="w-2">
                <CustomSelectOne label="Czas reakcji" name="reaction" v-model="newItem.reaction"
                                 :items="dicts.reaction" :showErrors="showErrors"
                                 :disabled="!editing" required/>
            </div>
            <div class="w-1">
                <CustomInputNumber label="L. rewirów" name="districts" v-model="newItem.districts"
                                   :customErrorMessage="getDistrictsErrorMessage()"
                                   required :showErrors="showErrors" :disabled="!editing" />
            </div>
            <CustomButton v-if="editing" icon="pi pi-plus" label="Dodaj" @click="add" :error="error"
                          style="position: absolute; right: 14px; margin-bottom: 1rem" v-on:keydown.tab="add"/>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import FocusTrap from "primevue/focustrap";
    import {SystemRole} from "@/utils/SystemRole";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "AddBirdsMSKR",

        components: {
            CustomSelectOne,
            CustomInputNumber,
            CustomButton,
        },

        directives: {
            focustrap: FocusTrap,
        },

        props: {
            modelValue: {
                type: Object,
            },
            error: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["editing", "dicts"],

        data() {
            return {
                newItem: _.cloneDeep(EmptyStructures.getEmptyBirds()),
                showErrors: false,
                speciesName: "",
                speciesCode: "",
                noteSex: true,
                noteAge: true,
                sexNotNotedValue: null,
                ageNotNotedValue: null,
            };
        },

        methods: {
            getDistrictsErrorMessage() {
                if (ValidateUtils.validateNotEmpty(this.newItem, ["districts", "count"])
                    && this.newItem.districts > this.newItem.count) {
                    return "L. rewirów większa niż Suma l. os.";
                }
                return "";
            },
            add() {
                if (!ValidateUtils.validateNotEmpty(this.newItem, ["species", "count", "males", "females", "otherAdults", "young", "reaction", "districts"])
                    || this.newItem.districts > this.newItem.count) {
                    this.showErrors = true;
                } else {
                    this.birds.push({
                        ...this.newItem,
                        ordinal: this.birds.length + 1,
                    });
                    this.newItem = _.cloneDeep(EmptyStructures.getEmptyBirds());
                    this.showErrors = false;
                }
            },
            copyLastSpecies() {
                if (this.birds?.length > 0) {
                    this.newItem.species = this.birds[this.birds.length - 1].species;
                }
            },
        },

        computed: {
            birds: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            isAdmin() {
                return SystemRole.isAdmin();
            },
        },

        watch: {
            birds: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
            /* eslint-disable */
            "newItem.males"(value) {
                this.newItem.count = value + this.newItem.females + this.newItem.otherAdults + this.newItem.young;
            },
            "newItem.females"(value) {
                this.newItem.count = this.newItem.males + value + this.newItem.otherAdults + this.newItem.young;
            },
            "newItem.otherAdults"(value) {
                this.newItem.count = this.newItem.males + this.newItem.females + value + this.newItem.young;
            },
            "newItem.young"(value) {
                this.newItem.count = this.newItem.males + this.newItem.females + this.newItem.otherAdults + value;
            }
            /* eslint-enable */
        },
    };
</script>

<style lang="scss">
.add-in-table-header {
    margin-bottom: -1rem,
}
.disabled-not-opacity {
    .p-disabled, .p-component:disabled {
        opacity: 1;
    }
}
</style>
