<template>
    <Toast />
    <ImportTransectDialog v-model="showImportTransectDialog"/>
    <div class="flex justify-content-between">
        <div class="filters flex gap-3 row-gap-0">
            <div class="flex gap-3 flex-wrap row-gap-0">
                <CustomMultiSelect id="programFilter" label="Program" name="programFilter" item-label=""
                                   v-model="searchCriteria.programs" :items="programs" class="w-8rem"
                                   itemLabel="" />
                <CustomMultiSelect id="fieldFilter" name="fieldFilter" v-model="searchCriteria.fieldNames"
                                   :items="fields" label="Filtruj według nazwy powierzchni"
                                   :disabled="fields.length < 1" item-label="name" class="w-20rem"
                                   virtual-scrolling />
                <CustomInputText id="name" name="name" v-model="searchCriteria.name" placeholder="Szukaj według nazwy"
                                 label="Nazwa" />
                <GeographicalFilters v-model="searchCriteria" ref="geographicalFilters"
                                     v-model:matchMode="searchCriteria.matchMode" :filterBy="matchModeOptions" />
            </div>
            <div class="flex align-self-end justify-content-end field">
                <CustomButton label="Wyczyść" @click="clear"/>
            </div>
        </div>
    </div>
    <div class="flex gap-3 justify-content-end flex-wrap mb-3">
        <CustomButton @click="navigateToCreateTransect" label="Utwórz transekt" />
        <CustomButton label="Eksportuj" @click="exportData" />
        <CustomButton label="Importuj" @click="showImportTransectDialog = true" />
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import {getListOfPrograms, listFieldsForProgram} from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import { FileUtils } from "@/utils/FileUtils";
    import ImportTransectDialog from "@/views/transectTable/ImportTransectDialog.vue";
    import GeographicalFilters from "@/components/filters/GeographicalFilters.vue";

    export default {
        name: "TransectSearchCriteria",
        components: {
            CustomMultiSelect,
            CustomInputText,
            CustomButton,
            ImportTransectDialog,
            Toast,
            GeographicalFilters,
        },

        props: {
            modelValue: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                transects: [],
                loading: false,
                totalRecords: 0,
                programs: [],
                fields: [],
                showImportTransectDialog: false,
                matchModeOptions: [
                    {value: "FIELD", label: "Powierzchni"},
                    {value: "TRANSECT", label: "Transektach"},
                ],
            };
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    this.getFieldsForPrograms();
                    this.$emit("update:modelValue", value);
                },
                deep: true,
            },
        },

        beforeMount() {
            getListOfPrograms()
                .then((response) => {
                    this.programs = response.data;
                });
        },

        methods: {
            getFieldsForPrograms() {
                if (!this.searchCriteria.programs || this.searchCriteria.programs.length === 0) {
                    this.fields = [];
                    return;
                }
                const promises = this.searchCriteria.programs.map((program) => listFieldsForProgram({ program }));
                Promise.all(promises)
                    .then((responses) => {
                        this.fields = responses.reduce((acc, response) => acc.concat(response.data), []);
                    })
                    .catch((error) => {
                        console.error("An error occurred:", error);
                    });
            },

            navigateToCreateTransect() {
                this.$router.push("/create-transect");
            },
            viewDetails(id) {
                this.$router.push(`transect/${id}`);
            },

            navigateToEditTransect(id) {
                this.$router.push(`/edit-transect/${id}`);
            },
            exportData() {
                FileUtils.downloadFile(this.modelValue, "transekty.xlsx", "/transect/export");
            },
            update() {
                this.$emit("update:modelValue", this.searchCriteria);
            },
            clear() {
                this.searchCriteria.programs = [];
                this.searchCriteria.name = "";
                this.searchCriteria.field.name = "";
                this.searchCriteria.order = null;
                this.$refs.geographicalFilters.clear();
                this.update();
            },
        },

        computed: {
            searchCriteria: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },
    };
</script>

<style scoped lang="scss"></style>
