<template>
    <div class="programs-table programs-table-observer">
        <DataTable dataKey="id" :value="programs" selectionMode="single" :lazy="true" :paginator="true"
                   v-model:rows="page.limit" v-model:first="page.offset" :totalRecords="totalRecords"
                   @page="onPage" @sort="onPage" removableSort :rows="10" :rowsPerPageOptions="[5,10,20,50,100]"
                   :loading="loading" size="small" v-model:expandedRows="expandedRows" @row-click="expandRow"
                   v-model:filters="searchCriteria" filterDisplay="menu" @update:filters="search">
            <Column header="Program" field="program" class="w-4 program-header header-border"
                    filterField="program" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #filter="{filterModel}">
                    <CustomSelectOne label="Program" name="program" v-model="filterModel.value" :items="programs"
                                     filter itemLabel="" class="flex-1 w-0 mt-3" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
            </Column>
            <Column header="Sezon" field="season" class="w-4 header-border"
                    filterField="season" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #filter="{filterModel}">
                    <CustomSelectOne label="Sezon" name="season" v-model="filterModel.value" :items="seasons"
                                     filter itemLabel="" class="flex-1 w-0 mt-3" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
            </Column>
            <Column header="Ogólny postęp" field="yearProgress" class="w-4 header-border">
                <template #body="slotProps">
                    <div class="flex gap-4 align-items-center">
                        <ProgressBar :progressPercentage="slotProps.data.yearProgress" class="w-6" />
                        <span class="w-4">{{ slotProps.data.yearProgress }}%</span>
                    </div>
                </template>
            </Column>
            <Column expander class="w-1" />
            <template #expansion="slotProps">
                <ProgramsListInternalTable :program="slotProps.data" class="programs-table-internal"/>
            </template>
        </DataTable>
    </div>
</template>

<script>
    import { ref } from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import ProgressBar from "@/components/ProgressBar.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import ProgramsListInternalTable
        from "@/views/programsList/components/ProgramsListInternalTable.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import FilterApply from "@/components/filters/FilterApply.vue";
    import FilterClear from "@/components/filters/FilterClear.vue";
    import {searchProgramEditions, searchProgramEditionsCount} from "@/swagger/vue-api-client";

    export default {
        name: "ProgramsListTable",

        components: {
            FilterClear,
            FilterApply,
            CustomSelectOne,
            ProgramsListInternalTable,
            DataTable,
            Column,
            ProgressBar,
        },

        data() {
            return {
                programEditions: [],
                searchCriteria: {
                    program: "",
                    season: "",
                },
                page: SearchCriteria.getClearPage(),
                totalRecords: null,
                expandedRows: ref([]),
                loading: false,
                loadedFilters: false,
                filters: [],
                seasons: ["lęgowy", "zimowy"],
                programs: [
                    {
                        id: 1,
                        program: "Monitoring Zimujących Ptaków Morskich",
                        season: "lęgowy",
                        yearProgress: 60,
                        details: [
                            {
                                id: 1,
                                control: "Kontrola wczesnowiosenna",
                                field: "TEST",
                                year: 2024,
                                status: "ACCEPTED",
                                formProgress: 100,
                                startTime: "10.03.2024",
                                endTime: "20.04.2024",
                            },
                            {
                                id: 2,
                                control: "Kontrola późnowiosenna",
                                field: "TEST",
                                year: 2024,
                                status: "IN_PROGRESS",
                                formProgress: 40,
                                startTime: "20.04.2024",
                                endTime: "20.05.2024",
                            },
                            {
                                id: 3,
                                control: "Kontrola wczesnowiosenna",
                                field: "TEST2",
                                year: 2023,
                                status: "ACCEPTED",
                                formProgress: 100,
                                startTime: "10.03.2023",
                                endTime: "20.04.2023",
                            },
                            {
                                id: 4,
                                control: "Kontrola późnowiosenna",
                                field: "TEST2",
                                year: 2023,
                                status: "IN_PROGRESS",
                                formProgress: 40,
                                startTime: "20.04.2023",
                                endTime: "20.05.2023",
                            },
                            {
                                id: 3,
                                control: "Kontrola wczesnowiosenna",
                                field: "TEST",
                                year: 2022,
                                status: "ACCEPTED",
                                formProgress: 100,
                                startTime: "10.03.2022",
                                endTime: "20.04.2022",
                            },
                            {
                                id: 4,
                                control: "Kontrola późnowiosenna",
                                field: "TEST",
                                year: 2022,
                                status: "IN_PROGRESS",
                                formProgress: 40,
                                startTime: "20.04.2022",
                                endTime: "20.05.2022",
                            },
                            {
                                id: 3,
                                control: "Kontrola wczesnowiosenna",
                                field: "TEST3",
                                year: 2021,
                                status: "ACCEPTED",
                                formProgress: 100,
                                startTime: "10.03.2021",
                                endTime: "20.04.2021",
                            },
                            {
                                id: 4,
                                control: "Kontrola późnowiosenna",
                                field: "TEST3",
                                year: 2021,
                                status: "IN_PROGRESS",
                                formProgress: 40,
                                startTime: "20.04.2021",
                                endTime: "20.05.2021",
                            },
                            {
                                id: 3,
                                control: "Kontrola wczesnowiosenna",
                                field: "TEST",
                                year: 2020,
                                status: "ACCEPTED",
                                formProgress: 100,
                                startTime: "10.03.2020",
                                endTime: "20.04.2020",
                            },
                            {
                                id: 4,
                                control: "Kontrola późnowiosenna",
                                field: "TEST",
                                year: 2020,
                                status: "IN_PROGRESS",
                                formProgress: 40,
                                startTime: "20.04.2020",
                                endTime: "20.05.2020",
                            },
                            {
                                id: 3,
                                control: "Kontrola wczesnowiosenna",
                                field: "TEST",
                                year: 2019,
                                status: "ACCEPTED",
                                formProgress: 100,
                                startTime: "10.03.2019",
                                endTime: "20.04.2019",
                            },
                            {
                                id: 4,
                                control: "Kontrola późnowiosenna",
                                field: "TEST",
                                year: 2019,
                                status: "IN_PROGRESS",
                                formProgress: 40,
                                startTime: "20.04.2019",
                                endTime: "20.05.2019",
                            },
                            {
                                id: 3,
                                control: "Kontrola wczesnowiosenna",
                                field: "TEST",
                                year: 2018,
                                status: "ACCEPTED",
                                formProgress: 100,
                                startTime: "10.03.2018",
                                endTime: "20.04.2018",
                            },
                            {
                                id: 4,
                                control: "Kontrola późnowiosenna",
                                field: "TEST",
                                year: 2018,
                                status: "IN_PROGRESS",
                                formProgress: 40,
                                startTime: "20.04.2018",
                                endTime: "20.05.2018",
                            },
                            {
                                id: 3,
                                control: "Kontrola wczesnowiosenna",
                                field: "TEST",
                                year: 2017,
                                status: "ACCEPTED",
                                formProgress: 100,
                                startTime: "10.03.2017",
                                endTime: "20.04.2017",
                            },
                            {
                                id: 4,
                                control: "Kontrola późnowiosenna",
                                field: "TEST",
                                year: 2017,
                                status: "IN_PROGRESS",
                                formProgress: 40,
                                startTime: "20.04.2017",
                                endTime: "20.05.2017",
                            },
                        ],
                    },
                    {
                        id: 2,
                        program: "Monitoring Zimujących Ptaków Morskich",
                        season: "zimowy",
                        programType: "POSITION",
                        yearProgress: 20,
                        details: [
                            {
                                id: 1,
                                control: "Kontrola wczesnowiosenna",
                                field: "TEST",
                                year: 2024,
                                status: "ACCEPTED",
                                formProgress: 48,
                                startTime: "10.03.2024",
                                endTime: "20.04.2024",
                                positions: ["SN01", "SN04"],
                            },
                            {
                                id: 2,
                                control: "Kontrola późnowiosenna",
                                field: "TEST",
                                year: 2024,
                                status: "IN_PROGRESS",
                                formProgress: 0,
                                startTime: "20.04.2024",
                                endTime: "20.05.2024",
                                positions: ["LK21", "LK23", "SNP09", "SNT07", "SNT08"],
                            },
                            {
                                id: 3,
                                control: "Kontrola wczesnowiosenna",
                                field: "TEST",
                                year: 2023,
                                status: "ACCEPTED",
                                formProgress: 48,
                                startTime: "10.03.2023",
                                endTime: "20.04.2023",
                                positions: ["SN01"],
                            },
                            {
                                id: 4,
                                control: "Kontrola późnowiosenna",
                                field: "TEST",
                                year: 2023,
                                status: "IN_PROGRESS",
                                formProgress: 0,
                                startTime: "20.04.2023",
                                endTime: "20.05.2023",
                                positions: ["SN01"],
                            },
                        ],
                    },
                    {
                        id: 3,
                        program: "Monitoring Zimujących Ptaków Morskich",
                        season: "lęgowy",
                        yearProgress: 100,
                        details: [
                            {
                                id: 1,
                                control: "Kontrola wczesnowiosenna",
                                field: "TEST",
                                year: 2024,
                                status: "ACCEPTED",
                                formProgress: 100,
                                startTime: "10.03.2024",
                                endTime: "20.04.2024",
                            },
                            {
                                id: 2,
                                control: "Kontrola późnowiosenna",
                                field: "TEST",
                                year: 2024,
                                status: "IN_PROGRESS",
                                formProgress: 100,
                                startTime: "20.04.2024",
                                endTime: "20.05.2024",
                            },
                        ],
                    },
                    {
                        id: 4,
                        program: "Monitoring Zimujących Ptaków Morskich",
                        season: "zimowy",
                        yearProgress: 70,
                        details: [
                            {
                                id: 1,
                                control: "Kontrola wczesnowiosenna",
                                field: "TEST",
                                year: 2024,
                                status: "ACCEPTED",
                                formProgress: 55,
                                startTime: "10.03.2024",
                                endTime: "20.04.2024",
                            },
                            {
                                id: 2,
                                control: "Kontrola późnowiosenna",
                                field: "TEST",
                                year: 2024,
                                status: "IN_PROGRESS",
                                formProgress: 30,
                                startTime: "20.04.2024",
                                endTime: "20.05.2024",
                            },
                        ],
                    },
                ],
            };
        },

        mounted() {
            // searchProgramEditionsFilters()
            //     .then((response) => {
            //         this.filters = response.data;
            //         this.loadedFilters = true;
            //     });
            // this.search();
        },

        beforeMount() {
            this.search();
        },

        methods: {
            expandRow(row) {
                const rows = document.querySelectorAll(".p-row-toggler");
                rows[row.index].click();
            },
            clear() {
                this.searchCriteria = SearchCriteria.getClearSearchCriteriaProgramEditions();
                this.search();
            },
            search() {
                if (this.loading) return;
                this.loading = true;
                setTimeout(() => {
                    console.log(this.searchCriteria, this.page, this.filterDescription);
                    const criteria = SearchCriteria.prepareCriteria(
                        this.searchCriteria,
                        this.page,
                        this.filterDescription,
                    );
                    console.log(criteria);
                    searchProgramEditions({body: criteria})
                        .then((response) => {
                            this.programEditions = response.data;
                            console.log(this.programEditions);
                            return searchProgramEditionsCount({body: criteria});
                        })
                        .then((response) => {
                            this.totalRecords = response.data;
                            this.loading = false;
                        });
                }, 100);
            },
            onPage(event) {
                this.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
                this.search();
            },
        },

        computed: {
            filterDescription() {
                return SearchCriteria.getSearchCriteriaProgramEditionFilterDescriptionDraft();
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../assets/theme/mytheme/variables";

    .programs-table-observer {
        .p-column-filter-menu-button {
            color: var(--primary-color-text);
        }
        .programs-table-internal .p-column-filter-menu-button {
            color: var(--text-color-secondary);
        }
        .p-column-title {
            font-size: 1rem;
            color: var(--surface-a);
        }
        .p-datatable .p-datatable-thead > tr > th {
            background-color: var(--secondary-color);
        }
        .p-datatable .p-datatable-tbody .p-datatable .p-datatable-thead > tr > th {
            background-color: rgba(5, 104, 57, 0.27);
            border-width: 0;
            .p-column-title {
                color: var(--color-black);
            }
            .p-datatable {
                border: 1px solid var(--surface-border);
            }
        }
        .p-datatable .p-datatable-tbody > tr:focus {
            outline: none;
        }
        .p-datatable .p-datatable-tbody > tr > td {
            background-color: var(--primary-color);
            color: var(--color-black);
            font-weight: normal;
            border: 1px solid var(--surface-border);
            border-top: none;
            border-left: none;
            &:first-child {
                border-left: 1px solid var(--surface-border);
            }
            &.header-border:nth-of-type(1) {
                border-right: none;
            }
            &.header-border:nth-of-type(2) {
                border-left: none;
                border-right: none;
            }
            &.header-border:nth-of-type(3) {
                border-left: none;
                border-right: none;
            }
            &.header-border:nth-of-type(4) {
                border-left: none;
            }
        }
        .p-datatable .p-datatable-tbody > tr > td.program-header {
            font-weight: bold;
        }
        .p-datatable .p-datatable-wrapper {
            & .p-datatable-row-expansion, .p-datatable-row-expansion td .p-datatable .p-datatable-wrapper {
                // border: 1px solid rgba(5, 104, 57, 0.27);
            }
        }
        a {
            display: block;
            color: var(--text-color);
            padding: 1rem;
        }
        .p-datatable-row-expansion, .p-datatable-row-expansion td {
            background-color: var(--surface-a) !important;
        }
        .p-datatable-tbody tr.p-datatable-row-expansion td, .p-datatable-tbody tr.p-datatable-row-expansion th {
            padding: 1rem 2.5rem;
        }
    }
    .accepted-style, .in-progress-style {
        padding: 2px 8px;
        width: fit-content;
        border-radius: 4px;
    }
    .accepted-style {
        color: var(--secondary-color);
        // background-color: $secondaryLightColor;
    }
    .in-progress-style {
        color: var(--red);
        // background-color: $colorLightRed;
    }
</style>
