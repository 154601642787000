<template>
    <div class="p-3">
        <AreaDictSearchCriteria v-model="searchCriteria" @update:modelValue="search" @unselect="unselect" />
        <AreaDictTable ref="areaDictTable" :columns="tableColumns" :searchCriteria="searchCriteria" />
    </div>
</template>

<script>
    import {} from "@/swagger/vue-api-client";
    import AreaDictTable from "./AreaDictTable.vue";
    import AreaDictSearchCriteria from "./AreaDictSearchCriteria.vue";
    import {FiltersUtils} from "@/utils/FiltersUtils";

    export default {
        name: "AreaDictView",

        components: {
            AreaDictTable,
            AreaDictSearchCriteria,
        },

        data() {
            return {
                tableColumns: [],
                columnName: null,
                columnYear: null,
                columnStatus: null,
                columnInfo: null,
                columnCode: null,
                columnSurface: null,
                columnSource: null,
                dictType: null,
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            if (FiltersUtils.loadFromSessionStorage(this.$route.path)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(this.$route.path);
            }
            // searchGeography({body: {type: "gminy", name: "Biała"}})
            //     .then((response) => {
            //         console.log(response.data);
            //     });
            this.dictType = this.$route.params.dict;
            this.assignColumnProps();
            /* eslint-disable */
            if (this.dictType === "gminy" || this.dictType === "powiaty" || this.dictType === "wojewodztwa"
                || this.dictType === "lesnictwa" || this.dictType === "nadlesnictwa" || this.dictType === "rdlp") {
                this.tableColumns = [this.columnName];
            } else if (this.dictType === "oso" || this.dictType === "soo") {
                this.tableColumns = [this.columnName, this.columnCode, this.columnSurface];
            } else if (this.dictType === "ochk") {
                this.tableColumns = [this.columnName, this.columnYear, this.columnStatus, this.columnInfo];
            } else if (this.dictType === "parkikrajobrazowe" || this.dictType === "parkinarodowe") {
                this.tableColumns = [this.columnName, this.columnSource];
            } else if (this.dictType === "rezerwaty") {
                this.tableColumns = [this.columnName, this.columnSource, this.columnSurface];
            } else {
                this.tableColumns = [];
            }
        },

        methods: {
            assignColumnProps() {
                this.columnName = {header: "Nazwa", field: "name", bodyClass: () => "", bodyContent: (data) => data.name};
                this.columnYear = {header: "Rok", field: "year", bodyClass: () => "", bodyContent: (data) => data.year};
                this.columnStatus = {header: "Status", field: "status", bodyClass: () => "", bodyContent: (data) => data.status};
                this.columnInfo = {header: "Informacje", field: "information", bodyClass: () => "", bodyContent: (data) => data.information};
                this.columnCode = {header: "Kod", field: "code", bodyClass: () => "", bodyContent: (data) => data.code};
                this.columnSurface = {header: "Powierzchnia [ha]", field: "surface", bodyClass: () => "", bodyContent: (data) => data.surface};
                this.columnSource = {header: "Źródło", field: "source", bodyClass: () => "", bodyContent: (data) => data.source};
            },

            getClearSearchCriteria() {
                return {
                    type: `${this.$route.params.dict}`,
                    name: "",
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },

            search() {
                this.$refs.areaDictTable.search();
            },

            unselect() {
                this.$refs.areaDictTable.unselect();
            },
        },
        /* eslint-enable */
        watch: {
            searchCriteria: {
                handler(value) {
                    FiltersUtils.saveToSessionStorage(this.$route.path, value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
