<template>
    <div class="p-3">
        <TransectSearchCriteria v-model="searchCriteria" @update:modelValue="search" />
        <TransectTable ref="transectTable" v-model="searchCriteria" />
    </div>
</template>

<script>
    import TransectSearchCriteria from "./TransectSearchCriteria.vue";
    import TransectTable from "./TransectTable.vue";
    import { SearchCriteria } from "@/utils/SearchCriteria";
    import {FiltersUtils} from "@/utils/FiltersUtils";

    export default {

        name: "TransectDictView",

        components: {
            TransectTable,
            TransectSearchCriteria,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchDataTransects(),
            };
        },

        beforeMount() {
            if (FiltersUtils.loadFromSessionStorage(this.$route.path)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(this.$route.path);
            }
        },

        methods: {

            getClearSearchDataTransects() {
                return SearchCriteria.getClearSearchDataTransects();
            },

            search() {
                this.$refs.transectTable.search();
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    FiltersUtils.saveToSessionStorage(this.$route.path, value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss"></style>
