<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="position" :geobjects="positions"
                              noAdditionalInformation>
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition header="Kolonie" :field="field" v-model="position" :positions="positions"
                                        v-model:step="internalStep"/>
        </template>
        <template v-slot:controlData>
            <div class="flex gap-6">
                <CustomSelectOne label="Typ roślinności" name="plantsType" :items="dicts.plantsType"
                                 v-model="position.plantsType" :disabled="!editing" required
                                 :showErrors="showErrorMessages" class="w-3 mb-0" />
                <CustomSelectOne label="Siedlisko" name="habitatType" :items="dicts.habitatType"
                                 v-model="position.habitatType" :disabled="!editing" required
                                 :showErrors="showErrorMessages" class="w-3 mb-0" />
            </div>
        </template>
        <template v-slot:birdsData>
            <FormBirdsMCH v-model:birds="position.birds" />
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import FormBirdsMCH from "./FormBirdsMCH.vue";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";

    export default {
        name: "FormMCHObservations",

        components: {
            FormObservationsTemplate,
            ObservationsSelectPosition,
            CustomSelectOne,
            FormBirdsMCH,
        },

        props: {
            allPositions: {
                type: Array,
                default: () => [],
            },
            field: {
                type: Array,
                default: () => [],
            },
            editable: {
                type: Boolean,
                default: false,
            },
            form: {
                type: Object,
                required: true,
            },
            step: {
                type: Number,
                required: true,
            },
        },

        emits: ["update:step"],

        inject: ["showErrorMessages", "dicts", "editing"],

        data() {
            return {
                positions: [],
                position: _.cloneDeep(EmptyStructures.getEmptyMCHPosition()),
                activeIndex: 0,
                controls: [],
            };
        },

        beforeMount() {
            this.positions = this.allPositions.filter((p) => (p.notAudited === null || !p.notAudited));
            this.controls[0] = this.form.control1;
            this.controls[1] = this.form.control2;
            this.controls[2] = this.form.control3;
            window.scrollTo({ top: 0, behavior: "smooth" });
        },

        computed: {
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            position: {
                handler(value) {
                    if (!value.birds) {
                        value.birds = [];
                    }
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../../assets/theme/mytheme/variables";
    .colorRedBG {
        .p-accordion-header-link.p-accordion-header-action {
            background-color: $color-red !important;
            color: var(--surface-a) !important;
        }
    }
</style>
