<template>
    <div class="custom-select-one field relative">
        <span class="p-float-label" :style="computedWidth()">
            <Dropdown :id="name" :options="internalItems" :optionLabel="itemLabel"
                      :optionValue="itemValue" :disabled="disabled"
                      @change="onChange" :rules="rules" :filter="filter"
                      v-model="internalValue" :placeholder="internalValue ? '' : label"
                      :class="computedClasses" :showClear="showClear" :optionDisabled="optionDisabled"
                      :virtual-scroller-options="scroll" @show="offTabindexOnFilter" :autoFilterFocus="filter"
                      :panelClass="computedPanelClass()" @focus="setWidth">
                <template #option="slotProps" v-if="tooltip">
                    <div @mouseenter="showDescription(slotProps.index)" @mouseout="hideDescription"
                         @focus="showDescription(slotProps.index)" @blur="hideDescription" @click="hideDescription">
                        {{ slotProps.option.code }}
                    </div>
                </template>
                <template #footer>
                    <div v-show="showCodeDescription && codeDescription !== '' && codeDescription !== null"
                         class="code-description-tooltip">
                        {{ codeDescription }}
                    </div>
                </template>
            </Dropdown>
            <CustomLabel :name="name" :label="label" :required="required" :disabled="disabled"
                         :class="{'label-filled': internalValue != null && internalValue !== ''}" />
        </span>
        <CustomValidationMessage :errorMessage="errorMessage" />
        <CustomValidationMessage :errorMessage="customErrorMessage" />
        <CustomValidationMessage v-if="showRequiredError" :errorMessage="$t('message.validations.required')" />
        <!-- <div v-show="showCodeDescription && codeDescription !== '' && codeDescription !== null"
             class="absolute code-description-tooltip">
            {{ codeDescription }}
        </div> -->
    </div>
</template>

<script>
    import Dropdown from "primevue/dropdown";
    import {useField} from "vee-validate";
    import CustomValidationMessage from "./CustomValidationMessage";
    import CustomLabel from "./CustomLabel";

    export default {
        name: "CustomSelectOne",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            itemLabel: {
                default: "label",
            },
            itemValue: {
                type: String,
                default: "",
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            emptyValue: {
                type: Boolean,
                default: false,
            },
            items: {
                type: Array,
            },
            modelValue: null,
            rules: undefined,
            filter: {
                type: Boolean,
                default: true,
            },
            customErrorMessage: {
                type: String,
                default: "",
            },
            customErrorWithoutMessage: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
            showErrors: {
                type: Boolean,
                default: false,
            },
            tooltip: {
                type: Boolean,
                default: false,
            },
            showClear: {
                type: Boolean,
                default: true,
            },
            short: {
                type: Boolean,
                default: false,
            },
            width: {
                type: String,
                default: null,
            },
            optionDisabled: {
                type: String,
                default: "disabled",
            },
            virtualScrolling: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["update:modelValue", "change"],

        data() {
            return {
                internalItems: this.items,
                selectedValue: null,
                showCodeDescription: false,
                codeDescription: "",
            };
        },

        mounted() {
            if (this.emptyValue) {
                this.internalItems = [{value: null, label: this.$t("message.other.emptyValue")}, ...this.items];
            }
            if (this.items.length === 0) {
                this.internalItems = [this.modelValue];
            }
        },

        watch: {
            items(newVal) {
                this.internalItems = [...newVal];
            },
        },

        computed: {
            internalValue: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            computedClasses() {
                if (this.customErrorMessage !== "" || this.customErrorWithoutMessage || this.showRequiredError) {
                    return "p-invalid";
                }
                return "";
            },
            scroll() {
                return this.virtualScrolling ? { itemSize: 36 } : undefined;
            },
            showRequiredError() {
                return this.showErrors && (this.internalValue == null || this.internalValue === "");
            },
        },

        methods: {
            setWidth() {
                if (this.short) {
                    const width = document.activeElement.offsetWidth;
                    document.activeElement.style.width = `${width}px`;
                }
            },
            offTabindexOnFilter() {
                const filter = document.querySelector(".p-dropdown-filter");
                if (filter) {
                    filter.setAttribute("tabindex", "-1");
                }
            },
            onChange(val) {
                if (val && val.value) {
                    this.handleChange(val.value);
                    this.$emit("change", val.value);
                } else {
                    this.handleChange(undefined);
                    this.$emit("change", undefined);
                }
            },
            showDescription(id) {
                const tooltip = document.querySelector(".code-description-tooltip");
                window.addEventListener("mousemove", (e) => {
                    const x = e.clientX;
                    const y = e.clientY;
                    tooltip.style.top = (y + 20) + "px";
                    tooltip.style.left = (x + 20) + "px";
                });
                this.showCodeDescription = true;
                this.computedCodeDescription(id);
            },
            hideDescription() {
                this.codeDescription = "";
                this.showCodeDescription = false;
            },
            computedCodeDescription(id) {
                this.codeDescription = this.items[id]?.label;
            },
            computedWidth() {
                if (this.width === null) return "";
                return "width: " + this.width;
            },
            computedPanelClass() {
                let panelClass = "";
                if (this.short) panelClass += "short-panel ";
                if (this.tooltip) panelClass += "tooltip-panel ";
                return panelClass;
            },
        },

        components: {Dropdown, CustomValidationMessage, CustomLabel},

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

    };
</script>

<style lang="scss" scoped>
    @import "../../../assets/theme/mytheme/variables";
    .custom-select-one {
        .p-dropdown {
            width: 100%;
        }
        .p-dropdown-trigger {
            border-left: 1px solid #ced4da;
        }
    }
    .code-description-tooltip {
        z-index: 999999;
        // background-color: var(--surface-0);
        color: black;
        // top: 0;
        // left: calc(100% + 4px);
        width: 100%;
        max-width: 300px;
        padding: 4px 8px;
        border-radius: 4px;
    }
</style>
<style lang="scss">
    .custom-select-one {
        .p-dropdown-trigger {
            border-left: 1px solid #ced4da;
        }
        .p-dropdown .p-dropdown-label.p-placeholder {
            color: transparent;
        }
        .p-dropdown .p-dropdown-label {
            position: relative;
            top: 2px;
        }
    }
    .short-panel.p-dropdown-panel {
        max-width: 500px;
        .p-dropdown-items .p-dropdown-item {
            text-align: left;
            padding: 10px 1rem;
            white-space: pre-wrap;
        }
    }
    .tooltip-panel {
        .p-dropdown-item {
            padding: 0 !important;
            div {
                padding: 10px 1rem;
            }
        }
    }
</style>
