<template>
    <div v-if="loaded" class="flex flex-column">
        <ProgramDetails :form="formZero" transect />
        <div class="mt-5 flex align-items-center justify-content-between">
            <CustomSelectOne label="Liczba odcinków" :items="allNumberOfTransects" :showClear="false"
                             v-model="numberOfTransects" class="w-2 mb-0"
                             name="numberOfTransects" itemLabel=""/>
            <div class="flex gap-2">
                <CustomIconButton icon="pi pi-chevron-left" label="Poprzedni" rowReverse @click="goPrevious" />
                <CustomIconButton icon="pi pi-chevron-right" label="Następny" @click="goNext" />
            </div>
        </div>
        <div class="flex">
            <SelectTransectLine v-for="(segments,index) in segmentsInTransects" :key="index"
                                :transects="segments" :transectNumber="index + 1"
                                @onClicked="(c) => onClickedOrHover(c,index,'clicked')"
                                @onHover="(h) => onClickedOrHover(h,index,'hover')" class="w-6" />
        </div>
        <div class="flex mb-4 gap-8">
            <div style="height: 600px;" class="w-7">
                <MapMultiTransectsFormZero mapId="transectsMap" height="600px" :field="field" popup
                                           :transects="coloredTransects" @onClick="onClick" @onHover="onHover"
                                           v-model:transect="transect" :isOn="false" editable>
                    <template v-slot:popup="slotProps">
                        <div class="marker-popup-header">
                            {{ slotProps.transect.name }}
                        </div>
                    </template>
                </MapMultiTransectsFormZero>
            </div>
            <div class="w-5">
                <div class="mb-4">Kolejne punkty wyznaczające odcinek:</div>
                <div v-if="transect" class="transect-points-list">
                    <div v-for="(line,index) in editableLineArray" :key="index"
                         class="flex gap-3 align-items-center">
                        <div class="mb-3 w-1">{{index + 1}}.</div>
                        <CustomInputNumber label="Koordynaty N" v-model="line.latitude"
                                           @update:modelValue="(v) => updateTransect(v,index,'latitude')"
                                           :maxDigits="6" name="latitude" :showLabel="true"
                                           :showErrors="showErrorMessages" required />
                        <CustomInputNumber label="Koordynaty E" v-model="line.longitude"
                                           @update:modelValue="(v) => updateTransect(v,index,'longitude')"
                                           :maxDigits="6" name="longitude" :showLabel="true"
                                           :showErrors="showErrorMessages" required />
                        <div class="flex flex-column mb-3 pr-0 align-items-center">
                            <CustomIconButton icon="pi pi-chevron-up" small @click="moveBefore(index)" />
                            <CustomIconButton icon="pi pi-chevron-down" small @click="moveAfter(index)" />
                        </div>
                        <div class="flex flex-column mb-3 pl-0 align-items-center">
                            <CustomIconButton icon="pi pi-times" small @click="remove(index)" />
                        </div>
                    </div>
                </div>
                <div v-if="transect" class="mt-4">
                    <CustomButton label="Dodaj punkt" class="justify-content-center" bgColor="#33795B"
                                  color="white" @click="add()" />
                </div>
                <div v-if="showErrorMessages && editableLineArray.length < 2 < 2" class="mt-3 error-info">
                    Transekt musi być wyznaczony przez co najmniej dwa punkty
                </div>
            </div>
        </div>
        <div class="flex justify-content-end flex-wrap gap-3">
            <CustomButton v-if="canApprove" class="form-button approve-button" label="Zatwierdź"
                          bgColor="var(--secondary-color)" color="var(--surface-a)" @click="approve" />
            <CustomButton v-if="canReject" class="form-button reject-button" label="Odrzuć"
                          bgColor="rgba(193, 197, 204, 0.4)" color="#EA3030" />
            <CustomButton v-if="canSendToVerification" class="form-button send-to-verification-button w-auto"
                          label="Wyślij do weryfikacji" bgColor="rgba(193, 197, 204, 0.4)" />
            <CustomButton v-if="canSave" class="form-button save-button" label="Zapisz"
                          bgColor="rgba(193, 197, 204, 0.4)" @click="save" />
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import {computed} from "vue";
    import MapMultiTransectsFormZero from "../../components/map/MapMultiTransectsFormZero.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomIconButton from "@/components/form/CustomIconButton.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import ProgramDetails from "@/components/formSharedComponents/ProgramDetails.vue";
    import SelectTransectLine from "@/components/transectForm/selectTransect/SelectTransectLine.vue";
    import {SystemRole} from "@/utils/SystemRole";
    import {
        getTransectZero,
        editTransectZero,
        approveFormTransectZero,
    } from "@/swagger/vue-api-client";

    export default {
        name: "FormTransectZeroView",

        components: {
            CustomButton,
            CustomInputNumber,
            CustomIconButton,
            ProgramDetails,
            SelectTransectLine,
            MapMultiTransectsFormZero,
            CustomSelectOne,
        },

        data() {
            return {
                loaded: false,
                editing: true,
                showErrorMessages: false,
                formZero: {},
                editableLineArray: [],
                field: [],
                deleteCircle: null,
                newTransect: {
                    line: [],
                },
                transects: [],
                transect: null,
                activeIndex: null,
                previousHover: null,
                previousClicked: null,
                color: "grey",
                colorDisabled: "lightGrey",
                colorClickedOrHover: "red",
                coloredTransects: [],
                hoverIndex: null,
                clickedIndex: null,
                numberOfTransects: null,
                allNumberOfTransects: [],
            };
        },

        provide() {
            return {
                programCode: computed(() => this.formZero.programName),
            };
        },

        beforeMount() {
            getTransectZero({formId: this.$route.params.id})
                .then((response) => {
                    this.formZero = response.data;
                    this.numberOfTransects = this.formZero.suggestedNumberOfTransects;
                    for (let i = 0; i < this.formZero.suggestedNumberOfTransects; i += 1) {
                        this.allNumberOfTransects.push(i + 1);
                    }
                    this.field = this.formZero.field.shape;
                    this.loaded = true;
                })
                .catch((err) => {
                    console.log(err);
                    this.loaded = false;
                });
            this.previousHover = this.hoverIndex;
            this.previousClicked = this.clickedIndex;
        },

        methods: {
            makeColoredTransects() {
                this.transects = this.formZero.formTransectZeros;
                this.coloredTransects = _.cloneDeep(this.transects);
                this.coloredTransects.forEach((t, i) => {
                    if (t.notAudited === true) {
                        t.color = this.colorDisabled;
                        t.openPopup = false;
                        t.zIndexOffset = -1;
                    } else if (i === this.clickedIndex || i === this.hoverIndex) {
                        t.color = this.colorClickedOrHover;
                        t.openPopup = true;
                        t.zIndexOffset = 1000;
                    } else {
                        t.color = this.color;
                        t.openPopup = false;
                        t.zIndexOffset = 1;
                    }
                });
            },
            moveBefore(index) {
                if (index > 0) {
                    const segment = this.transect.line[index];
                    this.transect.line.splice(index, 1);
                    this.transect.line.splice(index - 1, 0, segment);
                }
            },
            moveAfter(index) {
                if (index < this.transect.line.length - 1) {
                    const segment = this.transect.line[index];
                    this.transect.line.splice(index, 1);
                    this.transect.line.splice(index + 1, 0, segment);
                }
            },
            remove(index) {
                this.deleteCircle = [this.transect.line[index], index];
                this.editableLineArray.splice(index, 1);
                this.transect.line.splice(index, 1);
            },
            add() {
                if (this.transect.line.length === 0
                    && this.formZero.formTransectZeros[this.activeIndex - 1]?.line.length > 0) {
                    const lastPoint = _.cloneDeep(
                        this.formZero.formTransectZeros[this.activeIndex - 1].line[
                            this.formZero.formTransectZeros[this.activeIndex - 1].line.length - 1
                        ],
                    );
                    this.transect.line.push(lastPoint);
                } else if (this.transect.line.length > 0
                    && this.transect.line[0].latitude != null
                    && this.transect.line[0].longitude != null) {
                    const lastPoint = _.cloneDeep(this.transect.line[this.editableLineArray.length - 1]);
                    lastPoint.latitude += 0.001;
                    lastPoint.longitude += 0.001;
                    this.transect.line.push(lastPoint);
                } else if (this.field.length > 0) {
                    this.transect.line = [];
                    const pointFromField = this.field[0];
                    this.transect.line.push(_.cloneDeep(pointFromField));
                } else {
                    this.transect.line = [];
                    this.transect.line.push(this.polandCenter);
                }
            },
            getRequiredMessage(value) {
                if (this.showErrorMessages
                    && (value == null || value === "")) return this.$t("message.validations.required");
                return "";
            },
            updateTransect(value, index, type) {
                if (this.transect.line[index] == null) {
                    this.transect.line[index] = {longitude: null, latitude: null};
                }
                if (value != null && value !== "" && value !== 0) {
                    if (type === "longitude") {
                        this.transect.line[index].longitude = value;
                    }
                    if (type === "latitude") {
                        this.transect.line[index].latitude = value;
                    }
                }
            },
            validate() {
                let isCorrect = true;
                this.editableLineArray.forEach((l) => {
                    if (l.latitude === null || l.longitude === null) isCorrect = false;
                });
                if (this.showYear && this.transect.year === null) isCorrect = false;
                if (this.isNewHistoryTransect && this.editableLineArray.length < 2) isCorrect = false;
                return isCorrect;
            },
            goNext() {
                if (this.clickedIndex < 9) {
                    this.clickedIndex += 1;
                    this.updateClickedOrHover(this.clickedIndex, "clicked");
                }
            },
            goPrevious() {
                if (this.clickedIndex > 0) {
                    this.clickedIndex -= 1;
                    this.updateClickedOrHover(this.clickedIndex, "clicked");
                }
            },
            onClickedOrHover(segmentIndex, transectIndex, actionField) {
                // actionField is "clicked" or "hover"
                let counter = 0;
                this.segmentsInTransects.forEach((segments, ti) => {
                    if (segmentIndex != null && ti === transectIndex) {
                        counter += segmentIndex;
                        if (actionField === "clicked") this.clickedIndex = counter;
                        else if (actionField === "hover") this.hoverIndex = counter;
                        segments.forEach((s, si) => { s[actionField] = (si === segmentIndex); });
                    } else {
                        counter += segments.length;
                        segments.forEach((s) => { s[actionField] = false; });
                    }
                });
                if (segmentIndex == null) {
                    if (actionField === "clicked") this.clickedIndex = null;
                    else if (actionField === "hover") this.hoverIndex = null;
                }
            },
            updateClickedOrHover(index, actionField) {
                const counter = index;
                this.segmentsInTransects.forEach((segments, si) => {
                    if (index == null || counter >= segments.length) {
                        segments.forEach((s) => { s[actionField] = false; });
                        const secondCounter = counter - segments.length;
                        if (si === 1) {
                            segments.forEach((s, i) => { s[actionField] = (i === secondCounter); });
                        }
                    } else if (index == null || counter < segments.length) {
                        segments.forEach((s) => { s[actionField] = false; });
                        if (si === 0) {
                            segments.forEach((s, i) => { s[actionField] = (i === counter); });
                        }
                    }
                });
            },
            onClick(index) {
                if (index == null || !(this.transects[index].notAudited === true)) {
                    this.clickedIndex = index;
                    this.updateClickedOrHover(index, "clicked");
                }
            },
            onHover(index) {
                if (index == null || !(this.transects[index].notAudited === true)) {
                    this.hoverIndex = index;
                    this.updateClickedOrHover(index, "hover");
                }
            },
            markAsHoverOrClicked(index) {
                if (this.coloredTransects[index]) {
                    this.coloredTransects[index].color = this.colorClickedOrHover;
                    this.coloredTransects[index].openPopup = true;
                    this.coloredTransects[index].zIndexOffset = 1000;
                }
            },
            markAsNormal(index) {
                if (this.coloredTransects[index]) {
                    this.coloredTransects[index].color = this.color;
                    this.coloredTransects[index].openPopup = false;
                    this.coloredTransects[index].zIndexOffset = 1;
                }
            },
            save(withApproval = false) {
                const request = [];
                this.formZero.formTransectZeros.forEach((t) => {
                    request.push({
                        name: t.name,
                        line: t.line,
                    });
                });
                editTransectZero({formId: this.$route.params.id, body: request})
                    .then(() => {
                        if (withApproval) {
                            approveFormTransectZero({formId: this.$route.params.id})
                                .catch((err) => {
                                    console.log(err);
                                });
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
            },
            approve() {
                this.save(true);
            },
        },

        computed: {
            segmentsInTransects() {
                const first5 = this.transects.filter((t, i) => i <= 4);
                const second5 = this.transects.filter((t, i) => i > 4 && i <= 9);
                if (second5.length > 0) return [first5, second5];
                return [first5];
            },
            isCoordinator() {
                return SystemRole.isMainCoordinator() || SystemRole.isRegionalCoordinator()
                    || SystemRole.isNationalCoordinator();
            },
            canApprove() {
                return this.isCoordinator && (this.editing || this.formZero.state === "SENT_TO_VERIFICATION");
            },
            canReject() {
                return this.isCoordinator && this.formZero.state === "SENT_TO_VERIFICATION";
            },
            canSendToVerification() {
                return SystemRole.isObserver() && this.formZero.state === "DRAFT";
            },
            canSave() {
                return this.editing && this.formZero.state !== "APPROVED";
            },
        },

        watch: {
            hoverIndex(value) {
                if (this.previousHover !== null && this.previousHover !== this.clickedIndex) {
                    this.markAsNormal(this.previousHover);
                }
                if (value !== null) {
                    this.markAsHoverOrClicked(value);
                    this.previousHover = value;
                }
            },
            clickedIndex(value) {
                if (this.previousClicked !== null && this.previousClicked !== this.hoverIndex) {
                    this.markAsNormal(this.previousClicked);
                }
                if (value !== null) {
                    this.markAsHoverOrClicked(value);
                    this.previousClicked = value;
                }
                this.activeIndex = value;
            },
            activeIndex(value) {
                this.transect = this.formZero.formTransectZeros[value];
            },
            transect: {
                handler(value) {
                    this.editableLineArray = [];
                    if (value) {
                        value.line.forEach((l, i) => {
                            if (this.editableLineArray.length - 1 < i) {
                                this.editableLineArray.push({...l});
                            } else {
                                const editableElem = this.editableLineArray[i];
                                if (l.latitude !== editableElem.latitude) editableElem.latitude = l.latitude;
                                if (l.longitude !== editableElem.longitude) editableElem.longitude = l.longitude;
                            }
                        });
                        this.makeColoredTransects();
                    }
                },
                deep: true,
            },
            numberOfTransects(value, oldValue) {
                this.clickedIndex = 0;
                const length = this.formZero.formTransectZeros.length;
                if (value < oldValue) {
                    this.formZero?.formTransectZeros?.splice(value, oldValue - value);
                } else if (value > oldValue) {
                    for (let i = 0; i < value - oldValue; i += 1) {
                        if (length < value) {
                            /* eslint-disable */
                            this.formZero.formTransectZeros.push({
                                id: this.formZero.formTransectZeros[length - 1]?.id ? this.formZero.formTransectZeros[length - 1]?.id : i + 1,
                                name: `${this.formZero.formTransectZeros[length - 1]?.name ? this.formZero.formTransectZeros[length - 1]?.name : i + 1}`,
                                line: [],
                            });
                            /* eslint-enable */
                        }
                    }
                } else {
                    for (let i = 0; i < value; i += 1) {
                        if (length < value) {
                            /* eslint-disable */
                            this.formZero.formTransectZeros.push({
                                id: this.formZero.formTransectZeros[length - 1]?.id ? this.formZero.formTransectZeros[length - 1]?.id : i + 1,
                                name: `${this.formZero.formTransectZeros[length - 1]?.name ? this.formZero.formTransectZeros[length - 1]?.name : i + 1}`,
                                line: [],
                            });
                            /* eslint-enable */
                        }
                    }
                }
                this.makeColoredTransects();
                this.onClickedOrHover(0, 0, "clicked");
            },
        },
    };
</script>

<style lang="scss">
    @import "../../assets/theme/mytheme/variables";
    .transect-points-list {
        max-height: 500px;
        overflow-y: auto;
    }
</style>
