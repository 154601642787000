<template>
    <div class="p-3">
        <ImportTrendsDialog v-model="showImportTrendsDialog" />
        <div class="flex gap-2 justify-content-end mb-2">
            <CustomButton label="Importuj" @click="importTrends" />
            <CustomButton label="Eksportuj" @click="exportTrends" />
        </div>
        <TrendsTable ref="trendsTable" />
    </div>
</template>

<script>
    import TrendsTable from "./TrendsTable.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {FileUtils} from "@/utils/FileUtils";
    import ImportTrendsDialog from "./ImportTrendsDialog.vue";

    export default {
        name: "TrendsView",

        components: {
            TrendsTable,
            CustomButton,
            ImportTrendsDialog,
        },

        data() {
            return {
                searchCriteria: SearchCriteria.getClearSearchCriteriaTrends(),
                page: SearchCriteria.getClearPage(),
                showImportTrendsDialog: false,
            };
        },

        methods: {
            exportTrends() {
                const criteria = SearchCriteria.prepareCriteria(
                    this.searchCriteria,
                    this.page,
                    this.filterDescription,
                );
                FileUtils.downloadFile(criteria, "trendy.xlsx", "/trendData/search/export");
            },

            importTrends() {
                this.showImportTrendsDialog = true;
            },
        },

        computed: {
            filterDescription() {
                return SearchCriteria.getSearchCriteriaTrendsFilterDescription();
            },
        },
    };
</script>

<style lang="scss">
</style>
