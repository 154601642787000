<template>
    <div class="layout-topbar px-6 flex justify-content-between align-items-center">
        <div class="flex">
            <button class="p-link layout-topbar-button" @click="onMenuToggle" type="button">
                <span class="pi pi-bars"></span>
            </button>
            <div class="layout-topbar-description">
                Baza danych Monitoringu Ptaków Polski
            </div>
        </div>
        <div class="layout-topbar-icons">
            <div class="flex align-items-center gap-4">
                <AccTools/>
                <div class="user-info flex align-items-center h-full">
                    <div class="user-role">
                        <!-- {{ userRole }} -->
                        <CustomSelectOne itemValue="value" v-model="userRole" :items="userRoles" class="w-15rem mb-0"
                                         name="changeRole" :showClear="false"
                                         :filter="false" />
                    </div>
                    <!-- <CustomSelectLanguage class="flex-order-1 sm:flex-order-0"/> -->
                    <div class="user-name mr-auto sm:mr-4 ml-3 sm:ml-0 flex-order-0 sm:flex-order-1 border-x-2 px-4">
                        {{ name }}
                    </div>
                    <div class="logout flex-order-2 sm:flex-order-2" @click="logout" @keydown="logout">
                        <i class="pi pi-sign-out" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    // import CustomSelectLanguage from "@/components/form/CustomSelectLanguage";
    import axios from "axios";
    import CustomSelectOne from "../components/form/inner/CustomSelectOne";
    import AccTools from "@/components/AccTools";

    export default {

        components: {
            // CustomSelectLanguage,
            CustomSelectOne,
            AccTools,
        },

        emits: ["menu-toggle"],

        data() {
            return {
                name: localStorage.getItem("name"),
                userRole: localStorage.getItem("role"),
                systemRoles: [
                    {value: "OBSERVER", label: "Obserwator"},
                    {value: "REGIONAL_COORDINATOR", label: "Koordynator regionalny"},
                    {value: "NATIONAL_COORDINATOR", label: "Koordynator krajowy"},
                    {value: "MAIN_COORDINATOR", label: "Koordynator główny"},
                    {value: "ADMIN", label: "Administrator"},
                    {value: "PBD_MPP_ADMINISTRATOR", label: "Administrator PBD MPP"},
                ],
                loading: false,
                userRoles: [],
            };
        },

        beforeMount() {
            // this.userRole = localStorage.getItem("role");
            this.filterUserRoles();
        },

        methods: {
            onMenuToggle(event) {
                this.$emit("menu-toggle", event);
            },
            logout() {
                localStorage.removeItem("token");
                localStorage.removeItem("role");
                localStorage.removeItem("name");
                this.$router.push({name: "login"});
            },
            changeUserRole(role) {
                axios.post(process.env.VUE_APP_API_URL + "/role/" + role, {}, { withCredentials: true })
                    .then(() => {
                        // localStorage.setItem("token", response.data);
                        localStorage.setItem("role", role);
                        this.$router.go(0);
                    });
            },
            filterUserRoles() {
                const arr = localStorage.getItem("roles").split(",");
                for (let i = 0; i < this.systemRoles.length; i += 1) {
                    for (let j = 0; j < arr.length; j += 1) {
                        if (this.systemRoles[i].value === arr[j]) {
                            this.userRoles.push(this.systemRoles[i]);
                        }
                    }
                }
            },
        },

        watch: {
            userRole(value) {
                this.changeUserRole(value);
            },
        },

        // computed: {
        //     userRole() {
        //         if (localStorage.getItem("role") === "OBSERVER") return "Obserwator";
        //         if (localStorage.getItem("role") === "ADMIN") return "Administrator";
        //         if (localStorage.getItem("role") === "REGIONAL_COORDINATOR") return "Koordynator regionalny";
        //         if (localStorage.getItem("role") === "MAIN_COORDINATOR") return "Główny koordynator";
        //         return "";
        //     },
        // },
    };
</script>

<style lang="scss">
    @import "./assets/scss/app-top-bar";
</style>
