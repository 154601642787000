<template>
    <HabitatDataTemplate :habitatData="habitatData" v-model:habitatDataToChange="localHabitatDataToChange"
                         :emptyHabitatData="EmptyStructures.getEmptyMDUHabitatData()">
        <template v-slot:data="slotProps">
            <div class="flex justify-content-between flex-column">
                <div class="flex gap-3">
                    <CustomSelectOne label="Siedlisko" name="habitatType" :items="dicts.habitatType" class="w-6"
                                     v-model="slotProps.habitatData.habitatType"
                                     required :showErrors="showErrorMessages" :disabled="slotProps.disabled"
                                     @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                    <CustomSelectOne label="Użytkowanie" name="utilization" :items="dicts.utilization" class="w-6"
                                     v-model="slotProps.habitatData.utilization"
                                     required :showErrors="showErrorMessages" :disabled="slotProps.disabled"
                                     @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                </div>
                <div class="flex gap-3">
                    <CustomSelectOne label="Uwarunkowanie hydrologiczne" name="water" :items="dicts.water"
                                     class="w-6" v-model="slotProps.habitatData.water"
                                     required :showErrors="showErrorMessages" :disabled="slotProps.disabled"
                                     @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                    <CustomMultiSelect label="Uwarunkowanie przestrzenne" name="spatial" :items="dicts.spatial" class="w-6"
                                       v-model="slotProps.habitatData.spatial"
                                       required :showErrors="showErrorMessages" :disabled="slotProps.disabled"
                                       @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                </div>
                <div class="flex gap-3">
                    <CustomSelectOne label="Sukcesja" name="succession" :items="dicts.succession" class="w-6 pr-2"
                                     v-model="slotProps.habitatData.succession"
                                     required :showErrors="showErrorMessages" :disabled="slotProps.disabled"
                                     @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                </div>
                <div class="flex gap-3">
                    <CustomInputNumber label="Średnia wysokość roślinności (dokładność do 10cm)" class="w-6"
                                       name="averageHeightPlant" v-model="slotProps.habitatData.averageHeightPlant"
                                       required :showErrors="showErrorMessages" :disabled="slotProps.disabled"
                                       @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                    <CustomInputNumber label="Średnia wysokość kęp (dokładność do 10cm)" class="w-6"
                                       name="averageHeightClump" v-model="slotProps.habitatData.averageHeightClump"
                                       required :showErrors="showErrorMessages" :disabled="slotProps.disabled"
                                       @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                </div>
            </div>
        </template>
    </HabitatDataTemplate>
</template>

<script>
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import { EmptyStructures } from "@/utils/EmptyStructures";
    import HabitatDataTemplate from "@/views/form/HabitatDataTemplate.vue";

    export default {
        name: "HabitatDataMDU",

        components: {
            HabitatDataTemplate,
            CustomSelectOne,
            CustomMultiSelect,
            CustomInputNumber,
        },

        props: {
            habitatData: {
                type: Object,
            },
            habitatDataToChange: {
                type: Object,
            },
        },

        inject: ["editing", "dicts", "showErrorMessages"],

        emits: ["update:habitatDataToChange"],

        computed: {
            EmptyStructures() {
                return EmptyStructures;
            },
            localHabitatDataToChange: {
                get() { return this.habitatDataToChange; },
                set(value) { this.$emit("update:habitatDataToChange", value); },
            },
        },
    };
</script>

<style lang="scss">
</style>
