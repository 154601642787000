<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing" v-model:step="step"
                      v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :someGeobjectWasAudited="someGeobjectWasAudited"
                      :validationCorrect="validationCorrect" @goTo2Step="validateFirstStep">
        <template v-slot:formMap>
            <FormPositions v-model="form.positions" :field="form.field" @reloadFormData="getFormData"/>
        </template>
        <template v-slot:formObservations>
            <FormMRCObservations :field="form.field.shape" :allPositions="form.positions"
                                 ref="observationsView" v-model:step="step"/>
        </template>
    </FormViewTemplate>
</template>

<script>
    import {computed} from "vue";
    import {
        getForm,
        getList as getDict,
    } from "@/swagger/vue-api-client";
    import FormPositions from "@/components/formSharedComponents/FormPositions.vue";
    import FormMRCObservations from "./components/FormMRCObservations.vue";
    import {DateUtils} from "@/utils/DateUtils";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "FormMRCView",

        components: {
            FormViewTemplate,
            FormPositions,
            FormMRCObservations,
        },

        data() {
            return {
                programCode: "MRC",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                step: 1,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                controlTimeFrame: computed(() => this.form.control),
                programEditionId: computed(() => this.form.programEditionId),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            this.getFormData();
        },

        methods: {
            getFormData() {
                getForm({program: this.programCode, formId: this.$route.params.id})
                    .then((response) => {
                        this.form = response.data;
                        console.log(this.form);
                        ValidateUtils.flattenData(this.form.positions, "position");
                        for (let i = 0; i < this.form.positions.length; i += 1) {
                            const position = this.form.positions[i];
                            if (position.controls !== null) {
                                for (let j = 0; j < position.controls.length; j += 1) {
                                    const control = position.controls[j];
                                    if (control.startTime !== null) {
                                        control.startTime = control.startTime.substring(0, 5);
                                    }
                                    if (control.endTime !== null) {
                                        control.endTime = control.endTime.substring(0, 5);
                                    }
                                }
                            }
                        }
                        this.editing = this.form.editable
                            && ProgramEditionState.isEditingByDefault(this.form.state);
                        return getDict({programCode: this.programCode, formId: this.$route.params.id});
                    })
                    .then((response) => {
                        this.dicts = response.data;
                        console.log(this.dicts);
                        this.form.positions.forEach((p) => {
                            p.controls.forEach((c) => {
                                this.dicts.controlMethod.forEach((cM) => {
                                    if (cM.info && c.controlMethod.code === cM.code) {
                                        c.controlMethod.info = cM.info;
                                    }
                                });
                            });
                        });
                        this.loaded = true;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loaded = false;
                    });
            },
            getFormRequest() {
                const request = ValidateUtils.getFormRequest(this.form, this.programCode, "positions", true);
                if (!this.form.noFormControl) {
                    for (let i = 0; i < this.form.positions.length; i += 1) {
                        if (this.form.positions[i].controls != null) {
                            for (let j = 0; j < this.form.positions[i].controls.length; j += 1) {
                                ValidateUtils.formatControlDateStartTimeAndEndTime(this.form.positions[i].controls[j]);
                            }
                        }
                        request.positions.push(this.form.positions[i]);
                    }
                }
                return request;
            },
            addErrorToast(detailMessage) {
                this.$toast.add({
                    severity: "error",
                    summary: "Błąd",
                    detail: detailMessage,
                    life: 5000,
                });
            },
            validateControls(i) {
                const position = this.form.positions[i];
                let isCorrect = true;
                if (position.controls == null) return isCorrect;
                for (let j = 0; j < position.controls.length; j += 1) {
                    const control = position.controls[j];
                    let isSingleControlCorrect = true;
                    isSingleControlCorrect = ValidateUtils.validateControls(
                        position,
                        j,
                        ["controlDate", "startTime", "endTime", "controlMethod"],
                        true,
                    ) || !((!control.noBirds || control.noBirds === null) && (control.occupiedNests === null
                        || control.occupiedNests === ""));
                    if (control.controlDate !== null) {
                        if (this.form.monitoringYear.toString() !== DateUtils.dateAsString(
                            control.controlDate).slice(0, 4)) {
                            this.addErrorToast(`${position.name}, kontrola nr ${j + 1}:
                                data niezgodna z rokiem monitoringu.`);
                            isSingleControlCorrect = false;
                        }
                        if (j > 0) {
                            for (let k = j; k >= 0; k -= 1) {
                                if (position.controls[k].controlDate && position.controls[j].controlDate
                                    && position.controls[k].controlDate > position.controls[j].controlDate) {
                                    this.addErrorToast(`${position.name}, data kontrolai nr ${j + 1}
                                        musi być późniejsza niż data poprzedniej kontroli.`);
                                    isSingleControlCorrect = false;
                                }
                            }
                        }
                    }
                    isSingleControlCorrect = ValidateUtils.validateIsDateInRange(control.controlDate, this.dicts.controlType[i].controlStart, this.dicts.controlType[i].controlEnd) && isSingleControlCorrect;
                    if (!isSingleControlCorrect) {
                        position.error = true;
                        control.error = true;
                        isCorrect = false;
                    }
                }
                return isCorrect;
            },
            validateData() {
                let isCorrect = ValidateUtils.validateNoFormControl(this.form);
                if (isCorrect && !this.form.noFormControl) {
                    isCorrect = this.validateFirstStep() && isCorrect;
                    for (let i = 0; i < this.form.positions.length; i += 1) {
                        const position = this.form.positions[i];
                        if (position.error) delete position.error; // remove previous checks
                        if (position.notAudited === false || position.notAudited === null
                            || position.notAudited === undefined) {
                            isCorrect = this.validateControls(i) && isCorrect;
                        }
                    }
                }
                this.validationCorrect = isCorrect;
                this.request = this.getFormRequest();
            },
            validateFirstStep() {
                const errors = ValidateUtils.validateNotAudited(this.form.positions, "Stanowisko");
                this.addErrorToasts(errors);
                return errors.length === 0;
            },
            addErrorToasts(errors) {
                errors.forEach((message) => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: message,
                        life: 5000,
                    });
                });
            },
        },

        computed: {
            someGeobjectWasAudited() {
                return ValidateUtils.someGeobjectWasAudited(this.form.positions, this.form.noFormControl);
            },
        },
    };
</script>

<style lang="scss">
</style>
