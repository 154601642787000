<template>
    <div v-if="!isAdmin" class="flex flex-1 flex-wrap add-in-table-header flex-column gap-3" v-focustrap>
        <div class="flex flex-1 gap-2 flex-wrap align-items-center">
            <div class="w-2">
                <CustomSelectOne label="Kryterium lęgowości" name="nestingCriteria" v-model="newItem.nestingCriteria"
                                 :items="dicts.nestingCriteria" filter
                                 :disabled="!editing || !disabled" required :showErrors="showErrors"/>
            </div>
            <div class="w-2">
                <CustomInputNumber label="Liczba par lub os. dorosłych" name="pairs" v-model="newItem.pairs"
                                   required :showErrors="showErrors"
                                   :disabled="!editing || !disabled || newItem.nestingCriteria?.code === 'MŁO'"/>
            </div>
            <div class="w-2">
                <CustomInputNumber label="Liczba młodych w rodzinie" name="young" v-model="newItem.young"
                                   :disabled="!editing || (newItem.nestingCriteria?.code !== 'PIS' && newItem.nestingCriteria?.code !== 'MŁO') || !disabled"/>
            </div>
            <div class="w-3">
                <CustomInputText label="Dodatkowe informacje" name="additionalInfo" v-model="newItem.additionalInfo"
                                 :disabled="!editing || !disabled"/>
            </div>
            <CustomButton v-if="editing" icon="pi pi-plus" label="Dodaj" @click="add" :error="error"
                          style="position: absolute; right: 14px; margin-bottom: 1rem" v-on:keydown.tab="add"/>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import FocusTrap from "primevue/focustrap";
    import {SystemRole} from "@/utils/SystemRole";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import CustomInputText from "@/components/form/CustomInputText.vue";

    export default {
        name: "AddBirdsMPO",

        components: {
            CustomInputText,
            CustomSelectOne,
            CustomInputNumber,
            CustomButton,
        },

        directives: {
            focustrap: FocusTrap,
        },

        props: {
            modelValue: {
                type: Object,
            },
            error: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["editing", "dicts"],

        data() {
            return {
                newItem: _.cloneDeep(EmptyStructures.getEmptyBirds()),
                showErrors: false,
            };
        },

        methods: {
            add() {
                if (!ValidateUtils.validateNotEmpty(this.newItem, ["pairs", "nestingCriteria"])) {
                    this.showErrors = true;
                } else {
                    this.birds.push({
                        ...this.newItem,
                        ordinal: this.birds.length + 1,
                    });
                    this.newItem = _.cloneDeep(EmptyStructures.getEmptyBirds());
                    this.showErrors = false;
                }
            },
        },

        computed: {
            birds: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            isAdmin() {
                return SystemRole.isAdmin();
            },
        },

        watch: {
            birds: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
            "newItem.nestingCriteria": {
                handler(value) {
                    if (value?.code === "MŁO") {
                        this.newItem.pairs = 1;
                    }
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
.add-in-table-header {
    margin-bottom: -1rem,
}
.disabled-not-opacity {
    .p-disabled, .p-component:disabled {
        opacity: 1;
    }
}
</style>
