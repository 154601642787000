<template>
    <div :id="mapId" :style="height ? `height: ${this.height}` : ''" />
    <div ref="subfieldPopup" class="marker-popup hidden"></div>
    <div v-if="subfieldPopup">
        <div v-for="(subfield,index) in allSubfields" ref="subfieldPopup" class="marker-popup" :key="subfield.id">
            <slot name="subfieldPopup" :subfield="subfield" :index="index"></slot>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import L from "leaflet";
    import {MapMixin} from "@/mixins/MapMixin";

    export default {
        name: "MapSingleMarker",

        mixins: [MapMixin],

        props: {
            modelValue: null,
            mapId: {
                type: String,
                default: "map",
            },
            height: {
                type: String,
                default: null,
            },
            isOn: {
                type: Boolean,
                default: true,
            },
            zoomControls: {
                type: Boolean,
                default: true,
            },
            field: {
                type: Array,
                default: () => [],
            },
            subfield: {
                type: Object,
            },
            otherSubfields: {
                type: Array,
            },
            subfieldPopup: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["update:modelValue", "emitCoor"],

        data() {
            return {
                newMarker: null,
                iconGreen: '<svg width="27" height="38" viewBox="0 0 27 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 13.05C0 5.85 5.85 0 13.05 0L13.44 0.00599992C20.46 0.213 26.1 5.9805 26.1 13.05C26.1 20.1 19.65 30.3 14.1 37.05C13.8 37.35 13.5 37.65 13.05 37.65C12.6 37.65 12.3 37.35 12 37.05C6.3 30.15 0 20.1 0 13.05ZM9.05005 12.8252C9.05005 15.0474 10.8278 16.8252 13.05 16.8252C15.2723 16.8252 17.05 15.0474 17.05 12.8252C17.05 10.603 15.2723 8.8252 13.05 8.8252C10.8278 8.8252 9.05005 10.603 9.05005 12.8252Z" fill="#33795B"/></svg>',
                fieldOnMap: [],
                polandBoundaries: [
                    {longitude: 14.116666666666667, latitude: 54.8333333},
                    {longitude: 24.15, latitude: 54.8333333},
                    {longitude: 24.15, latitude: 49},
                    {longitude: 14.116666666666667, latitude: 49},
                    {longitude: 14.116666666666667, latitude: 54.8333333},
                ],
                allSubfields: [],
            };
        },

        watch: {
            modelValue: {
                handler(newVal) {
                    if (newVal[0] != null && newVal[1] != null) {
                        this.updateMarker(newVal);
                    } else if (newVal[0] == null && newVal[1] == null && this.newMarker != null) {
                        this.map.removeLayer(this.newMarker);
                    }
                },
                deep: true,
            },
        },

        beforeMount() {
            if (this.otherSubfields && this.otherSubfields.length > 0) {
                this.allSubfields = [this.subfield, ...this.otherSubfields];
            } else {
                this.allSubfields = [this.subfield];
            }
        },

        mounted() {
            this.initCustomIdMap(this.mapId);
            this.addZoomControls();
            if (this.field !== null && this.field.length > 0) {
                this.makeField();
            } else {
                this.zoomToPoland();
            }
            if (this.subfield && this.subfield.shape?.length > 0) {
                this.makeSubfield(this.subfield.shape, undefined, 0);
            }
            if (this.otherSubfields && this.otherSubfields.length > 0) {
                this.makeSubfields();
            }
            // this.getMarkerCoordinates();
            if (this.isOn) {
                this.map.on("click", (e) => {
                    if (this.newMarker !== null) {
                        this.map.removeLayer(this.newMarker);
                    }

                    const Icon = new L.divIcon({
                        html: this.iconGreen,
                        iconSize: [27, 38],
                        iconAnchor: [13.5, 38],
                        popupAnchor: [1, -34],
                    });

                    this.newMarker = L.marker(e.latlng, {
                        icon: Icon,
                        bubblingMouseEvents: false,
                        draggable: this.isOn,
                    });

                    this.newMarker.on("dragend", () => {
                        this.getCoordinatesFromMarker(this.newMarker._latlng);
                    });

                    this.newMarker.addTo(this.map);

                    this.map.setView(e.latlng);

                    this.getCoordinatesFromMarker(this.newMarker?._latlng);
                });
            } else {
                this.map.off("click");
            }
            this.updateMarker(this.modelValue);
        },

        methods: {
            getCoordinatesFromMarker(result) {
                const coordinates = {
                    lat: Number((result.lat).toFixed(6)),
                    lng: Number((result.lng).toFixed(6)),
                };
                this.$emit("emitCoor", coordinates);
            },
            makeField() {
                for (let i = 0; i < this.field.length; i += 1) {
                    this.fieldOnMap.push([this.field[i].latitude, this.field[i].longitude]);
                }
                const fieldShape = L.polygon(this.fieldOnMap);
                fieldShape.setStyle({fillColor: "transparent", color: "#33795B"});
                fieldShape.addTo(this.map);
                this.map.fitBounds(this.fieldOnMap);
                this.map.setMinZoom(this.map.getZoom() - 6);
            },
            makeSubfields() {
                this.otherSubfields.forEach((s, i) => {
                    this.makeSubfield(s.shape, "#14141499", i + 1);
                });
            },
            makeSubfield(subfield, color = "#EA3030", index) {
                const subfieldArray = [];
                for (let i = 0; i < subfield.length; i += 1) {
                    subfieldArray.push([subfield[i].latitude, subfield[i].longitude]);
                }
                const subfieldShape = L.polygon(subfieldArray);
                subfieldShape.setStyle({fillColor: "transparent", color: color});
                let popup;
                popup = L.popup().setContent(this.$refs.subfieldPopup[index]);
                subfieldShape.addTo(this.map);
                subfieldShape.bindPopup(popup, {autoClose: false, closeOnClick: false}).openPopup();
            },
            zoomToPoland() {
                for (let i = 0; i < this.polandBoundaries.length; i += 1) {
                    this.fieldOnMap.push([this.polandBoundaries[i].latitude, this.polandBoundaries[i].longitude]);
                }
                this.map.fitBounds(this.fieldOnMap);
                this.map.setMinZoom(this.map.getZoom() - 1);
            },
            updateMarker(coor) {
                if (!coor || coor[0] === null || coor[1] === null) return;
                if (this.newMarker !== null) {
                    this.map.removeLayer(this.newMarker);
                }

                if (coor.length === 0) return;

                const Icon = new L.divIcon({
                    html: this.iconGreen,
                    iconSize: [27, 38],
                    iconAnchor: [13.5, 38],
                    popupAnchor: [1, -34],
                });

                this.newMarker = L.marker(coor, {
                    icon: Icon,
                    bubblingMouseEvents: false,
                    draggable: this.isOn,
                });

                this.newCircle = L.circle([coor[0], coor[1]]);

                if (this.isOn) {
                    this.newMarker.on("dragend", () => {
                        this.getCoordinatesFromMarker(this.newMarker._latlng);
                    });
                }

                this.newMarker.addTo(this.map);

                this.map.setView(coor);
            },
            addZoomControls() {
                if (this.zoomControls) {
                    const zoom = L.control.zoom({
                        position: "bottomright",
                    });
                    zoom.addTo(this.map);
                }
            },
        },
    };
</script>

<style lang="scss">
    @import "../assets/scss/leaflet-map";
    @import "../../assets/theme/mytheme/variables";
</style>
