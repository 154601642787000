<template>
    <div v-if="!isAdmin" class="flex flex-1 flex-wrap add-in-table-header flex-column" v-focustrap>
        <div class="flex flex-1 column-gap-2 flex-wrap align-items-center">
            <CustomButton icon="pi pi-copy" style="margin-bottom: 1rem"
                          title="Skopiuj ostatnio dodany gatunek"
                          :disabled="!editing" @click="copyLastSpecies"
                          v-on:keydown.enter="copyLastSpecies" />
            <div class="w-2" style="min-width: 200px;">
                <CustomSelectOne label="Gatunek" name="species" v-model="newItem.species"
                                 required :showErrors="showErrors" :disabled="!editing"
                                 :items="dicts.programBirds" itemLabel="name" />
            </div>
            <div class="w-2" style="min-width: 200px;">
                <CustomSelectOne label="Kryterium lęgowości" name="nestingCriteria" v-model="newItem.nestingCriteria"
                                 :items="internalNestingCriteria"
                                 short required :disabled="!editing || !newItem.species" tooltip
                                 :itemLabel="getDictLabelWithCodePrefix" :showErrors="showErrors && newItem.species !== null" />
            </div>
            <div class="w-1" style="min-width: 120px;">
                <CustomInputNumber label="L. os. dorosłych" name="count" v-model="newItem.count"
                                   :disabled="!editing" />
            </div>
            <div class="w-1" style="min-width: 100px;">
                <CustomInputNumber label="L. gniazd" name="nests" v-model="newItem.nests"
                                   :disabled="!editing || !nestCriteria" />
            </div>
            <div class="w-2" style="min-width: 200px;">
                <CustomInputNumber label="Ocena l. par w kryterium" name="pairs" v-model="newItem.pairs"
                                   :showErrors="showErrors" :disabled="!editing" required />
            </div>
            <div class="w-2" style="min-width: 200px;">
                <CustomInputText label="Szczegóły obserwacji" name="notesBird"
                                 v-model="newItem.notesBird" :disabled="!editing" />
            </div>
            <CustomButton v-if="editing" icon="pi pi-plus" label="Dodaj" @click="add" :error="error" class="media-q"
                          style="position: absolute; right: 14px; margin-bottom: 1rem; min-width: 110px;" v-on:keydown.tab="add"/>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import FocusTrap from "primevue/focustrap";
    import {SystemRole} from "@/utils/SystemRole";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import CustomInputText from "@/components/form/CustomInputText.vue";

    export default {
        name: "AddBirdsMPWR",

        components: {
            CustomSelectOne,
            CustomInputNumber,
            CustomButton,
            CustomInputText,
        },

        directives: {
            focustrap: FocusTrap,
        },

        props: {
            modelValue: {
                type: Object,
            },
            error: {
                type: Boolean,
                default: false,
            },
            freeNestingCriteria: {
                type: Map,
                required: true,
            },
        },

        inject: ["editing", "dicts"],

        data() {
            return {
                newItem: _.cloneDeep(EmptyStructures.getEmptyBirds()), // jak będzie backend to dopisać do emptyStructures nowe pola
                showErrors: false,
                internalNestingCriteria: [],
                nestCriteria: false,
            };
        },

        methods: {
            add() {
                if (!ValidateUtils.validateNotEmpty(this.newItem, ["nestingCriteria", "pairs"])
                    || this.newItem.districts > this.newItem.count) {
                    this.showErrors = true;
                } else {
                    this.birds.push({
                        ...this.newItem,
                        ordinal: this.birds.length + 1,
                    });
                    this.newItem = _.cloneDeep(EmptyStructures.getEmptyBirds());
                    this.showErrors = false;
                    this.nestCriteria = false;
                }
            },
            copyLastSpecies() {
                if (this.birds?.length > 0) {
                    this.newItem.species = this.birds[this.birds.length - 1].species;
                }
            },
            getDictLabelWithCodePrefix(item) {
                if (item == null) return "";
                return this.textTruncate(item.code + " - " + item.label);
            },
            textTruncate(text) {
                if (text.length > 40) {
                    return _.truncate(text, {
                        length: 40,
                        separator: " ",
                    });
                }
                return text;
            },
        },

        computed: {
            birds: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            isAdmin() {
                return SystemRole.isAdmin();
            },
        },

        watch: {
            birds: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
            "newItem.species": {
                handler(value) {
                    if (value) {
                        const free = this.freeNestingCriteria.get(value.code);
                        const arr = [];
                        this.dicts.nestingCriteria.forEach((nc) => {
                            nc.info.forEach((i) => {
                                if (i.code === value.code) {
                                    if (Array.from(free.values()).includes(nc.code)) {
                                        arr.push(nc);
                                    }
                                }
                            });
                        });
                        this.internalNestingCriteria = arr;
                    } else {
                        this.internalNestingCriteria = [];
                        this.newItem.nestingCriteria = null;
                    }
                },
                deep: true,
            },
            "newItem.nestingCriteria": {
                handler(value) {
                    if (value) {
                        const code = value.code.split("_").pop();
                        if (code === "GNS"
                            || code === "JAJ"
                            || code === "PIS"
                            || code === "WYS") {
                            this.nestCriteria = true;
                        } else {
                            this.newItem.nests = null;
                            this.nestCriteria = false;
                        }
                    }
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
    .add-in-table-header {
        margin-bottom: -1rem,
    }
    .disabled-not-opacity {
        .p-disabled, .p-component:disabled {
            opacity: 1;
        }
    }

    .media-q {
        @media screen and (max-width: 1672px) {
            position: relative !important;
            right: 0 !important;
        }
    }
</style>
