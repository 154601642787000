<template>
    <CustomCheckbox label="Brak ptaków" v-model="position.noBirds" :disabled="!editing" name="noBirds" class="pb-3"/>
    <div class="flex flex-column">
        <div class="flex gap-3">
            <CustomSelectOne v-if="!position.noBirds" label="Kryterium lęgowości" name="nestingCriteria"
                             v-model="position.nestingCriteria" class="w-6"
                             :items="$route.query.controlCode === 'Zuraw' ? internalNestingCriteria : dicts.nestingCriteria"
                             required :showErrors="showErrorMessages"
                             :disabled="!editing" />
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";

    export default {
        name: "FormBirdsMFGP",

        components: {
            CustomCheckbox,
            CustomSelectOne,
        },

        props: {
            modelValue: null,
        },

        inject: ["editing", "showErrorMessages", "dicts"],

        emits: ["update:modelValue"],

        data() {
            return {
                internalNestingCriteria: [],
            };
        },

        beforeMount() {
            if (this.position && this.position.controlMethod?.label === "nasłuch") {
                this.dicts.nestingCriteria.forEach((nc) => {
                    if (nc.code === "PG") {
                        this.internalNestingCriteria = [nc];
                    }
                });
            } else {
                this.internalNestingCriteria = _.cloneDeep(this.dicts.nestingCriteria);
            }
        },

        methods: {
            showError() {
                return this.showErrorMessages && this.position.noBirds !== true;
            },
            getDictLabelWithCodePrefix(item) {
                if (item == null) return "";
                return this.textTruncate(item.code + " - " + item.label);
            },
            textTruncate(text) {
                if (text.length > 80) {
                    return _.truncate(text, {
                        length: 80,
                        separator: " ",
                    });
                }
                return text;
            },
        },

        computed: {
            position: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            internalBirds: {
                handler(value) { this.$emit("update:birds", value); },
                deep: true,
            },
            position: {
                handler(value) {
                    if (value && value.controlMethod?.label === "nasłuch") {
                        this.dicts.nestingCriteria.forEach((nc) => {
                            if (nc.code === "PG") {
                                this.internalNestingCriteria = [nc];
                            }
                        });
                    } else {
                        this.internalNestingCriteria = _.cloneDeep(this.dicts.nestingCriteria);
                    }
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
