<template>
    <div class="flex">
        <div v-if="loadRegCoordinators"
             class="add-regionalCoordinator-container flex gap-2" style="width: 100%">
            <div style="width: 30%">
                <CustomSelectOne label="Koordynator regionalny" :items="allRegCoordinators"
                                 itemLabel="name" v-model="newItem.regionalCoordinator"
                                 class="flex-1 w-0 mb-0" name="regionalCoordinator"
                                 :showErrors="showErrorMessages" required style="max-width: 300px"/>
            </div>
            <CustomMultiSelect label="Powierzchnie" :items="availableFields" itemLabel="name"
                               v-model="newItem.fields"
                               class="flex-1 w-0 mb-0" name="programFieldsList" required
                               :showErrors="showErrorMessages" virtual-scrolling />
            <CustomButton label="Dodaj koordynatora regionalnego" icon="pi pi-plus" @click="addNew" />
        </div>
    </div>
</template>

<script>
    import {
        editRegionalCoordinatorCast,
        getAllRegionalCoordinators,
    } from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";

    export default {
        name: "AddRegionalCoordinator",

        components: {
            CustomMultiSelect,
            CustomSelectOne,
            CustomButton,
        },

        props: {
            availableFields: {
                type: Array,
                required: true,
            },
        },

        inject: ["programCode", "nationalCoordinators"],

        data() {
            return {
                newItem: {
                    regionalCoordinator: null,
                    fields: null,
                },
                allRegCoordinators: [],
                loadRegCoordinators: false,
                showErrorMessages: false,
            };
        },

        emits: ["newRegionalCoordinator"],

        beforeMount() {
            getAllRegionalCoordinators({ program: this.programCode })
                .then((response) => {
                    this.allRegCoordinators = response.data;
                    this.loadRegCoordinators = true;
                });
        },

        methods: {
            addNew() {
                if (this.nationalCoordinators.length === 0) {
                    this.$toast.add({
                        severity: "error",
                        summary: "Nie wybrano koordynatorów krajowych",
                        life: 3000,
                    });
                    return;
                }
                if (this.newItem.regionalCoordinator === null || this.newItem.fields === null) {
                    this.showErrorMessages = true;
                } else {
                    const regionalCoordinatorRequest = {
                        userId: this.newItem.regionalCoordinator.id,
                        fieldIDs: [],
                    };
                    for (let i = 0; i < this.newItem.fields.length; i += 1) {
                        regionalCoordinatorRequest.fieldIDs.push(this.newItem.fields[i].id);
                    }
                    editRegionalCoordinatorCast({
                        programEditionId: this.$route.params.id,
                        body: regionalCoordinatorRequest,
                    })
                        .then(() => {
                            this.$emit("newRegionalCoordinator", this.newItem);
                            this.newItem = {
                                regionalCoordinator: null,
                                fields: null,
                            };
                        })
                        .catch((err) => {
                            console.log(err);
                            if (err.response.status === 422) {
                                this.$toast.add({
                                    severity: "error",
                                    summary: "Powierzchnia jest już przypisana do innego koordynatora regionalnego",
                                    life: 3000,
                                });
                            }
                        });
                    this.showErrorMessages = false;
                }
            },
        },
    };
</script>

<style lang="scss">
.add-regionalCoordinator-container {
    .custom-multi-select {
        .p-multiselect {
            width: 100%;
            max-width: 400px;
        }
    }
}
</style>
