<template>
    <Toast />
    <ImportPositionDialog v-model="showImportPositionDialog"/>
    <div class="flex justify-content-between flex-column">
        <div class="flex gap-3 row-gap-0">
            <div class="flex gap-3 flex-wrap row-gap-0">
                <CustomMultiSelect v-if="programs !== null" id="programFilter" label="Program"
                                   v-model="internalValue.programs" :items="programs" name="programFilter"
                                   class="w-8rem" @update:modelValue="update"
                                   itemLabel="" />
                <CustomMultiSelect id="fieldFilter" v-model="internalValue.fieldNames"
                                   itemValue="name" :items="fields" class="w-18rem" item-label="name"
                                   name="fieldFilter" label="Filtruj wg nazwy powierzchni" @update:modelValue="update"
                                   :disabled="internalValue.programs === null || internalValue.programs.length === 0"/>
                <CustomMultiSelect v-if="statuses.length > 0" id="statusFilter" v-model="internalValue.statuses"
                                   class="w-15rem" itemValue="value" :items="statuses" name="statusFilter"
                                   label="Filtruj według statusu" @update:modelValue="update" />
                <CustomInputText id="name" v-model="internalValue.name" placeholder="Szukaj według nazwy"
                                 label="Nazwa" class="w-12rem" @update:modelValue="update" name="name" />
                <GeographicalFilters v-model="internalValue" ref="geographicalFilters"
                                     v-model:matchMode="internalValue.matchMode" :filterBy="matchModeOptions" />
            </div>
            <div class="flex align-self-end justify-content-end field">
                <CustomButton label="Wyczyść" @click="clear"/>
            </div>
        </div>
    </div>
    <div class="flex gap-3 justify-content-end flex-wrap mb-3">
        <CustomButton @click="navigateToCreatePosition" label="Utwórz stanowisko"/>
        <CustomButton @click="exportData" label="Eksportuj"/>
        <CustomButton label="Importuj" @click="showImportPositionDialog = true" />
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import CustomButton from "../../../components/form/CustomButton";
    import CustomMultiSelect from "../../../components/form/inner/CustomMultiSelect.vue";
    import CustomInputText from "../../../components/form/CustomInputText";
    import {SearchCriteria} from "../../../utils/SearchCriteria";
    import {
        listFieldsForProgram,
        getListOfPrograms,
        getListOfStatuses,
    } from "@/swagger/vue-api-client";
    import GeographicalFilters from "@/components/filters/GeographicalFilters.vue";
    import {FileUtils} from "@/utils/FileUtils";
    import ImportPositionDialog from "./ImportPositionDialog.vue";

    export default {
        name: "PositionDictSearchCrtiera",

        components: {
            CustomButton,
            CustomMultiSelect,
            CustomInputText,
            GeographicalFilters,
            ImportPositionDialog,
            Toast,
        },

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue", "unselect"],

        data() {
            return {
                programs: null,
                statuses: [],
                fields: [],
                name: "",
                searchCriteria: SearchCriteria,
                matchModeOptions: [
                    {value: "FIELD", label: "Powierzchni"},
                    {value: "POSITION", label: "Stanowiskach"},
                ],
                showImportPositionDialog: false,
            };
        },

        beforeMount() {
            getListOfPrograms()
                .then((response) => {
                    this.programs = response.data;
                });
            getListOfStatuses()
                .then((response) => {
                    for (let i = 0; i < response.data.length; i += 1) {
                        this.statuses.push({value: response.data[i], label: this.computedStatus(response.data[i])});
                    }
                });
        },

        methods: {
            navigateToCreatePosition() {
                this.$router.push("/create-position");
            },

            computedStatus(status) {
                if (status === "ACTIVE") return "Aktywne";
                if (status === "REPORTED_REMOVE") return "Zgłoszone do usunięcia";
                if (status === "REPORTED_RESTORE") return "Zgłoszone do przywrócenia";
                if (status === "ARCHIVED") return "Zarchiwizowane";
                if (status === "CREATED") return "Utworzone";
                return "";
            },

            getFields() {
                if (!this.internalValue.programs || this.internalValue.programs.length === 0) {
                    this.fields = [];
                    return;
                }

                const promises = this.internalValue.programs
                    .map((program) => listFieldsForProgram({ program }));

                Promise.all(promises)
                    .then((responses) => {
                        this.fields = responses.reduce((acc, response) => acc.concat(response.data), []);
                    })
                    .catch((error) => {
                        console.error("An error occurred:", error);
                    });
            },

            update() {
                this.$emit("update:modelValue", this.internalValue);
            },

            clear() {
                this.internalValue.programs = [];
                this.internalValue.fieldNames = [];
                this.internalValue.statuses = [];
                this.internalValue.name = "";
                this.$refs.geographicalFilters.clear();
                this.$emit("unselect");
                this.update();
            },
            exportData() {
                FileUtils.downloadFile(this.modelValue, "stanowiska.xlsx", "/position/export");
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        watch: {
            "internalValue.programs"(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.getFields();
                }
            },
        },
    };
</script>

<style lang="scss">
</style>
