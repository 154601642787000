<template>
    <CustomCheckbox label="Brak innych gatunków" v-model="internalNoBirds" :disabled="!editing"
                    name="noOthers" :error="showError()"/>
    <DataTable v-if="!internalNoBirds" :value="internalBirds" v-model:editingRows="editingRows" editMode="row"
               dataKey="ordinal" @row-edit-save="onRowEditSave" size="small" :rowClass="() => 'rowWidth'"
               class="navigate-table" @click="arrowsNavigateTable" scrollable scrollHeight="500px">
        <template #header>
            <AddOtherBirdsMMC v-model="internalBirds" :countingMethod="countingMethod"
                              :error="showError()"/>
        </template>
        <Column header="L.p." field="ordinal" style="width: 4.1666%;" />
        <Column header="Gatunek" class="w-2" field="species">
            <template #body="slotProps">
                {{ slotProps.data.species?.namePl ? slotProps.data.species?.namePl : slotProps.data.species?.name }}
            </template>
            <template #editor="{data,field}">
                <CustomSelectOne name="species" v-model="data[field]"
                                 :items="dicts.programBirds" itemLabel="name" />
            </template>
        </Column>
        <Column header="Metoda oceny liczebności" class="w-2" field="countingMethod">
            <template #body="slotProps">
                {{ slotProps.data.countingMethod?.label }}
            </template>
            <template #editor="{data,field}">
                <CustomSelectOne name="countingMethod" v-model="data[field]"
                                 :items="countingMethod" />
            </template>
        </Column>
        <Column header="Liczba par lęgowych" class="w-2" field="pairs">
            <template #editor="{data,field}">
                <CustomInputNumber name="pairs" v-model="data[field]" :disabled="data.countingMethod?.code === 'NIE'"/>
            </template>
        </Column>
        <Column header="Kiedy oceniono liczebność?" class="w-2" field="controlType">
            <template #body="slotProps">
                {{ slotProps.data.controlType?.label }}
            </template>
            <template #editor="{data,field}">
                <CustomSelectOne name="controlType" v-model="data[field]"
                                 :items="dicts.controlType" :disabled="data.countingMethod === 'NIE'"/>
            </template>
        </Column>
        <Column class="w-2"/>
        <Column v-if="editing" :rowEditor="true" style="text-align: center;" class="w-1"/>
        <Column v-if="editing" style="width: 4.1666%; text-align: center;">
            <template #body="slotProps">
                <CustomButton icon="pi pi-trash" borderColor="transparent"
                              @click="deleteBirdObservation(slotProps.data)" />
            </template>
        </Column>
    </DataTable>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import TableUtils from "@/utils/TableUtils";
    import TableArrowsNavigation from "@/utils/TableArrowsNavigation";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import AddOtherBirdsMMC from "@/views/formMMC/components/AddOtherBirdsMMC.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";

    export default {
        name: "FormOtherBirdsMMC",

        components: {
            CustomCheckbox,
            AddOtherBirdsMMC,
            CustomInputNumber,
            DataTable,
            Column,
            CustomSelectOne,
            CustomButton,
        },

        props: {
            noBirds: {
                type: Boolean,
            },
            birds: {
                type: Array,
                default: () => [],
            },
            controlMethod: {
                type: Object,
            },
        },

        inject: ["editing", "dicts", "showErrorMessages"],

        emits: ["update:birds", "update:noBirds"],

        data() {
            return {
                editingRows: ref([]),
                countingMethod: [],
            };
        },

        mounted() {
            this.updateCountingMethod(this.controlMethod);
        },

        methods: {
            arrowsNavigateTable(ctx) {
                TableArrowsNavigation.arrowsNavigateTable(ctx);
            },
            onRowEditSave(event) {
                const {newData, index} = event;
                this.internalBirds[index] = newData;
            },
            deleteBirdObservation(item) {
                const index = this.internalBirds.indexOf(item);
                this.internalBirds.splice(index, 1);
            },
            showError() {
                return this.showErrorMessages && ((this.internalBirds == null
                    || this.internalBirds.length === 0) && !(this.internalNoBirds === true));
            },
            updateCountingMethod(controlMethod) {
                if (controlMethod?.code === "Visit") {
                    this.countingMethod = this.dicts.countingMethod.filter((i) => i.code !== "NO_COUNTED");
                } else {
                    this.countingMethod = this.dicts.countingMethod;
                }
            },
        },

        computed: {
            internalNoBirds: {
                get() { return this.noBirds; },
                set(value) { this.$emit("update:noBirds", value); },
            },
            internalBirds: {
                get() { return this.birds; },
                set(value) { this.$emit("update:birds", value); },
            },
        },

        watch: {
            internalBirds: {
                handler(value) {
                    value.forEach((b) => {
                        b.count = b.males + b.females + b.otherAdults + b.young;
                    });
                    this.$emit("update:birds", value);
                },
                deep: true,
            },
            birds: {
                handler(value) {
                    TableUtils.addOrdinal(value);
                },
                immediate: true,
            },
            controlMethod(value) {
                this.updateCountingMethod(value);
            },
        },
    };
</script>

<style lang="scss">
</style>
