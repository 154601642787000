<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="position" :geobjects="positions"
                              v-model:additionalInformation="position" additionalInformationInControl
                              :controls="controls" noLightGreenHeaders>
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition v-model="position" :positions="positions" :field="field" v-model:step="internalStep"/>
        </template>
        <template v-slot:controlData>
            <div v-if="$route.query.controlCode !== 'Bocian'" class="flex gap-3">
                <CustomCalendar class="w-4" label="Data liczenia" v-model="position.controlDate" name="controlDate"
                                required :showErrors="showErrorMessages"
                                :customErrorMessage="getDateErrorMessage(position.controlDate)"
                                :disabled="!editing" />
                <CustomSelectOne label="Siedlisko" name="habitatType"
                                 v-model="position.habitatType" class="w-4" :items="dicts.habitatType"
                                 required :showErrors="showErrorMessages"
                                 :disabled="!editing" />
                <CustomSelectOne v-if="$route.query.controlCode === 'Zuraw'" label="Metoda liczenia" name="controlMethod" :items="dicts.controlMethod" required
                                 v-model="position.controlMethod"
                                 :showErrors="showErrorMessages" :disabled="!editing" class="w-4" />
            </div>
            <div v-if="$route.query.controlCode === 'Bocian'" class="flex gap-3">
                <CustomSelectOne label="Położenie gniazda" name="nestPosition"
                                 v-model="position.habitatType" class="w-4" :items="dicts.habitatType"
                                 required :showErrors="showErrorMessages"
                                 :disabled="!editing" />
            </div>
        </template>
        <template v-if="$route.query.controlCode === 'Bocian'" v-slot:controlMainData="slotProps">
            <div class="flex gap-3">
                <CustomCalendar class="w-6" label="Data kontroli" v-model="slotProps.control.controlDate" name="controlDate"
                                required :customErrorMessage="getDateErrorMessage(slotProps.control.controlDate,slotProps.index)"
                                :disabled="!editing" :showErrors="showErrorMessages" />
                <CustomSelectOne label="Metoda liczenia" name="controlMethod"
                                 v-model="slotProps.control.controlMethod" class="w-6" :items="dicts.controlMethod"
                                 required :showErrors="showErrorMessages"
                                 :disabled="!editing" />
            </div>
        </template>
        <template v-if="$route.query.controlCode === 'Bocian'" v-slot:controlBirdsData="slotProps">
            <div class="flex flex-column">
                <div class="flex gap-3">
                    <CustomSelectOne label="Kryterium lęgowości" name="nestingCriteria"
                                     v-model="slotProps.control.nestingCriteria" class="w-6" :items="computedItems(dicts.nestingCriteria,slotProps.control.id)"
                                     required :showErrors="showErrorMessages"
                                     :disabled="!editing" :itemLabel="getDictLabelWithCodePrefix" />
                    <CustomSelectOne v-if="slotProps.control.id === 2" label="Wynik lęgu" name="breedingResult"
                                     v-model="slotProps.control.breedingResult" class="w-6" :items="dicts.breedingResult"
                                     :showErrors="showErrorMessages"
                                     disabled />
                    <div v-else class="dummy w-6" />
                </div>
            </div>
        </template>
        <template v-if="$route.query.controlCode !== 'Bocian'" v-slot:birdsData>
            <FormBirds v-if="$route.query.controlCode === 'Perkoz'
                || $route.query.controlCode === 'Gawron'" v-model:birds="position.birds" v-model:noBirds="position.noBirds" />
            <FormBirdsMFGP v-else v-model="position" />
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import FormBirds from "@/components/formBirds/FormBirds.vue";
    import FormBirdsMFGP from "./FormBirdsMFGP.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "FormMFGPObservations",

        components: {
            FormObservationsTemplate,
            ObservationsSelectPosition,
            CustomCalendar,
            FormBirds,
            FormBirdsMFGP,
            CustomSelectOne,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
            step: {
                type: Number,
                required: true,
            },
            field: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                positions: [],
                activeIndex: 0,
                index: 0,
                position: _.cloneDeep(EmptyStructures.getEmptyMFGPPosition()),
                control: _.cloneDeep(EmptyStructures.getEmptyMFGPControl()),
                controls: [],
                cloneControls: null,
            };
        },

        emits: ["update:step", "update:form"],

        inject: ["showErrorMessages", "editing", "dicts", "controlTimeFrame"],

        beforeMount() {
            this.positions = this.internalForm.positions.filter((p) => (p.notAudited === null || !p.notAudited));
            if (this.$route.query.controlCode === "Bocian") {
                this.updatePositionAndControls(0);
                const nCCodes = ["HPM1", "HPM2", "HPM3", "HPM4", "HPM5", "HPM6", "HPMX"];
                if (nCCodes.includes(this.controls[1].nestingCriteria?.code)) {
                    this.controls[1].breedingResult = {key: "SUCCESS", code: "SUCCESS", label: "Sukces"};
                } else if ((this.controls[0].nestingCriteria?.code === "WYS" && this.controls[1].nestingCriteria?.code === "HPA")
                    || this.controls[1].nestingCriteria?.code === "HP0") {
                    this.controls[1].breedingResult = {key: "LOSSES", code: "LOSSES", label: "Strata"};
                } else {
                    this.controls[1].breedingResult = {key: "UNKNOWN", code: "UNKNOWN", label: "Nieznany"};
                }
            }
        },

        methods: {
            updatePositionAndControls(newActiveIndex) {
                this.position = this.positions[newActiveIndex];
                if (this.position.control1 != null) {
                    this.controls[0] = {...this.position.control1, id: 1};
                } else {
                    this.controls[0] = {..._.cloneDeep(EmptyStructures.getEmptyMFGPControl()), id: 1};
                }
                if (this.position.control2 != null) {
                    this.controls[1] = {...this.position.control2, id: 2};
                } else {
                    this.controls[1] = {..._.cloneDeep(EmptyStructures.getEmptyMFGPControl()), id: 2};
                }
            },
            computedItems(dict, id) {
                const computedNC = [];
                if (dict && id) {
                    const bocian = id === 1 ? "Bocian1" : "Bocian2";
                    dict.forEach((d) => {
                        d.info?.forEach((inf) => {
                            if (inf.code === bocian) {
                                computedNC.push(d);
                            }
                        });
                    });
                }
                return computedNC;
            },
            getDateErrorMessage(value, i) {
                if (this.$route.query.controlCode !== "Bocian" && !ValidateUtils.validateIsDateInRange(value, this.controlTimeFrame.controlStart, this.controlTimeFrame.controlEnd)) {
                    return "Data musi być z przedziału " + this.controlTimeFrame.controlStart + " - "
                        + this.controlTimeFrame.controlEnd;
                }
                if (this.$route.query.controlCode === "Bocian" && !ValidateUtils.validateIsDateInRange(value, this.dicts.controlType[i]?.controlStart, this.dicts.controlType[i]?.controlEnd)) {
                    return "Data musi być z przedziału " + this.dicts.controlType[i].controlStart + " - "
                        + this.dicts.controlType[i].controlEnd;
                }
                return "";
            },
            getDictLabelWithCodePrefix(item) {
                if (item == null) return "";
                return item.code + " - " + item.label;
            },
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
            activeIndex(value) {
                if (this.$route.query.controlCode === "Bocian") {
                    this.updatePositionAndControls(value);
                }
            },
            "position.positionId"() {
                this.activeIndex = this.positions.indexOf(this.position);
            },
            position: {
                handler(value) {
                    if (!value.birds) {
                        value.birds = [];
                    }
                },
                deep: true,
            },
            controls: {
                handler(value) {
                    if (this.cloneControls && (this.cloneControls[0].nestingCriteria?.code !== value[0].nestingCriteria?.code
                        || this.cloneControls[1].nestingCriteria?.code !== value[1].nestingCriteria?.code)) {
                        const nCCodes = ["HPM1", "HPM2", "HPM3", "HPM4", "HPM5", "HPM6", "HPMX"];
                        if (nCCodes.includes(value[1].nestingCriteria?.code)) {
                            value[1].breedingResult = {key: "SUCCESS", code: "SUCCESS", label: "Sukces"};
                        } else if ((value[0].nestingCriteria?.code === "WYS" && value[1].nestingCriteria?.code === "HPA")
                            || value[1].nestingCriteria?.code === "HP0") {
                            value[1].breedingResult = {key: "LOSSES", code: "LOSSES", label: "Strata"};
                        } else {
                            value[1].breedingResult = {key: "UNKNOWN", code: "UNKNOWN", label: "Nieznany"};
                        }
                    }
                    this.cloneControls = _.cloneDeep(value);
                    this.position.control1 = value[0];
                    this.position.control2 = value[1];
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
