<template>
    <CustomInputText searchIcon class="mt-4 w-3" v-model="textAnywhere" style="min-width: 230px;" />
    <div class="monitoring-program-controls">
        <HeaderComponent headerText="Kontrole" class="my-0" :buttons="buttons" @clickButton="clickButton" />
        <!-- <ControlSearchCriteria v-model="searchCriteria" class="mx-2"/> -->
        <ControlTable ref="controlTable" />
    </div>
</template>

<script>
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import HeaderComponent from "@/components/HeaderComponent.vue";
    // import ControlSearchCriteria
    //     from "@/views/monitoringProgram/components/controls/ControlSearchCriteria.vue";
    import ControlTable from "@/views/monitoringProgram/components/controls/ControlTable.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import {FileUtils} from "@/utils/FileUtils";

    export default {
        name: "MonitoringProgramControls",

        components: {
            ControlTable,
            // ControlSearchCriteria,
            HeaderComponent,
            CustomInputText,
        },

        inject: ["programCode"],

        data() {
            return {
                searchCriteria: this.getClearSearchCriteriaControls(),
                buttons: [
                    {
                        label: "Eksportuj",
                        bgColor: "var(--surface-a)",
                        labelColor: "var(--text-color)",
                        onClick: () => this.exportControls(),
                        show: true, // no condition when can export
                    },
                ],
                textAnywhere: "",
            };
        },

        methods: {
            clickButton(value) {
                if (this.buttons[value].onClick) {
                    this.buttons[value].onClick();
                }
            },
            getClearSearchCriteriaControls() {
                return SearchCriteria.getClearSearchCriteriaControls(this.$route.params.id);
            },
            search(text) {
                this.$refs.controlTable.search(text);
            },
            exportControls() {
                FileUtils.downloadFileUsingGET(
                    `dane_surowe_${this.programCode}_id_${this.$route.params.id}.xlsx`,
                    `/data-raw/export/${this.$route.params.id}`,
                );
            },
        },

        watch: {
            textAnywhere(value) {
                this.search(value);
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../../assets/theme/mytheme/variables";
    .monitoring-program-controls {
        border: 1px solid var(--surface-border);
        // margin-top: 1.5rem;
    }
</style>
