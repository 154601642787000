<template>
    <div v-if="loaded" class="flex flex-column">
        <ProgramDetails :form="formZero" transect />
        <div v-if="mapReloader > 1 && activeTransect?.id !== 'main2'"
             class="mt-5 flex align-items-center justify-content-between">
            <CustomSelectOne label="Liczba odcinków" :items="allNumberOfTransects" :showClear="false"
                             v-model="numberOfTransects" class="w-2 mb-0"
                             name="numberOfTransects" itemLabel="" />
            <div class="flex gap-2">
                <CustomIconButton icon="pi pi-chevron-left" label="Poprzedni" rowReverse @click="goPrevious" />
                <CustomIconButton icon="pi pi-chevron-right" label="Następny" @click="goNext" />
            </div>
        </div>
        <div v-if="transects.length > 0 && mapReloader > 1" class="flex">
            <SelectTransectLine v-for="(segments,index) in segmentsInTransects" :key="index"
                                :transects="segments" :transectNumber="index + 1"
                                @onClicked="(c) => onClickedOrHover(c,index,'clicked')"
                                @onHover="(h) => onClickedOrHover(h,index,'hover')" class="w-6" />
        </div>
        <div class="flex mb-4 gap-8">
            <div style="height: 600px;" class="w-7">
                <MapTransZeroWorkSpace mapId="transectsMap" height="600px" :field="field" popup
                                       @onClick="onClick" @onHover="onHover"
                                       editable v-model:activeTransect="activeTransect"
                                       :transects="coloredTransects"
                                       v-model:transectsParts="transectsParts"
                                       @changeTransect="changeTransect" :key="mapReloader" :isOn="false"
                                       :mapReloader="mapReloader" @getLineIndex="getLineIndex"
                                       v-model:connect="connect" @checkTransectsToConnect="checkTransectsToConnect"
                                       @clearSecondMainTransectLine="clearSecondMainTransectLine">
                    <template v-slot:popup="slotProps">
                        <div class="marker-popup-header">
                            {{ slotProps.transect.name }}
                        </div>
                    </template>
                </MapTransZeroWorkSpace>
            </div>
            <div class="w-5">
                <div class="mb-4">Kolejne punkty wyznaczające {{ activeTransect.id === "main1"
                    || activeTransect.id === "main2" ? "transekt" : "odcinek" }}:</div>
                <div v-if="activeTransect" class="transect-points-list">
                    <div v-for="(line,index) in editableLineArray" :key="index"
                         class="flex gap-3 align-items-center">
                        <div class="mb-3 w-1">{{index + 1}}.</div>
                        <CustomInputNumber label="Koordynaty N" v-model="line.latitude"
                                           @update:modelValue="(v) => updateTransect(v,index,'latitude')"
                                           :maxDigits="6" name="latitude" :showLabel="true"
                                           :showErrors="showErrorMessages" required />
                        <CustomInputNumber label="Koordynaty E" v-model="line.longitude"
                                           @update:modelValue="(v) => updateTransect(v,index,'longitude')"
                                           :maxDigits="6" name="longitude" :showLabel="true"
                                           :showErrors="showErrorMessages" required />
                        <div class="flex flex-column mb-3 pr-0 align-items-center">
                            <CustomIconButton icon="pi pi-chevron-up" small @click="moveBefore(index)" />
                            <CustomIconButton icon="pi pi-chevron-down" small @click="moveAfter(index)" />
                        </div>
                        <div class="flex flex-column mb-3 pl-0 align-items-center">
                            <CustomIconButton icon="pi pi-times" small @click="remove(index)" />
                        </div>
                    </div>
                </div>
                <div v-if="activeTransect" class="mt-4">
                    <CustomButton label="Dodaj punkt" class="justify-content-center" bgColor="#33795B"
                                  color="white" @click="add()" />
                </div>
                <div v-if="showErrorMessages && editableLineArray.length < 2 < 2" class="mt-3 error-info">
                    Transekt musi być wyznaczony przez co najmniej dwa punkty
                </div>
            </div>
        </div>
        <div class="flex justify-content-end flex-wrap gap-3">
            <!-- <CustomButton v-if="canApprove" class="form-button approve-button" label="Zatwierdź"
                          bgColor="var(--secondary-color)" color="var(--surface-a)" @click="approve" />
            <CustomButton v-if="canReject" class="form-button reject-button" label="Odrzuć"
                          bgColor="rgba(193, 197, 204, 0.4)" color="#EA3030" />
            <CustomButton v-if="canSendToVerification" class="form-button send-to-verification-button w-auto"
                          label="Wyślij do weryfikacji" bgColor="rgba(193, 197, 204, 0.4)" /> -->
            <CustomButton v-if="canSave" class="form-button save-button" label="Zapisz"
                          bgColor="rgba(193, 197, 204, 0.4)" @click="save" />
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    import _ from "lodash";
    import {computed} from "vue";
    import MapTransZeroWorkSpace from "../../components/map/MapTransZeroWorkSpace.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomIconButton from "@/components/form/CustomIconButton.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import ProgramDetails from "@/components/formSharedComponents/ProgramDetails.vue";
    import SelectTransectLine from "@/components/transectForm/selectTransect/SelectTransectLine.vue";
    import {SystemRole} from "@/utils/SystemRole";
    import {
        // getTransectZero,
        editTransectZero,
        approveFormTransectZero,
    } from "@/swagger/vue-api-client";

    export default {
        name: "TransZeroWorkSpace",

        components: {
            CustomButton,
            CustomInputNumber,
            CustomIconButton,
            ProgramDetails,
            SelectTransectLine,
            MapTransZeroWorkSpace,
            CustomSelectOne,
        },

        data() {
            return {
                loaded: false,
                editing: true,
                showErrorMessages: false,
                formZero: {},
                editableLineArray: [],
                field: [],
                deleteCircle: null,
                transects: [],
                transectsParts: [],
                transect: null,
                activeIndex: null,
                previousHover: null,
                previousClicked: null,
                color: "grey",
                colorDisabled: "lightGrey",
                colorClickedOrHover: "red",
                coloredTransects: [],
                hoverIndex: null,
                clickedIndex: null,
                numberOfTransects: null,
                allNumberOfTransects: [],
                activeTransectDistance: 0,
                activeTransect: null,
                mainTransect1: {
                    id: "main1",
                    line: [],
                    name: "tr1",
                    color: "green",
                },
                mainTransect2: {
                    id: "main2",
                    line: [],
                    name: "tr2",
                    color: "green",
                },
                mapReloader: 1,
                oldNumberOfTransects: null,
                connect: false,
                lineIndex: null,
                arr: [],
            };
        },

        provide() {
            return {
                programCode: computed(() => this.formZero.programName),
            };
        },

        beforeMount() {
            this.formZero = {
                programEditionId: 8,
                programName: "MPPL",
                monitoringYear: 2024,
                editable: false,
                observer: "A. Sykała",
                state: "DRAFT",
                field: {
                    id: 2195,
                    program: "MPPL",
                    name: "SE146",
                    fieldType: "FIELD",
                    superField: null,
                    shape: [
                        {
                            longitude: 21.317405446105294,
                            latitude: 49.74802400632832,
                        },
                        {
                            longitude: 21.331280592827603,
                            latitude: 49.74798517448995,
                        },
                        {
                            longitude: 21.33134176053963,
                            latitude: 49.75697579752776,
                        },
                        {
                            longitude: 21.317464161862627,
                            latitude: 49.757014671159716,
                        },
                        {
                            longitude: 21.317405446105294,
                            latitude: 49.74802400632832,
                        },
                    ],
                },
                id: 15,
                suggestedNumberOfTransects: 10,
                formTransectZeros: [],
            };
            this.numberOfTransects = this.formZero.suggestedNumberOfTransects;
            for (let i = 0; i < this.formZero.suggestedNumberOfTransects; i += 1) {
                this.allNumberOfTransects.push(i + 1);
            }
            this.field = this.formZero.field.shape;
            this.activeTransect = this.mainTransect1;
            this.loaded = true;
            // getTransectZero({formId: this.$route.params.id})
            //     .then((response) => {
            //         this.formZero = response.data;
            //         this.numberOfTransects = this.formZero.suggestedNumberOfTransects;
            //         for (let i = 0; i < this.formZero.suggestedNumberOfTransects; i += 1) {
            //             this.allNumberOfTransects.push(i + 1);
            //         }
            //         this.field = this.formZero.field.shape;
            //         this.loaded = true;
            //     })
            //     .catch((err) => {
            //         console.log(err);
            //         this.loaded = false;
            //     });
            this.previousHover = this.hoverIndex;
            this.previousClicked = this.clickedIndex;
        },

        methods: {
            clearSecondMainTransectLine() {
                this.transects.forEach((t) => {
                    if (t.id === "main2") {
                        t.line = [];
                    }
                });
            },
            getLineIndex(value) {
                this.lineIndex = value;
            },
            makeColoredTransects() {
                if (this.formZero.formTransectZeros.length > 0) {
                    this.transects = this.formZero.formTransectZeros;
                    // this.transects = _.cloneDeep(this.formZero.formTransectZeros);
                } else if (this.transects.length < 1 && this.transectsParts.length === 0) {
                    if (this.formZero.suggestedNumberOfTransects > 5) {
                        this.transects.push(this.mainTransect1, this.mainTransect2);
                    } else {
                        this.transects.push(this.mainTransect1);
                    }
                }
                this.coloredTransects = _.cloneDeep(this.transects);
                this.coloredTransects.forEach((t, i) => {
                    if (t.notAudited === true) {
                        t.color = this.colorDisabled;
                        t.openPopup = false;
                        t.zIndexOffset = -1;
                    } else if (i === this.clickedIndex || i === this.hoverIndex) {
                        t.color = this.colorClickedOrHover;
                        t.openPopup = true;
                        t.zIndexOffset = 1000;
                    } else {
                        t.color = this.color;
                        t.openPopup = false;
                        t.zIndexOffset = 1;
                    }
                });
            },
            moveBefore(index) {
                if (index > 0) {
                    const segment = this.activeTransect.line[index];
                    this.activeTransect.line.splice(index, 1);
                    this.activeTransect.line.splice(index - 1, 0, segment);
                }
            },
            moveAfter(index) {
                if (index < this.activeTransect.line.length - 1) {
                    const segment = this.activeTransect.line[index];
                    this.activeTransect.line.splice(index, 1);
                    this.activeTransect.line.splice(index + 1, 0, segment);
                }
            },
            remove(index) {
                this.deleteCircle = [this.activeTransect.line[index], index];
                this.editableLineArray.splice(index, 1);
                this.activeTransect.line.splice(index, 1);
            },
            add() {
                if (this.activeTransect.line.length === 0
                    && this.formZero.formTransectZeros[this.activeIndex - 1]?.line.length > 0) {
                    const lastPoint = _.cloneDeep(
                        this.formZero.formTransectZeros[this.activeIndex - 1].line[
                            this.formZero.formTransectZeros[this.activeIndex - 1].line.length - 1
                        ],
                    );
                    this.activeTransect.line.push(lastPoint);
                } else if (this.activeTransect.line.length > 0
                    && this.activeTransect.line[0].latitude != null
                    && this.activeTransect.line[0].longitude != null) {
                    const lastPoint = _.cloneDeep(this.activeTransect.line[this.editableLineArray.length - 1]);
                    lastPoint.latitude += 0.001;
                    lastPoint.longitude += 0.001;
                    this.activeTransect.line.push(lastPoint);
                } else if (this.field.length > 0) {
                    this.activeTransect.line = [];
                    const pointFromField = this.field[0];
                    this.activeTransect.line.push(_.cloneDeep(pointFromField));
                } else {
                    this.activeTransect.line = [];
                    this.activeTransect.line.push(this.polandCenter);
                }
            },
            getRequiredMessage(value) {
                if (this.showErrorMessages
                    && (value == null || value === "")) return this.$t("message.validations.required");
                return "";
            },
            updateTransect(value, index, type) {
                if (this.activeTransect.line[index] == null) {
                    this.activeTransect.line[index] = {longitude: null, latitude: null};
                }
                if (value != null && value !== "" && value !== 0) {
                    if (type === "longitude") {
                        this.activeTransect.line[index].longitude = value;
                    }
                    if (type === "latitude") {
                        this.activeTransect.line[index].latitude = value;
                    }
                }
            },
            validate() {
                let isCorrect = true;
                this.editableLineArray.forEach((l) => {
                    if (l.latitude === null || l.longitude === null) isCorrect = false;
                });
                if (this.showYear && this.activeTransect.year === null) isCorrect = false;
                if (this.isNewHistoryTransect && this.editableLineArray.length < 2) isCorrect = false;
                return isCorrect;
            },
            goNext() {
                if (this.clickedIndex < 9) {
                    this.clickedIndex += 1;
                    this.updateClickedOrHover(this.clickedIndex, "clicked");
                }
            },
            goPrevious() {
                if (this.clickedIndex > 0) {
                    this.clickedIndex -= 1;
                    this.updateClickedOrHover(this.clickedIndex, "clicked");
                }
            },
            onClickedOrHover(segmentIndex, transectIndex, actionField) {
                // actionField is "clicked" or "hover"
                let counter = 0;
                this.segmentsInTransects.forEach((segments, ti) => {
                    if (segmentIndex != null && ti === transectIndex) {
                        counter += segmentIndex;
                        if (actionField === "clicked") this.clickedIndex = counter;
                        else if (actionField === "hover") this.hoverIndex = counter;
                        segments.forEach((s, si) => { s[actionField] = (si === segmentIndex); });
                    } else {
                        counter += segments.length;
                        segments.forEach((s) => { s[actionField] = false; });
                    }
                });
                if (segmentIndex == null) {
                    if (actionField === "clicked") this.clickedIndex = null;
                    else if (actionField === "hover") this.hoverIndex = null;
                }
            },
            updateClickedOrHover(index, actionField) {
                const counter = index;
                this.segmentsInTransects.forEach((segments, si) => {
                    if (segments.length > 1) {
                        if (index == null || counter >= segments.length) {
                            segments.forEach((s) => { s[actionField] = false; });
                            const secondCounter = counter - segments.length;
                            if (si === 1) {
                                segments.forEach((s, i) => { s[actionField] = (i === secondCounter); });
                            }
                        } else if (index == null || counter < segments.length) {
                            segments.forEach((s) => { s[actionField] = false; });
                            if (si === 0) {
                                segments.forEach((s, i) => { s[actionField] = (i === counter); });
                            }
                        }
                    }
                });
            },
            onClick(index) {
                if (index == null || !(this.transects[index].notAudited === true)) {
                    this.clickedIndex = index;
                    this.updateClickedOrHover(index, "clicked");
                }
            },
            onHover(index) {
                if (index == null || !(this.transects[index].notAudited === true)) {
                    this.hoverIndex = index;
                    this.updateClickedOrHover(index, "hover");
                }
            },
            markAsHoverOrClicked(index) {
                if (this.coloredTransects[index]) {
                    this.coloredTransects[index].color = this.colorClickedOrHover;
                    this.coloredTransects[index].openPopup = true;
                    this.coloredTransects[index].zIndexOffset = 1000;
                }
            },
            markAsNormal(index) {
                if (this.coloredTransects[index]) {
                    this.coloredTransects[index].color = this.color;
                    this.coloredTransects[index].openPopup = false;
                    this.coloredTransects[index].zIndexOffset = 1;
                }
            },
            save() {
                const request = [];
                this.formZero.formTransectZeros.forEach((t) => {
                    if (t.id !== "main1" && t.id !== "main2") {
                        request.push({
                            name: t.name,
                            line: t.line,
                        });
                    }
                });
                // editTransectZero({formId: this.$route.params.id, body: request})
                // .catch((err) => {
                //     console.log(err);
                // });
                console.log(request);
            },
            approve() {
                approveFormTransectZero({formId: this.$route.params.id})
                    .catch((err) => {
                        console.log(err);
                    });
            },
            changeTransect() {
                if (this.activeTransect.id === "main2") {
                    this.formZero.formTransectZeros = _.cloneDeep(this.transectsParts);
                    this.activeTransect = this.formZero.formTransectZeros[0];
                    this.activeIndex = 0;
                    this.mainTransect1 = {
                        id: "main1",
                        line: [],
                        name: "tr1",
                        color: "green",
                    };
                    this.mainTransect2 = {
                        id: "main2",
                        line: [],
                        name: "tr2",
                        color: "green",
                    };
                    this.mapReloader += 1;
                }
                if (this.activeTransect.id === "main1") {
                    this.formZero.formTransectZeros = _.cloneDeep([...this.transectsParts, this.mainTransect2]);
                    this.activeTransect = this.formZero.formTransectZeros[5];
                    this.activeIndex = 5;
                    this.mapReloader += 1;
                }
            },
            checkTransectsToConnect(value) {
                this.arr = [];
                if (value) {
                    for (let i = 0; i < this.transects.length; i += 1) {
                        for (let j = i + 1; j < this.transects.length; j += 1) {
                            this.transects[i].line.forEach((l) => {
                                this.transects[j].line.forEach((ll) => {
                                    if (_.isEqual(l, ll)) {
                                        this.arr.push([i , j]);
                                    }
                                });
                            });
                        }
                    }
                }
            },
        },

        computed: {
            segmentsInTransects() {
                const first5 = this.transects.filter((t, i) => i <= 4);
                const second5 = this.transects.filter((t, i) => i > 4 && i <= 9);
                if (second5.length > 0) return [first5, second5];
                return [first5];
            },
            isCoordinator() {
                return SystemRole.isMainCoordinator() || SystemRole.isRegionalCoordinator()
                    || SystemRole.isNationalCoordinator();
            },
            canApprove() {
                return this.isCoordinator && (this.editing || this.formZero.state === "SENT_TO_VERIFICATION");
            },
            canReject() {
                return this.isCoordinator && this.formZero.state === "SENT_TO_VERIFICATION";
            },
            canSendToVerification() {
                return SystemRole.isObserver() && this.formZero.state === "DRAFT";
            },
            canSave() {
                return this.editing && this.formZero.state !== "APPROVED";
            },
        },

        watch: {
            connect(value) {
                this.checkTransectsToConnect(value)
            },
            transects: {
                handler() {
                    if (this.connect) {
                        this.arr.forEach((a) => {
                            a.forEach((e) => {
                                if (e === this.activeIndex) {
                                    if (this.lineIndex === 0) {
                                        this.transects[a[0]].line[this.transects[a[0]].line.length - 1] = this.transects[a[1]].line[0];
                                    } else if (this.lineIndex > 0) {
                                        this.transects[a[1]].line[0] = this.transects[a[0]].line[this.transects[a[0]].line.length - 1];
                                    }
                                }
                            });
                        });
                    }
                },
                deep: true,
            },
            hoverIndex(value) {
                if (this.previousHover !== null && this.previousHover !== this.clickedIndex) {
                    this.markAsNormal(this.previousHover);
                }
                if (value !== null) {
                    this.markAsHoverOrClicked(value);
                    this.previousHover = value;
                }
            },
            clickedIndex(value) {
                if (this.previousClicked !== null && this.previousClicked !== this.hoverIndex) {
                    this.markAsNormal(this.previousClicked);
                }
                if (value !== null) {
                    this.markAsHoverOrClicked(value);
                    this.previousClicked = value;
                }
                this.activeIndex = value;
            },
            activeIndex(value) {
                this.activeTransect = this.formZero.formTransectZeros[value];
            },
            activeTransect: {
                handler(value) {
                    this.editableLineArray = [];
                    if (value) {
                        value.line.forEach((l, i) => {
                            if (this.editableLineArray.length - 1 < i) {
                                this.editableLineArray.push({...l});
                            } else {
                                const editableElem = this.editableLineArray[i];
                                if (l.latitude !== editableElem.latitude) editableElem.latitude = l.latitude;
                                if (l.longitude !== editableElem.longitude) editableElem.longitude = l.longitude;
                            }
                        });
                        this.makeColoredTransects();
                    }
                },
                deep: true,
            },
            mapReloader(value) {
                if (value > 1) {
                    this.clickedIndex = 0;
                    this.onClickedOrHover(0, 0, "clicked");
                }
            },
            numberOfTransects(value) {
                if (this.mapReloader > 1) {
                    this.clickedIndex = 0;
                    const length = this.formZero.formTransectZeros.length;
                    if (value < this.oldNumberOfTransects) {
                        this.formZero?.formTransectZeros?.splice(value, this.oldNumberOfTransects - value);
                    } else if (value > this.oldNumberOfTransects) {
                        for (let i = 0; i < value - this.oldNumberOfTransects; i += 1) {
                            if (length < value) {
                                /* eslint-disable */
                                this.formZero.formTransectZeros.push({
                                    id: this.formZero.formTransectZeros[length - 1]?.id ? this.formZero.formTransectZeros[length - 1]?.id + i + 1 : i + 1,
                                    name: `${Number(this.formZero.formTransectZeros[length - 1]?.name) ? Number(this.formZero.formTransectZeros[length - 1]?.name)  + i + 1 : i + 1}`,
                                    line: [],
                                });
                                /* eslint-enable */
                            }
                        }
                    } else {
                        for (let i = 0; i < value; i += 1) {
                            if (length < value) {
                                /* eslint-disable */
                                this.formZero.formTransectZeros.push({
                                    id: this.formZero.formTransectZeros[length - 1]?.id ? this.formZero.formTransectZeros[length - 1]?.id + i + 1 : i + 1,
                                    name: `${Number(this.formZero.formTransectZeros[length - 1]?.name) ? Number(this.formZero.formTransectZeros[length - 1]?.name)  + i + 1 : i + 1}`,
                                    line: [],
                                });
                                /* eslint-enable */
                            }
                        }
                    }
                    this.makeColoredTransects();
                    this.onClickedOrHover(0, 0, "clicked");
                }
                this.oldNumberOfTransects = value;
            },
        },
    };
</script>

<style lang="scss">
    @import "../../assets/theme/mytheme/variables";
    .transect-points-list {
        max-height: 500px;
        overflow-y: auto;
    }
</style>
