<template>
    <CustomCheckbox label="Brak ptaków" v-model="control.noBirds" :disabled="!editing" name="noBirds" class="pb-3"/>
    <div class="flex gap-3">
        <CustomSelectOne v-if="!control.noBirds" label="Metoda oceny liczebności samców" :items="dicts.countingMethod"
                         name="countingMethod" class="w-3 pr-2" v-model="control.countingMethod"
                         required :showErrors="showError()" :disabled="!editing"/>
        <div class="dummy w-9"></div>
    </div>
    <div v-if="!control.noBirds" class="flex flex-column">
        <div class="flex gap-3">
            <CustomTimePicker label="Godzina obserwacji" name="observationTime" v-model="control.observationTime"
                              required :showErrors="showError()" class="w-3" :disabled="!editing"/>
            <CustomInputNumber label="L. głosów samców w I liczeniu" name="count1"
                               v-model="control.count1" :required="countVoices" :disabled="!countVoices || !editing"
                               :showErrors="countVoices && showError()" class="w-3" />
            <CustomInputNumber label="L. głosów samców w II liczeniu" name="count2"
                               v-model="control.count2" :required="countVoices" :disabled="!countVoices || !editing"
                               :showErrors="countVoices && showError()" class="w-3" />
            <CustomInputNumber label="L. głosów samców w III liczeniu" name="count3"
                               v-model="control.count3" :required="countVoices" :disabled="!countVoices || !editing"
                               :showErrors="countVoices && showError()" class="w-3" />
        </div>
        <div class="flex gap-3">
            <CustomInputNumber label="Min." name="minimum" v-model="control.minimum" required
                               :showErrors="showError()" class="w-4" :disabled="!editing"/>
            <CustomInputNumber label="Maks." name="maximum" v-model="control.maximum" required
                               :showErrors="showError()" class="w-4" :disabled="!editing"/>
            <CustomSelectOne label="Aktywność" name="activity" v-model="control.activity"
                             :items="dicts.activity" :required="countVoices"
                             :disabled="!countVoices || !editing" :showErrors="countVoices && showError()" class="w-4" />
        </div>
    </div>
</template>

<script>
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomTimePicker from "@/components/form/CustomTimePicker";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";

    export default {
        name: "FormBirdsMDU",

        components: {
            CustomCheckbox,
            CustomTimePicker,
            CustomInputNumber,
            CustomSelectOne,
        },

        props: {
            modelValue: null,
        },

        inject: ["editing", "showErrorMessages", "dicts"],

        emits: ["update:modelValue"],

        methods: {
            showError() {
                return this.showErrorMessages && this.control.noBirds !== true;
            },
        },

        computed: {
            control: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            countVoices() {
                return this.control?.countingMethod?.code === "1";
            },
        },

        watch: {
            internalBirds: {
                handler(value) { this.$emit("update:birds", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
