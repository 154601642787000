<template>
    <div class="px-3">
        <NewPositionDialog v-if="editing" v-model="showNew" @add="addPosition" :field="field"
                           @similarPosition="similarPosition" :programCode="programCode"
                           @similarPositionObject="similarPositionObject" @newPositionObject="newPositionObject"
                           :allPositions="positions" />
        <ArchivePositionsDialog v-if="editing && dynamicPositions" v-model="showArchive"
                                :field="field" :programCode="programCode" />
        <SimilarPositionDialog :showSimilar="showSimilar" @hideSimilar="hideSimilar" :newAddedPos="newAddedPos"
                               :similarPos="similarPos" :fieldID="field.id" @add="addPosition"
                               :programCode="programCode" />
        <PositionWithoutCoordinatesListDialog v-if="showEditList" v-model:visible="showEditList"
                                              :positionsWithoutCoordinatesList="positionsWithoutCoordinatesList"
                                              @posWithoutCoor="goToEdit" />
        <EditPositionDialog v-if="showEdit" v-model:visible="showEdit" :field="field"
                            :positionWithoutCoordinates="positionWithoutCoordinates"
                            :objectBeingChanged="objectBeingChanged"
                            :programCode="programCode" @positionEdited="updatePosition"
                            :dynamicPositions="dynamicPositions"/>
        <RejectChangesDialog v-model="showRejectChangesDialog" @approve="() => revertChanges()" />
        <div class="flex gap-2">
            <ChangeCoordinatesInfo class="flex flex-1" :objectBeingChanged="objectBeingChanged"/>
            <CustomWarningBox v-if="showAlert && isCoordinator" :text="text" class="w-6" />
        </div>
        <HeaderComponent headerText="Mapa powierzchni" :uppercase="false" class="mt-5" />
        <PositionsMap v-model="positions" :field="field.superField ? field.superField.shape : field.shape"
                      :subfield="field.superField ? field.shape : []" :disabled="!editing" :objectBeingChanged="objectBeingChanged"
                      :programCode="programCode" :onlyStepOne="onlyStepOne" :dynamicPositions="dynamicPositions"/>
        <div v-if="editing && !onlyStepOne" class="flex flex-column">
            <div class="flex justify-content-between mb-2">
                <div v-if="dynamicPositions" class="flex gap-4">
                    <CustomButton icon="pi pi-clone" label="Dodaj nowe stanowisko archiwalne"
                                  rowReverse bgColor="var(--secondary-color)" color="var(--surface-a)"
                                  @click="showArchive = true" />
                    <CustomButton label="Dodaj nowe stanowisko" icon="pi pi-plus" rowReverse
                                  bgColor="var(--secondary-color)" color="var(--surface-a)" @click="showNew = true" />
                </div>
                <div>
                    <CustomButton label="Cofnij zmiany na mapie" icon="pi pi-undo" rowReverse color="var(--surface-a)"
                                  bgColor="var(--secondary-color)" @click="showRejectChangesDialog = true" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        revertAllGeographicalHistoryInForm,
        revertAllPositions,
    } from "@/swagger/vue-api-client";
    import NewPositionDialog from "./dialogs/NewPositionDialog.vue";
    import ArchivePositionsDialog from "./dialogs/ArchivePositionsDialog.vue";
    import SimilarPositionDialog from "./dialogs/SimilarPositionDialog.vue";
    import EditPositionDialog from "./dialogs/EditPositionDialog.vue";
    import HeaderComponent from "@/components/HeaderComponent";
    import CustomButton from "@/components/form/CustomButton.vue";
    import PositionsMap from "./PositionsMap.vue";
    import RejectChangesDialog from "./dialogs/RejectChangesDialog.vue";
    import ChangeCoordinatesInfo from "@/components/formSharedComponents/ChangeCoordinatesInfo.vue";
    import CustomWarningBox from "@/components/form/CustomWarningBox.vue";
    import {SystemRole} from "@/utils/SystemRole";
    import PositionWithoutCoordinatesListDialog from "./dialogs/PositionWithoutCoordinatesListDialog.vue";

    export default {
        name: "FormPositions",

        components: {
            ChangeCoordinatesInfo,
            PositionsMap,
            CustomButton,
            HeaderComponent,
            EditPositionDialog,
            SimilarPositionDialog,
            ArchivePositionsDialog,
            NewPositionDialog,
            RejectChangesDialog,
            CustomWarningBox,
            PositionWithoutCoordinatesListDialog,
        },

        props: {
            modelValue: {
                type: Array,
                required: true,
            },
            field: {
                type: Object,
                required: true,
            },
            onlyStepOne: {
                type: Boolean,
                default: false,
            },
            dynamicPositions: {
                type: Boolean,
                default: false,
            },
            objectBeingChanged: {
                type: String,
                default: "stanowisko",
            },
        },

        inject: ["editing", "programCode"],

        data() {
            return {
                showArchive: false,
                showSimilar: false,
                showNew: false,
                showEdit: false,
                showEditList: false,
                showRejectChangesDialog: false,
                positionWithoutCoordinates: null,
                editIndex: null,
                newAddedPos: null,
                similarPos: null,
                showAlert: false,
                positionsWithoutCoordinatesList: [],
            };
        },

        emits: ["update:modelValue", "reloadFormData"],

        mounted() {
            if (this.editing) {
                this.checkIfPositionWithoutCoordinates();
            }
        },

        methods: {
            hideSimilar() {
                setTimeout(() => {
                    this.showSimilar = false;
                }, 100);
            },
            addPosition(value) {
                this.positions.push(value);
                console.log("add", value);
            },
            checkIfPositionWithoutCoordinates() {
                this.positionsWithoutCoordinatesList = [];
                for (let i = 0; i < this.positions.length; i += 1) {
                    if (this.positions[i].coordinates == null) {
                        this.positionsWithoutCoordinatesList.push(this.positions[i]);
                    }
                }
                if (this.positionsWithoutCoordinatesList.length > 0) {
                    this.showEditList = true;
                }
                if (this.positionsWithoutCoordinatesList.length === 0) {
                    this.showEditList = false;
                }
                // for (let i = 0; i < this.positions.length; i += 1) {
                //     if (this.positions[i].coordinates == null) {
                //         this.positionWithoutCoordinates = this.positions[i];
                //         this.editIndex = i;
                //         this.showEdit = true;
                //         return;
                //     }
                // }
            },
            goToEdit(value) {
                this.positions.forEach((pos, i) => {
                    if (pos.name === value.name) {
                        this.editIndex = i;
                    }
                });
                this.positionWithoutCoordinates = value;
                this.showEdit = true;
            },
            similarPosition(value) {
                this.showSimilar = value;
            },
            similarPositionObject(value) {
                this.similarPos = value;
            },
            newPositionObject(value) {
                this.newAddedPos = value;
            },
            revertChanges() {
                let revertAllApiMethod = revertAllGeographicalHistoryInForm;
                if (this.dynamicPositions) revertAllApiMethod = revertAllPositions;
                revertAllApiMethod({
                    programCode: this.programCode,
                    formId: this.$route.params.id,
                }).then(() => {
                    this.$emit("reloadFormData");
                });
            },
            updatePosition(data) {
                this.positions[this.editIndex].coordinates = data.coordinates;
                this.positions[this.editIndex].accepted = data.accepted;
                this.positions[this.editIndex].status = data.status;
            },
            checkIfNotAcceptedPosition(positions) {
                positions.forEach((pos) => {
                    if (!pos.accepted) {
                        this.showAlert = true;
                        return;
                    }
                    this.showAlert = false;
                });
            },
        },

        computed: {
            positions: {
                get() { this.checkIfNotAcceptedPosition(this.modelValue); return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            text() {
                return `Nastąpiła zmiana lokalizacji
                    ${this.objectBeingChanged === "stanowisko" ? "stanowisk" : ""}
                    ${this.objectBeingChanged === "punkt" ? "punktów" : ""}`;
            },
            isCoordinator() {
                return SystemRole.isMainCoordinator() || SystemRole.isRegionalCoordinator()
                    || SystemRole.isNationalCoordinator();
            },
        },

        watch: {
            positions: {
                handler(value) {
                    this.checkIfNotAcceptedPosition(value);
                    this.$emit("update:modelValue", value);
                },
                deep: true,
            },
            showEdit(value) {
                if (value === false) {
                    this.positionWithoutCoordinates = null;
                    this.editIndex = null;
                    this.checkIfPositionWithoutCoordinates();
                }
            },
            editing(value) {
                if (value) {
                    this.checkIfPositionWithoutCoordinates();
                }
            },
        },
    };
</script>

<style lang="scss">
</style>
