<template>
    <div v-if="!isAdmin" class="flex flex-1 flex-wrap add-in-table-header flex-column gap-3" v-focustrap>
        <div class="flex flex-1 gap-2 flex-wrap align-items-center">
            <CustomButton icon="pi pi-copy" style="margin-bottom: 1rem"
                          title="Skopiuj ostatnio dodany gatunek"
                          :disabled="!editing" @click="copyLastSpecies"
                          v-on:keydown.enter="copyLastSpecies" />
            <div class="w-3">
                <CustomSelectOne label="Gatunek" name="species" v-model="newItem.species" required
                                 :showErrors="showErrors" :disabled="!editing"
                                 :items="dicts.programBirds" itemLabel="name" />
            </div>
            <div class="w-1">
                <CustomCheckbox label="Nie liczono" name="notCounted" v-model="newItem.notCounted"
                                :disabled="!editing || haveToCountThisSpecies" />
            </div>
            <div class="w-2">
                <CustomInputNumber label="L. osobników" name="count" v-model="newItem.count"
                                   required :showErrors="showErrors"
                                   :disabled="!editing || newItem.notCounted" />
            </div>
            <CustomButton v-if="editing" icon="pi pi-plus" label="Dodaj" @click="add" :error="error"
                          style="position: absolute; right: 14px; margin-bottom: 1rem" v-on:keydown.tab="add"/>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import FocusTrap from "primevue/focustrap";
    import {SystemRole} from "@/utils/SystemRole";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "AddBirdsMGZ",

        components: {
            CustomCheckbox,
            CustomSelectOne,
            CustomInputNumber,
            CustomButton,
        },

        directives: {
            focustrap: FocusTrap,
        },

        props: {
            modelValue: {
                type: Object,
            },
            error: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["editing", "dicts"],

        data() {
            return {
                newItem: _.cloneDeep(EmptyStructures.getEmptyBirds()),
                showErrors: false,
                haveToCountThisSpecies: false,
            };
        },

        methods: {
            add() {
                if (this.newItem.species == null || this.newItem.species === ""
                    || (this.haveToCountThisSpecies && this.newItem.notCounted === true)
                    || (this.haveToCountThisSpecies && (this.newItem.count == null || this.newItem.count === ""))
                    || (this.newItem.notCounted === false
                        && (this.newItem.count == null || this.newItem.count === ""))) {
                    this.showErrors = true;
                } else {
                    this.birds.push({
                        ...this.newItem,
                        ordinal: this.birds.length + 1,
                    });
                    this.newItem = _.cloneDeep(EmptyStructures.getEmptyBirds());
                    this.showErrors = false;
                }
            },
            copyLastSpecies() {
                if (this.birds?.length > 0) {
                    this.newItem.species = this.birds[this.birds.length - 1].species;
                }
            },
        },

        computed: {
            birds: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            isAdmin() {
                return SystemRole.isAdmin();
            },
        },

        watch: {
            birds: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
            "newItem.species"(value) {
                this.haveToCountThisSpecies = value != null && value.required === true;
                if (this.haveToCountThisSpecies) this.newItem.notCounted = false;
            },
            "newItem.notCounted"(value) {
                if (value === true) this.newItem.count = null;
            },
        },
    };
</script>

<style lang="scss">
.add-in-table-header {
    margin-bottom: -1rem,
}
</style>
