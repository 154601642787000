<template>
    <DataTable v-if="searchCriteria" :value="trends" dataKey="id" lazy :totalRecords="totalRecords"
               :loading="loading" :search-criteria="searchCriteria"
               v-model:editingRows="editingRows" editMode="row"
               @row-edit-save="onRowEditSave"
               @page="onPage" @sort="onPage" removableSort size="small"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink
               LastPageLink CurrentPageReport RowsPerPageDropdown"
               currentPageReportTemplate="Trendów na stronie" paginator
               :rows="10" :rowsPerPageOptions="[5,10,20,50]"
               v-model:rows="page.limit" v-model:first="page.offset" v-model:filters="searchCriteria"
               filter-display="menu" @update:filters="search">
        <template #header>
            <AddTrend :programs="programs" :mockData="mockData" @refreshTable="search" />
        </template>
        <Column header="Program" sortable field="program" :showFilterMatchModes="false"
                filter-menu-class="w-16rem" filterField="programName">
            <template #filter="{filterModel}">
                <CustomSelectOne name="program" v-model="filterModel.value"
                                 :items="programs" class="flex-1 w-0 mt-3" :empty-value="false" />
            </template>
            <template #filterclear="{ filterCallback }">
                <FilterClear :callback="filterCallback" />
            </template>
            <template #filterapply="{ filterCallback }">
                <FilterApply :callback="filterCallback" />
            </template>
            <template #editor="{data,field}">
                <CustomSelectOne name="program" :items="programs"
                                 v-model="data[field]" />
            </template>
        </Column>
        <Column header="Gatunek" sortable field="species" :showFilterMatchModes="false"
                filter-menu-class="w-16rem" filterField="speciesName">
            <template #filter="{filterModel}">
                <CustomSelectOne name="species" v-model="filterModel.value"
                                 :items="mockData.species" itemLabel="name" itemValue=""
                                 class="flex-1 w-0 mt-3" :empty-value="false" />
            </template>
            <template #filterclear="{ filterCallback }">
                <FilterClear :callback="filterCallback" />
            </template>
            <template #filterapply="{ filterCallback }">
                <FilterApply :callback="filterCallback" />
            </template>
            <template #body="slotProps">
                {{ slotProps.data.species.namePl }}
            </template>
            <template #editor="{data,field}">
                <CustomSelectOne name="species" :items="mockData.species"
                                 itemLabel="name" itemValue=""
                                 v-model="data[field]" />
            </template>
        </Column>
        <Column header="Rok od" sortable field="yearStart" :showFilterMatchModes="false"
                filter-menu-class="w-16rem" filterField="yearStart">
            <template #filter="{filterModel}">
                <CustomIntervalFilter v-model="filterModel.value" :range="{min: 0,max: new Date().getFullYear()}" />
            </template>
            <template #filterclear="{ filterCallback }">
                <FilterClear :callback="filterCallback" />
            </template>
            <template #filterapply="{ filterCallback }">
                <FilterApply :callback="filterCallback" />
            </template>
            <template #body="slotProps">
                {{ slotProps.data.yearStart.getFullYear() }}
            </template>
            <template #editor="{data,field}">
                <CustomCalendar name="yearStart" dateFormat="yy" view="year"
                                v-model="data[field]" />
            </template>
        </Column>
        <Column header="Rok do" sortable field="yearEnd" :showFilterMatchModes="false"
                filter-menu-class="w-16rem" filterField="yearEnd">
            <template #filter="{filterModel}">
                <CustomIntervalFilter v-model="filterModel.value" :range="{min: 0,max: new Date().getFullYear()}" />
            </template>
            <template #filterclear="{ filterCallback }">
                <FilterClear :callback="filterCallback" />
            </template>
            <template #filterapply="{ filterCallback }">
                <FilterApply :callback="filterCallback" />
            </template>
            <template #body="slotProps">
                {{ slotProps.data.yearEnd.getFullYear() }}
            </template>
            <template #editor="{data,field}">
                <CustomCalendar name="yearEnd" dateFormat="yy" view="year"
                                v-model="data[field]" />
            </template>
        </Column>
        <Column header="Trend" sortable field="trendValue" :showFilterMatchModes="false"
                filter-menu-class="w-16rem" filterField="trendValue">
            <template #filter="{filterModel}">
                <CustomIntervalFilter v-model="filterModel.value" :range="{min: 0,max: 999}" :maxDigits="99" />
            </template>
            <template #filterclear="{ filterCallback }">
                <FilterClear :callback="filterCallback" />
            </template>
            <template #filterapply="{ filterCallback }">
                <FilterApply :callback="filterCallback" />
            </template>
            <template #editor="{data,field}">
                <CustomInputNumber name="trendValue"
                                   v-model="data[field]" :maxDigits="99" />
            </template>
        </Column>
        <Column header="Błąd standardowy trendu" sortable field="standardError" :showFilterMatchModes="false"
                filter-menu-class="w-16rem" filterField="standardError">
            <template #filter="{filterModel}">
                <CustomIntervalFilter v-model="filterModel.value" :range="{min: 0,max: 999}" :maxDigits="99" />
            </template>
            <template #filterclear="{ filterCallback }">
                <FilterClear :callback="filterCallback" />
            </template>
            <template #filterapply="{ filterCallback }">
                <FilterApply :callback="filterCallback" />
            </template>
            <template #editor="{data,field}">
                <CustomInputNumber name="standardError"
                                   v-model="data[field]" :maxDigits="99" />
            </template>
        </Column>
        <Column header="Trend (nazwa)" sortable field="trend" :showFilterMatchModes="false"
                filter-menu-class="w-16rem" filterField="trend">
            <template #filter="{filterModel}">
                <CustomSelectOne name="trendName" v-model="filterModel.value"
                                 :items="mockData.trend" class="flex-1 w-0 mt-3" :empty-value="false" />
            </template>
            <template #filterclear="{ filterCallback }">
                <FilterClear :callback="filterCallback" />
            </template>
            <template #filterapply="{ filterCallback }">
                <FilterApply :callback="filterCallback" />
            </template>
            <template #editor="{data,field}">
                <CustomSelectOne label="Trend (nazwa)" name="trendName" :items="mockData.trend"
                                 v-model="data[field]" />
            </template>
        </Column>
        <Column header="Typ trendu" sortable field="trendType" :showFilterMatchModes="false"
                filter-menu-class="w-16rem" filterField="trendType">
            <template #filter="{filterModel}">
                <CustomSelectOne name="trendType" v-model="filterModel.value"
                                 :items="mockData.trendType" class="flex-1 w-0 mt-3" :empty-value="false" />
            </template>
            <template #filterclear="{ filterCallback }">
                <FilterClear :callback="filterCallback" />
            </template>
            <template #filterapply="{ filterCallback }">
                <FilterApply :callback="filterCallback" />
            </template>
            <template #editor="{data,field}">
                <CustomSelectOne name="trendType" :items="mockData.trendType"
                                 v-model="data[field]" />
            </template>
        </Column>
        <Column header="Ostatnia aktualizacja" sortable field="lastModification">
            <template #body="slotProps">
                {{ computedTime(new Date(slotProps.data.lastModification)) }}
            </template>
        </Column>
        <Column header="Obszar" sortable field="trendArea" :showFilterMatchModes="false"
                filter-menu-class="w-16rem" filterField="trendArea">
            <template #filter="{filterModel}">
                <CustomSelectOne name="trendArea" v-model="filterModel.value"
                                 :items="mockData.trendArea" class="flex-1 w-0 mt-3" :empty-value="false" />
            </template>
            <template #filterclear="{ filterCallback }">
                <FilterClear :callback="filterCallback" />
            </template>
            <template #filterapply="{ filterCallback }">
                <FilterApply :callback="filterCallback" />
            </template>
            <template #editor="{data,field}">
                <CustomSelectOne name="trendArea" :items="mockData.trendArea"
                                 v-model="data[field]" />
            </template>
        </Column>
        <Column :rowEditor="true" style="width: 120px; text-align: center;" />
        <Column style="width: 60px; text-align: center;">
            <template #body="slotProps">
                <CustomButton icon="pi pi-trash" borderColor="transparent" @click="deleteTrend(slotProps.data)" />
            </template>
        </Column>
    </DataTable>
</template>

<script>
    // import _ from "lodash";
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {
        searchTrendData,
        searchTrendDataCount,
        deleteTrendById,
        getListOfPrograms,
        updateTrendData,
    } from "@/swagger/vue-api-client";
    import FilterApply from "@/components/filters/FilterApply.vue";
    import FilterClear from "@/components/filters/FilterClear.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomIntervalFilter from "@/components/filters/CustomIntervalFilter.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import AddTrend from "./AddTrend.vue";
    import {DateUtils} from "@/utils/DateUtils";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import {FiltersUtils} from "@/utils/FiltersUtils";

    export default {
        name: "TrendsTable",

        components: {
            DataTable,
            Column,
            FilterApply,
            FilterClear,
            CustomSelectOne,
            CustomIntervalFilter,
            CustomButton,
            AddTrend,
            CustomInputNumber,
            CustomCalendar,
        },

        data() {
            return {
                trends: [],
                editingRows: ref([]),
                loading: false,
                totalRecords: 0,
                searchCriteria: SearchCriteria.getClearSearchCriteriaTrends(),
                page: SearchCriteria.getClearPage(),
                programs: [],
                mockData: {
                    species: [
                        {id: 476, name: "perkoz dwuczuby", code: "PER1"},
                    ],
                    trend: [
                        "nieokreślony",
                        "nieznany",
                        "silny spadek",
                        "silny wzrost",
                        "stabilny",
                        "umiarkowany spadek",
                        "umiarkowany wzrost",
                    ],
                    trendType: [
                        "Wskaźnik liczebności populacji",
                        "Liczebność populacji",
                        "Rozpowszechnienie",
                        "Liczba zasiedlonych kwadratów",
                        "Proporcja par z udanym lęgiem",
                        "Średnia liczba piskląt / lęg",
                        "Średnia liczba piskląt / lęg udany",
                    ],
                    trendArea: [
                        "Cały obszar kraju",
                        "Tylko tereny OSOP Natura 2000",
                        "Z wyłączeniem terenów OSOP Natura 2000",
                    ],
                },
            };
        },

        beforeMount() {
            if (FiltersUtils.loadFromSessionStorage(this.$route.path)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(this.$route.path);
            }
        },

        mounted() {
            this.search();
            getListOfPrograms()
                .then((response) => {
                    this.programs = response.data;
                });
        },

        methods: {
            async search() {
                this.loading = true;
                const criteria = SearchCriteria.prepareCriteria(
                    this.searchCriteria,
                    this.page,
                    this.filterDescription,
                );
                searchTrendData({body: criteria})
                    .then((response) => {
                        this.trends = response.data;
                        this.trends.forEach((t) => {
                            t.yearStart = new Date(t.yearStart, 1, 1);
                            t.yearEnd = new Date(t.yearEnd, 1, 1);
                        });
                        return searchTrendDataCount({body: criteria});
                    })
                    .then((response) => {
                        this.totalRecords = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        if (err.response.status === 403) {
                            this.$router.push({name: "forbidden"});
                        }
                    });
            },

            onPage(event) {
                this.searchCriteria.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
            },

            onRowEditSave(event) {
                const {newData, index} = event;
                this.trends[index] = newData;
                const trendRequest = {
                    program: newData.program,
                    speciesId: newData.species.id,
                    yearStart: newData.yearStart.getFullYear(),
                    yearEnd: newData.yearEnd.getFullYear(),
                    trendValue: newData.trendValue,
                    standardError: newData.standardError,
                    trend: newData.trend,
                    trendType: newData.trendType,
                    trendArea: newData.trendArea,
                };
                updateTrendData({id: event.data.id, body: trendRequest});
            },

            deleteTrend(data) {
                deleteTrendById({id: data.id})
                    .then(() => {
                        const index = this.trends.indexOf(data);
                        this.trends.splice(index, 1);
                    });
            },

            computedTime(fullDate) {
                const hours = DateUtils.getTimeOnly(fullDate);
                const date = DateUtils.dateAsString(fullDate);
                return date + " " + hours;
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    FiltersUtils.saveToSessionStorage(this.$route.path, value);
                },
                deep: true,
            },
        },

        computed: {
            filterDescription() {
                return SearchCriteria.getSearchCriteriaTrendsFilterDescription();
            },
        },
    };
</script>

<style lang="scss">
</style>
