<template>
    <CustomCheckbox label="Brak ptaków" v-model="internalNoBirds" :disabled="!editing"
                    name="noBirds" :error="showError()"/>
    <DataTable v-if="!internalNoBirds" :value="internalBirds" v-model:editingRows="editingRows" editMode="row"
               dataKey="ordinal" @row-edit-save="onRowEditSave" size="small" :rowClass="() => 'rowWidth'"
               class="navigate-table birds-mpwr-table" @click="arrowsNavigateTable"
               rowGroupMode="subheader" groupRowsBy="species.name" scrollable scrollHeight="500px" :key="internalBirds"
               :pt="{rowGroupHeaderCell: {colspan: 9}}">
        <template #header>
            <AddBirdsMPWR v-model="internalBirds" :error="showError()" :freeNestingCriteria="freeNestingCriteria"/>
        </template>
        <template #groupheader="slotProps">
            <div class="groupheader-margin py-3">
                <span class="font-bold uppercase">{{ slotProps.data.species?.name }}</span>
            </div>
        </template>
        <Column header="Lp." field="ordinal" style="width: 4.1666%;" />
        <Column header="Gatunek" class="w-2" field="species">
            <template #body="slotProps">
                {{ slotProps.data.species?.namePl ? slotProps.data.species?.namePl : slotProps.data.species?.name }}
            </template>
            <template #editor="{data,field}">
                <CustomSelectOne name="species" v-model="data[field]"
                                 :items="dicts.programBirds" itemLabel="name" />
            </template>
        </Column>
        <Column header="Kryterium lęgowości" class="w-3" field="nestingCriteria">
            <template #body="slotProps">
                <div v-tooltip.top="{value: slotProps.data.nestingCriteria.label,class: 'tooltip-style'}">
                    {{ getDictLabelWithCodePrefix(slotProps.data.nestingCriteria) }}
                </div>
            </template>
            <template #editor="{data,field}">
                <CustomSelectOne name="nestingCriteria" v-model="data[field]"
                                 :items="computedItems(data?.species,data.nestingCriteria)"
                                 short :disabled="!data.species" tooltip
                                 :itemLabel="getDictLabelWithCodePrefix" />
            </template>
        </Column>
        <Column header="L. osobników dorosłych" class="w-1" field="count">
            <template #editor="{data,field}">
                <CustomInputNumber name="count" v-model="data[field]" />
            </template>
        </Column>
        <Column header="L. gniazd" class="w-1" field="nests">
            <template #editor="{data,field}">
                <CustomInputNumber name="nestes" v-model="data[field]" :disabled="!checkNestCriteria(data.nestingCriteria)" />
            </template>
        </Column>
        <Column header="Ocena l. par w kryterium" class="w-1" field="pairs">
            <template #editor="{data,field}">
                <CustomInputNumber name="pairs" v-model="data[field]" />
            </template>
        </Column>
        <Column header="Szczegóły obserwacji" class="w-1" field="notesBird">
            <template #editor="{data,field}">
                <CustomInputText name="notesBird" v-model="data[field]" />
            </template>
        </Column>
        <template #groupfooter="slotProps">
            <div class="flex gap-4 justify-content-end">
                <div class="p-2" style="border: 1px solid #dee2e6;">
                    <span class="font-bold">L. par/terytoriów w kategorii A:</span> {{
                        showComputedSummary("A",slotProps.data.species?.code) || 0
                    }}
                </div>
                <div class="p-2" style="border: 1px solid #dee2e6;">
                    <span class="font-bold">L. par/terytoriów w kategorii B:</span> {{
                        showComputedSummary("B",slotProps.data.species?.code) || 0
                    }}
                </div>
                <div class="p-2" style="border: 1px solid #dee2e6;">
                    <span class="font-bold">L. par/terytoriów w kategorii C:</span> {{
                        showComputedSummary("C",slotProps.data.species?.code) || 0
                    }}
                </div>
            </div>
        </template>
        <Column v-if="editing" :rowEditor="true" style="text-align: center;" class="w-1"/>
        <Column v-if="editing" style="width: 4.1666%; text-align: center;">
            <template #body="slotProps">
                <CustomButton icon="pi pi-trash" borderColor="transparent"
                              @click="deleteBirdObservation(slotProps.data)" />
            </template>
        </Column>
    </DataTable>
</template>

<script>
    import _ from "lodash";
    import {ref} from "vue";
    import Tooltip from "primevue/tooltip";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import AddBirdsMPWR from "@/views/formMPWR/components/AddBirdsMPWR.vue";
    import TableUtils from "@/utils/TableUtils";
    import TableArrowsNavigation from "@/utils/TableArrowsNavigation";

    export default {
        name: "FormBirdsMPWR",

        components: {
            AddBirdsMPWR,
            DataTable,
            Column,
            CustomInputNumber,
            CustomSelectOne,
            CustomCheckbox,
            CustomButton,
            CustomInputText,
        },

        directives: {
            tooltip: Tooltip,
        },

        props: {
            noBirds: {
                type: Boolean,
            },
            birds: {
                type: Array,
                default: () => [],
            },
        },

        inject: ["editing", "showErrorMessages", "dicts"],

        emits: ["update:noBirds", "update:birds"],

        data() {
            return {
                editingRows: ref([]),
                catAMap: new Map(),
                catBMap: new Map(),
                catCMap: new Map(),
                freeNestingCriteria: new Map(),
            };
        },

        beforeMount() {
            this.updateFreeNestingCriteria();
        },

        methods: {
            arrowsNavigateTable(ctx) {
                TableArrowsNavigation.arrowsNavigateTable(ctx);
            },
            onRowEditSave(event) {
                const {newData, index} = event;
                if (!this.checkNestCriteria(newData.nestingCriteria)) {
                    newData.nests = null;
                }
                this.internalBirds[index] = newData;
                this.computedSummary(this.internalBirds);
            },
            deleteBirdObservation(item) {
                const index = this.internalBirds.indexOf(item);
                this.internalBirds.splice(index, 1);
            },
            showError() {
                return this.showErrorMessages && ((this.internalBirds == null
                    || this.internalBirds.length === 0) && !(this.internalNoBirds === true));
            },
            computedSummary(birds) {
                this.catAMap.clear();
                this.catBMap.clear();
                this.catCMap.clear();
                birds.forEach((b) => {
                    const code = b.nestingCriteria?.code.split("_").pop();
                    if (code.length === 1) {
                        if (this.catAMap.has(`${b.species.code}`)) {
                            this.catAMap.set(`${b.species.code}`, this.catAMap.get(`${b.species.code}`) + b.pairs);
                        } else {
                            this.catAMap.set(`${b.species.code}`, b.pairs);
                        }
                    }
                    if (code.length === 2) {
                        if (this.catBMap.has(`${b.species.code}`)) {
                            this.catBMap.set(`${b.species.code}`, this.catBMap.get(`${b.species.code}`) + b.pairs);
                        } else {
                            this.catBMap.set(`${b.species.code}`, b.pairs);
                        }
                    }
                    if (code.length === 3) {
                        if (this.catCMap.has(`${b.species.code}`)) {
                            this.catCMap.set(`${b.species.code}`, this.catCMap.get(`${b.species.code}`) + b.pairs);
                        } else {
                            this.catCMap.set(`${b.species.code}`, b.pairs);
                        }
                    }
                });
            },
            showComputedSummary(cat, species) {
                if (cat === "A") {
                    return this.catAMap.get(`${species}`);
                }
                if (cat === "B") {
                    return this.catBMap.get(`${species}`);
                }
                if (cat === "C") {
                    return this.catCMap.get(`${species}`);
                }
                return null;
            },
            computedItems(species, nestingCriteria) {
                const internalNC = [];
                internalNC.push(nestingCriteria);
                if (species) {
                    const free = this.freeNestingCriteria.get(species.code);
                    this.dicts.nestingCriteria.forEach((nc) => {
                        nc.info.forEach((i) => {
                            if (i.code === species.code) {
                                if (Array.from(free.values()).includes(nc.code)) {
                                    internalNC.push(nc);
                                }
                            }
                        });
                    });
                }
                return internalNC;
            },
            checkNestCriteria(value) {
                if (value) {
                    const code = value.code.split("_").pop();
                    if (code === "GNS"
                        || code === "JAJ"
                        || code === "PIS"
                        || code === "WYS") {
                        return true;
                    }
                    return false;
                }
                return false;
            },
            updateFreeNestingCriteria() {
                this.dicts.programBirds.forEach((species) => {
                    const freeSpeciesNestingCriteria = new Set();
                    this.dicts.nestingCriteria.forEach((nc) => {
                        nc.info.forEach((i) => {
                            if (i.code === species.code) freeSpeciesNestingCriteria.add(nc.code);
                        });
                    });
                    this.freeNestingCriteria.set(species.code, freeSpeciesNestingCriteria);
                });
                if (this.internalBirds != null) {
                    this.internalBirds.forEach((b) => {
                        const freeSpeciesNestingCriteria = this.freeNestingCriteria.get(b.species.code);
                        freeSpeciesNestingCriteria.delete(b.nestingCriteria.code);
                        this.freeNestingCriteria.set(b.species.code, freeSpeciesNestingCriteria);
                    });
                }
            },
            getDictLabelWithCodePrefix(item) {
                if (item == null) return "";
                return this.textTruncate(item.code + " - " + item.label);
            },
            textTruncate(text) {
                if (text.length > 40) {
                    return _.truncate(text, {
                        length: 40,
                        separator: " ",
                    });
                }
                return text;
            },
        },

        computed: {
            internalNoBirds: {
                get() { return this.noBirds; },
                set(value) { this.$emit("update:noBirds", value); },
            },
            internalBirds: {
                get() { return this.birds; },
                set(value) { this.$emit("update:birds", value); },
            },
        },

        watch: {
            internalBirds: {
                handler(value) {
                    this.computedSummary(value);
                    value.sort((a, b) => a.species?.name.localeCompare(b.species?.name));
                    this.$emit("update:birds", value);
                    this.updateFreeNestingCriteria();
                },
                deep: true,
            },
            birds: {
                handler(value) {
                    TableUtils.addOrdinal(value);
                    this.computedSummary(value);
                },
                immediate: true,
            },
        },
    };
</script>

<style lang="scss">
    .groupheader-margin {
        margin-left: 4.4%;
    }
    .birds-mpwr-table.p-datatable {
        .p-datatable-header {
            background-color: var(--surface-e) !important;
        }
        .p-datatable-thead {
            background-color: var(--surface-e) !important;
            > tr > th {
                background-color: var(--surface-e) !important;
            }
        }
        .p-datatable-tbody > tr.p-rowgroup-header {
            background-color: var(--surface-b);
        }
        .p-column-title {
            font-size: 1rem;
        }
    }
    .tooltip-style {
        max-width: 300px;
    }
</style>
